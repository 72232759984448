import './FiPanelDialog.scss';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiPanelDialog.html'),
    bindings: {

    },
})
export class FiPanelDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    image_1: string = 'test.jpg';
    image_2: string = '';

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
        console.log(this.fs.panelItem);
    }

    close() {
        this.$mdDialog.cancel();
    }

    save() {

        // Cia priskirti ir php Update
        alert('save');
    }

    attachFile() {
        console.log('atach');
    }

}