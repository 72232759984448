import './Cooperation.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Cooperation.html'),
    bindings: {
    }
})
export class Cooperation {
    static $inject = [
        'fiSubHeaderService',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    cAssembly: boolean;
    cDesign: boolean;
    cName: string;
    cEmail: string;
    cPhone: string;
    cCity: string;
    cRules: boolean;

    thanks: boolean = false;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;
    }

    send() {
        this.$http.post("https://www.fitit.lt/db/cooperation.php", { 

            assembly: this.cAssembly,
            design: this.cDesign,
            name: this.cName,
            email: this.cEmail,
            phone: this.cPhone,
            city: this.cCity,
            rules: this.cRules

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {

                console.log(response);

                if(response.data == 'return') {
                    // alert('return');
                }
                else {
                    
                }
                return;

        });

        this.thanks = true;
    }
}
