import './FiContactMe.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiContactMe.html'),
    bindings: {

    },
})
export class FiContactMe {
    static $inject = [
        '$mdDialog',
        '$http',
    ];


    userPhone: string;
    userEmail: string;
    isSent: boolean;

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    $onInit() {
        //
    }

    send() {
        this.$http.post("https://www.fitit.lt/db/contact-me.php", { 

            user_phone: this.userPhone,
            user_email: this.userEmail

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                this.isSent = true;

        });
    }

    close() {
        this.$mdDialog.cancel();
    }

}