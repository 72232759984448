import * as angular from 'angular';

import { Home } from './home/Home';
import { Configurator } from './configurator/Configurator';
import { Configuratornew } from './configuratornew/Configuratornew';

import { ItemDetails } from './itemDetails/ItemDetails';
import { ItemList } from './itemList/ItemList';
import { UserProfile } from './user/profile/UserProfile';
import { UserFavorites } from './user/favorites/UserFavorites';
import { UserOrders } from './user/orders/UserOrders';
import { UserPassword } from './user/password/UserPassword';
import { Cart } from './cart/Cart';
import { Checkout } from './checkout/Checkout';
import { Success } from './success/Success';
import { Cooperation } from './cooperation/Cooperation';
import { Duk } from './duk/Duk';
import { Login } from './login/Login';
import { Rules } from './rules/Rules';
import { Gallery } from './gallery/Gallery';
import { Contacts } from './contacts/Contacts';
import { PrivacyPolicy } from './privacyPolicy/PrivacyPolicy';
import { CookiePolicy } from './cookiePolicy/CookiePolicy';
import { Order } from './order/Order';
import { OrderCarpenter } from './orderCarpenter/OrderCarpenter';
import { ViewOrder } from './viewOrder/ViewOrder';
import { Request } from './request/Request';
import { HomeRequestPage } from './homeRequestPage/HomeRequestPage';
import { SuccessOrderCarpenter } from './successOrderCarpenter/SuccessOrderCarpenter';
import { Three } from './three/Three';

import { SuccessOffer } from './successOffer/SuccessOffer';


import { GiftCard } from './giftCard/GiftCard';
import { SuccessGiftCard } from './successGiftCard/SuccessGiftCard';
import { Kitchen } from './kitchen/Kitchen';
import { Offer } from './offer/Offer';



// Demo (delete)
import { Demo } from './demo/Demo';

// Admin
import { AdminItems } from './admin/items/AdminItems';
import { AdminOrders } from './admin/orders/AdminOrders';
import { AdminUsers } from './admin/users/AdminUsers';
import { AdminMaterial } from './admin/material/AdminMaterial';

import { CarpenterEdit } from './admin/carpenter/CarpenterEdit';
import { GiftcardsEdit } from './admin/giftcards/GiftcardsEdit';
import { GalleryEdit } from './admin/gallery/GalleryEdit';
import { DiscountEdit } from './admin/discount/DiscountEdit';

export default angular.module('app.views', [])
    .component('home', Home as angular.IComponentOptions)
    .component('configurator', Configurator as angular.IComponentOptions)
    .component('configuratornew', Configuratornew as angular.IComponentOptions)
    .component('itemDetails', ItemDetails as angular.IComponentOptions)
    .component('itemList', ItemList as angular.IComponentOptions)
    .component('orderCarpenter', OrderCarpenter as angular.IComponentOptions)
    .component('userProfile', UserProfile as angular.IComponentOptions)
    .component('userFavorites', UserFavorites as angular.IComponentOptions)
    .component('userOrders', UserOrders as angular.IComponentOptions)
    .component('userPassword', UserPassword as angular.IComponentOptions)
    .component('cart', Cart as angular.IComponentOptions)
    .component('checkout', Checkout as angular.IComponentOptions)
    .component('success', Success as angular.IComponentOptions)
    .component('cooperation', Cooperation as angular.IComponentOptions)
    .component('duk', Duk as angular.IComponentOptions)
    .component('login', Login as angular.IComponentOptions)
    .component('rules', Rules as angular.IComponentOptions)
    .component('gallery', Gallery as angular.IComponentOptions)
    .component('contacts', Contacts as angular.IComponentOptions)
    .component('privacyPolicy', PrivacyPolicy as angular.IComponentOptions)
    .component('cookiePolicy', CookiePolicy as angular.IComponentOptions)
    .component('order', Order as angular.IComponentOptions)
    .component('viewOrder', ViewOrder as angular.IComponentOptions)
    .component('request', Request as angular.IComponentOptions)
    .component('homeRequestPage', HomeRequestPage as angular.IComponentOptions)
    .component('successOrderCarpenter', SuccessOrderCarpenter as angular.IComponentOptions)
    .component('successOffer', SuccessOffer as angular.IComponentOptions)
    .component('three', Three as angular.IComponentOptions)
    .component('kitchen', Kitchen as angular.IComponentOptions)
    .component('offer', Offer as angular.IComponentOptions)
    
    // Demo (delete)
    .component('demo', Demo as angular.IComponentOptions)

    // Admin
    .component('adminItems', AdminItems as angular.IComponentOptions)
    .component('adminOrders', AdminOrders as angular.IComponentOptions)
    .component('adminUsers', AdminUsers as angular.IComponentOptions)
    .component('adminMaterial', AdminMaterial as angular.IComponentOptions)
    .component('carpenterEdit', CarpenterEdit as angular.IComponentOptions)
    .component('giftcardsEdit', GiftcardsEdit as angular.IComponentOptions)
    .component('discountEdit', DiscountEdit as angular.IComponentOptions)
    .component('galleryEdit', GalleryEdit as angular.IComponentOptions)
    .component('giftCard', GiftCard as angular.IComponentOptions)
    .component('successGiftCard', SuccessGiftCard as angular.IComponentOptions)
;
