import './FiChooseOuter.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, IColumnItem, IRowItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiChooseOuter.html'),
    bindings: {
        hideButton: '<' 
    },
})
export class FiChooseOuter {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
    }

    unActivateRow() {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {
            c.active = false;
            c.rows.forEach((r: IRowItem) => {
                    r.active = false;
                
            });
        });
        this.fs.furniture.activeRow = null;
        this.fs.furniture.activeColumn = null;
    }

    setOuter(selection) {

        if (!this.fs.furniture.activeRow.panel) {
            this.fs.furniture.activeRow.panel = this.fs.furniture.material;
            this.fs.furniture.activeRow.panel_id = this.fs.furniture.material_id;
        }

        if (selection == 6) {
            if (this.fs.furniture.activeRow.height > 10 && this.fs.furniture.activeRow.height > 100) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.outer = selection;
            }
        }
        else if (selection == 0) {
            this.fs.furniture.activeRow.outer = 0;
            this.fs.furniture.activeRow.panel = null;
            this.fs.furniture.activeRow.panel_id = null;
        }
        else {
            this.fs.furniture.activeRow.outer = selection;
            
        }

        // this.unActivateRow();
        this.$mdDialog.cancel();
    }

    close() {
        this.unActivateRow();
        this.$mdDialog.cancel();
    }
}