import './FiPanelFillingDialog.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiPanelFillingDialog.html'),
    bindings: {
        //
    },
})
export class FiPanelFillingDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    $onInit() {

    }

    viewImage(img) {
        this.$window.open('/img/fillings/' + img, '_blank');
    }

    close() {
        this.$mdDialog.cancel();
    }

}