import './HomeRequestPage.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';
import { FiCart } from '../../services/Cart';
import { FiPrice } from '../../services/Price';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./HomeRequestPage.html'),
    bindings: {
    }
})
export class HomeRequestPage {
    static $inject = [
        '$scope',
        '$locale',
        '$timeout',
        'fiSubHeaderService',
        '$window',
        'fiFurnitureService',
        '$http',
        'fiCart',
        'fiPrice',
    ];

    activeDukItem: number;
    galleryPic: number = 1;
    testimonialMobile: number = 1;
    image_1: File;
    galleryFade: boolean;
    galleryImage: string;
    

    cName: string;
    cEmail: string;
    cPhone: string;
    cDescription: string;
    cConsultation: boolean;

    thanks: boolean = false;
    
    constructor(
        private $scope: angular.IScope,
        private $locale: angular.ILocaleService,
        private $timeout: angular.ITimeoutService,
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
        private cart: FiCart,
        private ps: FiPrice,
    ) {
        /* */
    }

    data = [];
    cartStorage: FiCart;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        // Items
        this.$http.get('https://www.fitit.lt/db/items.php').then((data) => {
            this.data.push(data);
        });

        this.swapImage();

        this.changeImage();

    }

    swapImage() { 
        this.galleryFade = !this.galleryFade;

        this.$timeout(() => {
            this.swapImage();
        }, 5000);

    }

    viewImage(img) {
        this.$window.open(img, '_blank');
    }

    send() {
        this.$http.post("https://www.fitit.lt/db/request.php", { 

            name: this.cName,
            email: this.cEmail,
            phone: this.cPhone,
            description: this.cDescription,
            consulation: this.cConsultation

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {

                console.log(response);

                if(response.data == 'return') {
                    // alert('return');
                }
                else {
                    
                }
                return;

        });

        this.thanks = true;
    }

    changeImage() {
        this.galleryImage = 'pic-one';

        this.$timeout(() => {
            this.galleryImage = 'pic-two';

            this.$timeout(() => {
                this.galleryImage = 'pic-three';

                this.$timeout(() => {
                  
                        this.changeImage();
                   

                }, 5000);

            }, 5000);

        }, 5000);
    }

    changeTestimonialMobile(testimonialNo: number) {
        if (testimonialNo < 1 || testimonialNo > 7) {
            return
        }
        
        this.testimonialMobile = testimonialNo;
    }

    toggleDuk(listItem: number) {
        this.activeDukItem = listItem;
    }

    goToConfiguratorNewItem() {
        this.$window.location.href = '/konfiguratorius/';
    }

    changeGalleryPic(picNo: number) {

        if (picNo < 1 || picNo > 15) {
            return
        }
        
        this.galleryPic = picNo;
    }

    viewDetails(item_id: string) {
        this.$window.location.href = '/baldai/' + item_id;
    }

    goToConfigurator(item_id: string) {
        this.$window.location.href = '/konfiguratorius/' + item_id;
    }

    addToCart(item_id: string) {

        this.data = [];

        this.$http.get('https://www.fitit.lt/db/item.php?id=' + item_id).then((data) => {
            this.data.push(data);
            this.fs.setFurniture(false, this.data);

            this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        
            if (this.cartStorage) {
                this.cart.items = this.cartStorage.items;
            }
            
            this.cart.items.push({
                item: this.fs.furniture,
                count: 1,
                assemblyPrice: this.ps.getAssemblyPrice(),
                carryPrice: this.ps.getCarryPrice()
            });

            this.cart.count = this.cart.items.length;
            this.cart.environment = this.fs.environment;

            localStorage.setItem('cart', JSON.stringify(this.cart));

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
            window['dataLayer'].push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {                                // 'add' actionFieldObject measures.
                'products': [{                        //  adding a product to a shopping cart.
                    'name': this.fs.furniture.title,
                    'id': this.fs.furniture.item_id,
                    'price': this.fs.furniture.price,
                    'quantity': 1
                }]
                }
            }
            });

            this.$window.location.href = '/krepselis';
        });


        

    }

    getType(type: number) {
        if (type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }
}
