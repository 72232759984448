import './SketchSlidingDoors.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService, IColumnItem, IRowItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./SketchSlidingDoors.html'),
    bindings: {
    },
})
export class SketchSlidingDoors {
    static $inject = [
        'fiFurnitureService',
    ];


    constructor(
        private fs: FiFurnitureService,
    ) {
    }

    $onInit() {
        //
    }

   
}
