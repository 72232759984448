import { IFilterOrderBy } from 'angular';
import { LookupAllOptions } from 'dns';
import { FiExpansionPanelService } from './../fiExpansionPanel/FiExpansionPanelService';
// import { FiPrice } from '../../services/Price';

export class FiFurnitureService {
    static $inject = [
        'fiExpansionPanelService',
        '$http',
        '$window',
        '$timeout',
        // 'fiPrice',
    ];

    constructor(
        private feps: FiExpansionPanelService,
        private $http: angular.IHttpService,
        private $window: angular.IWindowService,
        private $timeout: angular.ITimeoutService,
        // private ps: FiPrice,
    ) {
    }

    // Main functions
    activeStep: number = 1;
    activeView: number = 1;
    // editMode: boolean = false;
    // newItem: boolean = true;
    activeViewClass: string;
    zoomImage: string;
    sameDoorsPanel: boolean = false;
    sameSlidingDoorsPanel: boolean = false;
    selected_mdf_gloss: number = 0;

    samePushHandles: boolean = true;
    sameNoHandles: boolean = true;
    sameHandles: boolean = true;
    sameKnob: boolean = true;
    sameInternalHandles: boolean = true;
    sameFilling: boolean = true;
    sameFillings: boolean = false;

    minFurnitureHeight: number;
    maxFurnitureHeight: number;
    minFurnitureWidth: number;
    maxFurnitureWidth: number;
    minFurnitureDepth: number;
    maxFurnitureDepth: number;
    edgeWidth: number = 1.8;

    panelTabTitle: string;
    doorsTabTitle: string;

    furnitureLoaded: boolean;

    environment: number = 0;


    setView(activeView: number) {
        this.activeView = activeView;
        this.setViewClassName();
    }

    setActiveStep(activeStep: number) {
        this.activeStep = activeStep;

        if (activeStep == 1) {
            this.activeView = 1;
            this.feps.expand(3);
            window['dataLayer'].push({'event': 'Step1'});
        }
        else if (activeStep == 2) {
            this.activeView = 3;
            // this.setView(1);
            this.feps.expand(8);
            window['dataLayer'].push({'event': 'Step2'});
        }
        else if (activeStep == 3) {
            this.activeView = 2;
            this.feps.expand(9);
            window['dataLayer'].push({'event': 'Step3'});
        }
        else if (activeStep == 4) {
            this.activeView = 4;
            this.setView(4);
            this.feps.expand(13);
            window['dataLayer'].push({'event': 'Step4'});
        }

        this.setViewClassName();
    }

    setViewClassName() {
        if(this.activeView == 2) {
            this.activeViewClass = 'outer';
        }
        else if(this.activeView == 3) {
            this.activeViewClass = 'inner'
        }
        else if(this.activeView == 4) {
            this.activeViewClass = 'outer'
        }
        else {
            this.activeViewClass = ''
        }
    }

    saveFurniture() {
        // Save furniture
    }

    data = [];
    item = [];
    columns: IColumnItem[] = [];
    slidingDoors: ISlidingDoorItem[] = [];
    divideRows: IDivideRows[] = [];
    rows: IRowItem[] = [];

    offer: IOffer;
    offerItems: IOfferItem[] = [];

    fillings = [
        {
            style: [
                {
                    id: 1,
                    name: 'Classic'
                },
                {
                    id: 2,
                    name: 'Line'
                },
                {
                    id: 3,
                    name: 'Modern'
                },
                {
                    id: 4,
                    name: 'Piano'
                }
            ],
            filling_count: [
                {
                    id: 1,
                    name: '1'
                },
                {
                    id: 2,
                    name: '2'
                },
                {
                    id: 3,
                    name: '3'
                },
                {
                    id: 4,
                    name: '4'
                }
            ]
        }
    ]

    mdf = [
        {
            gloss: [
                {
                    id: 0,
                    name: 'Matinis'
                },
                {
                    id: 1,
                    name: 'Matinis (galas baltas melaminas)'
                },
                {
                    id: 2,
                    name: 'Blizgus'
                },
                {
                    id: 3,
                    name: 'Supermatinis'
                },
                {
                    id: 4,
                    name: 'Superblizgus'
                }
            ],
            plate_thickness: [
                {
                    id: 0,
                    name: '18 mm'
                },
                {
                    id: 1,
                    name: '22 mm'
                },
                {
                    id: 2,
                    name: '25 mm'
                },
            ]
        }
    ]

    knob_profiles = [
        {
            color: [
                {
                    id: 1,
                    name: 'Auksinė'
                },
                {
                    id: 2,
                    name: 'Balta blizgi'
                },
                {
                    id: 3,
                    name: 'Sidabrinė'
                },
                {
                    id: 4,
                    name: 'Šampaninė'
                },
                {
                    id: 5,
                    name: 'Juoda šlifuota'
                },
                {
                    id: 6,
                    name: 'Juoda matinė'
                }
            ],
            price_indicative: [
                {
                    id: 1,
                    name: 'Pigu'
                },
                {
                    id: 2,
                    name: 'Vidutinė'
                },
                {
                    id: 3,
                    name: 'Brangu'
                }
            ]
        }
    ];

    legs = [
        {
            supplier: [
                {
                    id: 1,
                    name: 'UAB Furnitanas'
                }
            ],
            manufacturer: [
                {
                    id: 1,
                    name: 'RUJZ'
                },
                {
                    id: 2,
                    name: 'Schuco'
                }
            ],
            height: [
                {
                    id: 1,
                    name: '34'
                },
                {
                    id: 2,
                    name: '60'
                },
                {
                    id: 3,
                    name: '100'
                },
                {
                    id: 4,
                    name: '140'
                },
                {
                    id: 5,
                    name: '150'
                },
                {
                    id: 6,
                    name: '200'
                }
            ],
            color: [
                {
                    id: 1,
                    name: 'Chromas blizgus'
                },
                {
                    id: 2,
                    name: 'Aliuminis'
                },
                {
                    id: 3,
                    name: 'Nerūdijantis plienas'
                },
                {
                    id: 4,
                    name: 'Juoda'
                },
                {
                    id: 5,
                    name: 'Balta'
                },
                {
                    id: 6,
                    name: 'Chromas matinis'
                },
                {
                    id: 7,
                    name: 'Pilka'
                },
                {
                    id: 8,
                    name: 'Nikelis matinis'
                },
                {
                    id: 9,
                    name: 'Tamsi bronza'
                }
            ],
            material: [
                {
                    id: 1,
                    name: 'Metalinės'
                },
                {
                    id: 2,
                    name: 'Plastikinės'
                }
            ],
            adjustable_height: [
                {
                    id: 1,
                    name: 'Reguliuojamas'
                },
                {
                    id: 2,
                    name: 'Nereguliuojamas'
                }
            ]
        }
    ];

    handles = [
        {
            supplier: [
                {
                    id: 1,
                    name: 'UAB Furnitanas'
                }
            ],
            manufacturer: [
                {
                    id: 1,
                    name: 'Furnipart'
                },
                {
                    id: 2,
                    name: 'Schueco'
                },
                {
                    id: 3,
                    name: 'Citterio Giulio'
                },
                {
                    id: 4,
                    name: 'Metakor'
                },
                {
                    id: 5,
                    name: 'Rujz'
                },
                {
                    id: 6,
                    name: 'Giusti'
                }
            ],
            color: [
                {
                    id: 1,
                    name: 'Juoda'
                },
                {
                    id: 2,
                    name: 'Žalvaris'
                },
                {
                    id: 3,
                    name: 'Balta'
                },
                {
                    id: 4,
                    name: 'Bronzos spalva'
                },
                {
                    id: 5,
                    name: 'Nerūdijantis plienas'
                },
                {
                    id: 6,
                    name: 'Aukso spalva'
                },
                {
                    id: 7,
                    name: 'Antracito spalvos'
                },
                {
                    id: 8,
                    name: 'Chromas'
                },
                {
                    id: 9,
                    name: 'Nikelis'
                },
                {
                    id: 10,
                    name: 'Ruda'
                },
                {
                    id: 11,
                    name: 'Pilka'
                },
                {
                    id: 12,
                    name: 'Aliuminis'
                },
                {
                    id: 13,
                    name: 'Sidabro spalva'
                },
                {
                    id: 14,
                    name: 'Varis'
                },
                {
                    id: 15,
                    name: 'Plienas'
                },
                {
                    id: 16,
                    name: 'Platina'
                },
                {
                    id: 17,
                    name: 'Alavas'
                },
                {
                    id: 18,
                    name: 'Medis'
                },
                {
                    id: 19,
                    name: 'Ąžuolas'
                },
                {
                    id: 20,
                    name: 'Riešutas'
                },
                {
                    id: 21,
                    name: 'Bukas'
                },
                {
                    id: 22,
                    name: 'Mėlyna'
                },
                {
                    id: 23,
                    name: 'Žalia'
                },
                {
                    id: 24,
                    name: 'Geltona'
                },
                {
                    id: 25,
                    name: 'Raudona'
                },
                {
                    id: 26,
                    name: 'Auksas rausvas'
                },
                {
                    id: 27,
                    name: 'Kreminė'
                },
                {
                    id: 28,
                    name: 'Rožinė'
                },
                {
                    id: 29,
                    name: 'Šviesiai mėlyna'
                }
            ],
            material: [
                {
                    id: 1,
                    name: 'Metalinė - Ant briaunos'
                },
                {
                    id: 2,
                    name: 'Metalinė - Moderni'
                },
                {
                    id: 3,
                    name: 'Metalinė - Klasika'
                },
                {
                    id: 4,
                    name: 'Metalinė - Su porcelianu'
                },
                {
                    id: 5,
                    name: 'Medinės'
                },
                {
                    id: 6,
                    name: 'Vaikų baldams'
                },
                {
                    id: 7,
                    name: 'Metalinės - Su Swarovski'
                }
            ],
            texture: [
                {
                    id: 1,
                    name: 'Tipinė spalva'
                },
                {
                    id: 2,
                    name: 'Tipinis metalas'
                },
                {
                    id: 3,
                    name: 'Antikinė'
                },
                {
                    id: 4,
                    name: 'Šlifuota'
                },
                {
                    id: 5,
                    name: 'Žalvario kojelės'
                },
                {
                    id: 6,
                    name: 'Matinė'
                },
                {
                    id: 7,
                    name: 'Juodos kojelės'
                },
                {
                    id: 8,
                    name: 'Blizgi'
                },
                {
                    id: 9,
                    name: 'Titaninis'
                },
                {
                    id: 10,
                    name: 'Rusva'
                },
                {
                    id: 11,
                    name: 'Chromas'
                },
                {
                    id: 12,
                    name: 'Sendinta'
                },
                {
                    id: 13,
                    name: 'Blizgi, sendinta'
                },
                {
                    id: 14,
                    name: 'Nikelis'
                },
                {
                    id: 15,
                    name: 'Plastmasė'
                },
                {
                    id: 16,
                    name: 'Matinis chromas'
                },
                {
                    id: 17,
                    name: 'Nerūdijantis plienas'
                },
                {
                    id: 18,
                    name: 'Vengė'
                },
                {
                    id: 19,
                    name: 'Ąžuolas'
                },
                {
                    id: 20,
                    name: 'Bukas'
                },
                {
                    id: 21,
                    name: 'Riešutas'
                },
                {
                    id: 22,
                    name: 'Tamsi'
                },
                {
                    id: 23,
                    name: 'Konjako spalva'
                },
                {
                    id: 24,
                    name: 'Rusvai pilka'
                },
                {
                    id: 25,
                    name: 'Juodas chromas'
                },
                {
                    id: 26,
                    name: 'Juodas nikelis'
                }
            ],
            length_category: [
                {
                    id: 1,
                    name: '00 - 07 cm'
                },
                {
                    id: 2,
                    name: '07 - 20 cm'
                },
                {
                    id: 3,
                    name: '20 - 50 cm'
                }
            ]
        }
    ];

    handles_internal = [
        {
            supplier: [
                {
                    id: 1,
                    name: 'UAB Furnitanas'
                }
            ],
            manufacturer: [
                {
                    id: 1,
                    name: 'Furnipart'
                },
                {
                    id: 2,
                    name: 'Rujz'
                }
            ],
            color: [
                {
                    id: 1,
                    name: 'Aliuminis'
                },
                {
                    id: 2,
                    name: 'Nerūdijantis plienas'
                },
                {
                    id: 3,
                    name: 'Antracito spalvos'
                },
                {
                    id: 4,
                    name: 'Balta'
                },
                {
                    id: 5,
                    name: 'Juoda'
                },
                {
                    id: 6,
                    name: 'Chromas'
                },
                {
                    id: 7,
                    name: 'Nikelis'
                },
                {
                    id: 8,
                    name: 'Sendintas plienas'
                }
            ],
            material: [
                {
                    id: 1,
                    name: 'Metalinė - Moderni'
                }
            ],
            texture: [
                {
                    id: 1,
                    name: 'Tipinė spalva'
                },
                {
                    id: 2,
                    name: 'Tipinis metalas'
                },
                {
                    id: 3,
                    name: 'Matinis chromas'
                },
                {
                    id: 4,
                    name: 'Matinis nikelis'
                },
                {
                    id: 5,
                    name: 'Sendintas plienas'
                },
            ],
            length_category: [
                {
                    id: 1,
                    name: '00 - 07 cm'
                },
                {
                    id: 2,
                    name: '07 - 20 cm'
                },
                {
                    id: 3,
                    name: '20 - 50 cm'
                }
            ]
        }
    ];

    panel = [
        {
            manufacturer: [
                {
                    id: 1,
                    name: 'UAB Termopalas'
                }
            ],
            color: [
                {
                    id: 1,
                    name: 'Pilka'
                },
                {
                    id: 2,
                    name: 'Ruda'
                },
                {
                    id: 3,
                    name: 'Balta'
                },
                {
                    id: 4,
                    name: 'Rusva'
                },
                {
                    id: 5,
                    name: 'Juoda'
                },
                {
                    id: 6,
                    name: 'Geltona'
                },
                {
                    id: 7,
                    name: 'Raudona'
                },
                {
                    id: 8,
                    name: 'Oranžinė'
                },
                {
                    id: 9,
                    name: 'Žalia'
                },
                {
                    id: 10,
                    name: 'Violetinė'
                },
                {
                    id: 11,
                    name: 'Mėlyna'
                },
                {
                    id: 12,
                    name: 'Rožinė'
                }

            ],
            color_tone: [
                {
                    id: 1,
                    name: 'Šviesus'
                },
                {
                    id: 2,
                    name: 'Vidutinis'
                },
                {
                    id: 3,
                    name: 'Tamsus'
                },
                {
                    id: 4,
                    name: 'Ryškus'
                }
            ],
            supplier: [
                {
                    id: 1,
                    name: 'Cleaf'
                },
                {
                    id: 2,
                    name: 'Gruppo mauro saviola'
                },
                {
                    id: 3,
                    name: 'Kronospan'
                },
                {
                    id: 4,
                    name: 'Thermopal'
                },
                {
                    id: 5,
                    name: 'Egger'
                },
                {
                    id: 6,
                    name: 'Velvet'
                }
            ],
            gloss: [
                {
                    id: 1,
                    name: 'Turi blizgumo'
                },
                {
                    id: 2,
                    name: 'Neblizgu'
                },
                {
                    id: 3,
                    name: 'Labai blizgu'
                },
                {
                    id: 4,
                    name: 'n/a'
                }
            ],
            roughness: [
                {
                    id: 1,
                    name: 'Mažas'
                },
                {
                    id: 2,
                    name: 'Didelis'
                },
                {
                    id: 3,
                    name: 'Smulkus apelsinas'
                },
                {
                    id: 4,
                    name: 'Negrublėta'
                },
                {
                    id: 5,
                    name: 'Kiaušinio lukštas'
                },
                {
                    id: 6,
                    name: 'n/a'
                }
                
            ],
            pattern: [
                {
                    id: 1,
                    name: 'Betonas'
                },
                {
                    id: 2,
                    name: 'Medis'
                },
                {
                    id: 3,
                    name: 'Metalas'
                },
                {
                    id: 4,
                    name: 'Tekstilinė'
                },
                {
                    id: 5,
                    name: 'Oda'
                },
                {
                    id: 6,
                    name: 'Be rašto'
                },
                {
                    id: 7,
                    name: 'Medžio imitacija'
                },
                {
                    id: 8,
                    name: 'Banguota'
                },
                {
                    id: 9,
                    name: 'Akmuo'
                },
                {
                    id: 10,
                    name: 'Juostuotas'
                },
                {
                    id: 11,
                    name: 'Perlas'
                }
            ]
        }
    ];

    selectFurnitureOrType(id: string, type: number, editMode: boolean) {
        this.data = [];
       
        if (editMode) {
            this.furniture.type = type;
        }
        else {
            this.selectFurniture(id, false);
        }

        
    }


    selectFurniture(id: string, isNew: boolean) {

        this.data = [];

        this.$http.get('https://www.fitit.lt/db/item.php?id=' + id).then((data) => {

            this.$timeout(() => {
                this.data.push(data);
                this.setFurniture(isNew, null);

                if (this.furniture.type == 2) {
                    this.panelTabTitle = 'Durų užpildas';
                    this.doorsTabTitle = 'Stumdomos durys';
                }
                else {
                    this.panelTabTitle = 'Plokštės, filingai ir rankenėlės';
                    this.doorsTabTitle = 'Išorinės durelės ir stalčiai';
                }

                this.furnitureLoaded = true;

            }, 500);

            // console.log(this.furniture);
        });
    }

    setFurniture(isNew: boolean, data: any[]) {
        
        if (data) {
            this.data = data;
        }

        this.item = this.data[0].data;

        //console.log(this.item[0]);

        // Assigning item data
        const newFurniture = {
            id: this.item[0].id,
            item_id: this.item[0].item_id,
            type: this.item[0].type,
            title: this.item[0].title,
            doors_count: this.item[0].doors_count,
            with_drawers: this.item[0].with_drawers == 0 ? false: true,
            with_hangers: this.item[0].with_hangers == 0 ? false: true,
            draft: this.item[0].draft == 0 ? false: true,
            orderby: this.item[0].orderby,
            description: this.item[0].description,
            installation: this.item[0].installation,
            columnsCount: parseInt(this.item[0].columnsCount),
            quality: this.item[0].quality,
            edges: this.item[0].edges,
            price: this.item[0].price,
            extra_description_1: this.item[0].extra_description_1,
            extra_description_2: this.item[0].extra_description_2,
            extra_description_3: this.item[0].extra_description_3,
            extra_description_4: this.item[0].extra_description_4,
            extra_description_5: this.item[0].extra_description_5,
            extra_price_1: this.item[0].extra_price_1,
            extra_price_2: this.item[0].extra_price_2,
            extra_price_3: this.item[0].extra_price_3,
            extra_price_4: this.item[0].extra_price_4,
            extra_price_5: this.item[0].extra_price_5,
            price_from: Number(this.item[0].price_from),
            knob_id: parseInt(this.item[0].knob_id),
            knob: this.item[0].knob,
            category: this.item[0].category,
            plinth: {
                type : parseInt(this.item[0].plinth_type),
                height: Number(this.item[0].plinth_height),
                legs_id: parseInt(this.item[0].plinth_legs_id),
                legs: this.item[0].legs,
                inner_height: Number(this.item[0].plinth_height) - this.edgeWidth,
            },
            additional: {
                option_1: this.item[0].additional_1 == 0 ? false : true,
                option_2: this.item[0].additional_2 == 0 ? false : true,
                option_3: this.item[0].additional_3 == 0 ? false : true,
            },
            additional_2_height: Number(this.item[0].additional_2_height),
            additional_2_depth: Number(this.item[0].additional_2_depth),
            additional_2_unknow: this.item[0].additional_2_unknow == 0 ? false : true,
            material_is_mdf: this.item[0].material_is_mdf == 0 ? false : true,

            material_has_fillings: this.item[0].material_has_fillings == 0 ? false : true,

            collect_myself: this.item[0].collect_myself == 0 ? false : true,

            material_mdf: this.item[0].material_mdf,
            material_mdf_id: this.item[0].material_mdf_id,
            material_mdf_thickness: this.item[0].material_mdf_thickness,
            material_mdf_gloss: this.item[0].material_mdf_gloss,
            material_mdf_filling_id: this.item[0].material_mdf_filling_id,

            material_id: parseInt(this.item[0].material_id),
            material_type: this.item[0].material_type,
            material: this.item[0].material,

            material_ls_lmdp: this.item[0].material_ls_lmdp,
            material_rs_lmdp: this.item[0].material_rs_lmdp,
            material_ts_lmdp: this.item[0].material_ts_lmdp,
            material_bs_lmdp: this.item[0].material_bs_lmdp,

            width: Number(this.item[0].width),
            height: Number(this.item[0].height),
            depth: Number(this.item[0].depth),
            entresol: this.item[0].entresol == 0 ? false: true,
            entresolHeight: Number(this.item[0].entresolHeight),
            image_1: this.item[0].image_1,
            image_2: this.item[0].image_2,
            image_3: this.item[0].image_3,
            image_4: this.item[0].image_4,
            image_5: this.item[0].image_5,
            slidingDoorsCount: parseInt(this.item[0].slidingDoorsCount),
            rails: this.item[0].rails,
            columns: [],
            slidingDoors: [],
            material_different_sides: this.item[0].material_different_sides == 0 ? false : true,
            sliding_door_profiles: this.item[0].sliding_door_profiles == 0 ? false : true,

            material_ls_mdf: this.item[0].material_ls_mdf,
            material_rs_mdf: this.item[0].material_rs_mdf,
            material_ts_mdf: this.item[0].material_ts_mdf,
            material_bs_mdf: this.item[0].material_bs_mdf,

            material_ls_lmdp_id: parseInt(this.item[0].material_ls_lmdp_id),
            material_rs_lmdp_id: parseInt(this.item[0].material_rs_lmdp_id),
            material_ts_lmdp_id: parseInt(this.item[0].material_ts_lmdp_id),
            material_bs_lmdp_id: parseInt(this.item[0].material_bs_lmdp_id),
            material_ls_type: this.item[0].material_ls_type,
            material_rs_type: this.item[0].material_rs_type,
            material_ts_type: this.item[0].material_ts_type,
            material_bs_type: this.item[0].material_bs_type,
            material_ls_is_mdf: this.item[0].material_ls_is_mdf == 0 ? false: true,
            material_rs_is_mdf: this.item[0].material_rs_is_mdf == 0 ? false: true,
            material_ts_is_mdf: this.item[0].material_ts_is_mdf == 0 ? false: true,
            material_bs_is_mdf: this.item[0].material_bs_is_mdf == 0 ? false: true,
            material_ls_mdf_id: this.item[0].material_ls_mdf_id,
            material_rs_mdf_id: this.item[0].material_rs_mdf_id,
            material_ts_mdf_id: this.item[0].material_ts_mdf_id,
            material_bs_mdf_id: this.item[0].material_bs_mdf_id,
            material_ls_mdf_gloss: this.item[0].material_ls_mdf_gloss,
            material_rs_mdf_gloss: this.item[0].material_rs_mdf_gloss,
            material_ts_mdf_gloss: this.item[0].material_ts_mdf_gloss,
            material_bs_mdf_gloss: this.item[0].material_bs_mdf_gloss,
            material_ls_mdf_has_fillings: this.item[0].material_ls_mdf_has_fillings == 0 ? false: true,
            material_rs_mdf_has_fillings: this.item[0].material_rs_mdf_has_fillings == 0 ? false: true,
            material_ts_mdf_has_fillings: this.item[0].material_ts_mdf_has_fillings == 0 ? false: true,
            material_bs_mdf_has_fillings: this.item[0].material_bs_mdf_has_fillings == 0 ? false: true,
            material_ls_mdf_filling_id: this.item[0].material_ls_mdf_filling_id,
            material_rs_mdf_filling_id: this.item[0].material_rs_mdf_filling_id,
            material_ts_mdf_filling_id: this.item[0].material_ts_mdf_filling_id,
            material_bs_mdf_filling_id: this.item[0].material_bs_mdf_filling_id

        } as IFurnitureItem;

        this.columns = [];
        this.slidingDoors = [];
        this.rows = [];
        this.furniture = newFurniture;
       
        if (isNew) {
            this.furniture.title = '';
            this.furniture.description = '';
            this.furniture.doors_count = 0;
            this.furniture.with_drawers = false;
            this.furniture.with_hangers = false;
            this.furniture.draft = false;
            this.furniture.image_1 = '';
            this.furniture.image_2 = '';
            this.furniture.image_3 = '';
            this.furniture.image_4 = '';
            this.furniture.image_5 = '';
        }

        
        // divideRows: IDivideRows[] = [];
        // Adding sliding doors

        // console.log(this.item[0]);
        
        for(const door of this.item[0].slidingDoors) {
            for(const row of door.divideRows) {
                this.divideRows.push(
                    {
                        id: row.id,
                        row_id: row.row_id,
                        order_no: Number(row.order_no),
                        panel_id: row.panel_id,
                        mirror_id: row.mirror_id,
                        knob_id: row.knob_id,
                        width: Number(row.width),
                        height: Number(row.height),
                        panel_type: row.panel_type,
                        active: false,
                        panel: row.panel,
                        mirror: row.mirror,
                        knob: row.knob,
                        filling_id: row.filling_id,
                        filling: row.filling,
                        fillings: row.fillings,
                        mdf: row.mdf,
                        mdf_gloss: row.mdf_gloss,
                        mdf_id: row.mdf_id,
                        handle_type: row.handle_type,
                        mdf_thickness: row.mdf_thickness,
                        handle_id: row.handle_id,
                        handle_internal_id: row.handle_internal_id,
                        milled_knob_id: row.milled_knob_id,
                        handle_internal: row.handle_internal,
                        milled_knob: row.milled_knob,
                        handle: row.handle
                    }
                )
            }

            // Sliding doors
            this.slidingDoors.push(
                {
                    id: door.id,
                    door_id: door.door_id,
                    order_no: door.order_no,
                    divide: door.divide,
                    width: door.width,
                    height: door.height,
                    active: false,
                    divideRows: this.divideRows
                }
            )

            this.divideRows = [];
        }


        // Adding columns
        for(const column of this.item[0].columns) {
            for(const row of column.rows) {

                this.rows.push(
                    {
                        id: row.id,
                        order_no: Number(row.order_no),
                        row_id: row.row_id,
                        width: Number(row.width),
                        inner_width: Number(row.inner_width),
                        height: Number(row.height),
                        inner_height: Number(row.inner_height),
                        active: false,
                        inner: row.inner_type,
                        outer: row.outer_type,
                        handleType: row.handle_type,
                        handle: row.handle,
                        handle_internal: row.handle_internal,
                        panel: row.panel,
                        panel_id: row.panel_id,
                        handle_id: row.handle_id,
                        milled_knob_id: row.milled_knob_id,
                        milled_knob: row.milled_knob,
                        handle_internal_id: row.handle_internal_id,
                        merged: row.merged == 0 ? false : true,
                        parent_row_id: row.parent_row_id,
                        parent: row.parent,
                        merged_with: row.merged_with,
                        panelType: row.panel_type,
                        is_mdf: row.is_mdf,
                        mdf: row.mdf,
                        fillings: row.fillings == 0 ? false : true,
                        mdf_id: row.mdf_id,
                        filling_id: row.filling_id,
                        filling: row.filling,
                        mdf_thickness: row.mdf_thickness,
                        mdf_gloss: row.mdf_gloss
                    }
                )
            }

            this.columns.push(
                {
                    id: column.id,
                    order_no: column.order_no,
                    column_id: column.column_id,
                    width: Number(column.width),
                    height: Number(column.height),
                    outer_height: Number(column.outer_height),
                    inner_width: Number(column.inner_width),
                    active: false,
                    rowsCount: Number(column.rows_count),
                    rows: this.rows
                }
            )

            this.rows = [];
        }

        this.furniture.columns = this.columns;
        this.furniture.slidingDoors = this.slidingDoors;

        if (this.furniture.type == 2) {
            this.minFurnitureHeight = 100;
            this.maxFurnitureHeight = 300;

            this.minFurnitureWidth = 100;
            this.maxFurnitureWidth = 600;

            this.minFurnitureDepth = 30;
            this.maxFurnitureDepth = 90;
        }
        else {
            this.minFurnitureHeight = 10;
            this.maxFurnitureHeight = 400;

            this.minFurnitureWidth = 10;
            this.maxFurnitureWidth = 600;

            this.minFurnitureDepth = 10;
            this.maxFurnitureDepth = 90;
        }


        if (this.furniture.installation == 9 || this.furniture.installation == 10 || this.furniture.installation == 11 || this.furniture.installation == 12 || this.furniture.installation == 13 || this.furniture.installation == 14) {
            this.furniture.installationType = 2;
        }
        else if (this.furniture.installation == 8 || this.furniture.installation == 2 || this.furniture.installation == 3 || this.furniture.installation == 4 || this.furniture.installation == 5 || this.furniture.installation == 6) {
            this.furniture.installationType = 3;
        }
        else if (this.furniture.installation == 7 || this.furniture.installation == 15 ||this.furniture.installation == 16) {
            this.furniture.installationType = 4;
        }
        else {
            this.furniture.installationType = 1;
        }

        // console.log(this.furniture);
        
    }

    getMaterialName(category, id) {

        for(const el of category) {
            if (el.id == id) {
                return el.name;
            }
        }

    }

    // Furniture
    furniture: IFurnitureItem;
    panelItem: iPanelItem;
    mdfItem: iMDFItem;
    slidingDoorPanelItem: iPanelItem;
    slidingDoorMirrorItem: IMirrorItem;
    legItem: iLegItem;
    mirrorItem: IMirrorItem;
    knobItem: IKnobItem;
    fillingItem: iFillingItem;
    handleItem: iHandleItem;
    milledKnobItem: iMilledKnob;
    handleInternalItem: iHandleInternalItem;
    user: IUser;
    userToView: IUser;
    order: IOrder;

    items: any;
}

export interface IOrder {
    id: number;
    created: string;
    user_id: string;
    order_id: string;
    price: string;
    status: string;
    updated: string;
    user_comments: string;
    assembly: boolean;
    assembly_price: number;
    delivery: boolean;
    buying_company: boolean;
    advance_payment: boolean;
    items: IOrderItems[];
    user: IUser;
    carry: boolean;
    carry_price: number;
}

export interface IOrderItems {
    id: string;
    order_id: string;
    item_id: string;
    item_count: string;
    item: IFurnitureItem;
}


export interface IOffer {
    id: string;
    customer: string;
    order_no: string;
    date: string;
    total_price: string;
    carpanter_price: string;
    delivery_count: number;

    // UI Items
    items: IOfferItem[];
}

export interface IOfferItem {
    id: string;
    offer_id: string;
    item_id: string;
    title: string;
    price: number;
    
    item_count: number;
    assembly: number;
    assembly_count: number;
    carry_price: number;
    carry_count: number;

    extra_description_1: string;
    extra_description_2: string;
    extra_description_3: string;
    extra_description_4: string;
    extra_description_5: string;
    extra_price_1: number;
    extra_price_2: number;
    extra_price_3: number;
    extra_price_4: number;
    extra_price_5: number;
    sequence_no: number;
}

export interface iPanelItem {
    id: number;
    supplier: number;
    manufacturer: number;
    color_tone: number;
    color: number;
    gloss: number;
    roughness: number;
    pattern: number;
    name: string;
    code: string;
    thickness: string;
    height: string;
    width: string;
    price: string;
    edge_price_thin: string;
    edge_price_thick: string;
    image_1: string;
    image_2: string;
}

export interface iLegItem {
    id: number;
    supplier: number;
    manufacturer: number;
    material: number;
    color: number;
    adjustable_height: number;
    description: string;
    code: string;
    height: number;
    price: string;
    image_1: string;
}

export interface iHandleItem {
    id: number;
    supplier: number;
    manufacturer: number;
    color: number;
    code: string;
    length: number;
    distance: number;
    protrusion: number;
    price: string;
    material: number;
    texture: number;
    description: string;
    image_1: string;
    length_category: number;
}

export interface iHandleInternalItem {
    id: number;
    supplier: number;
    manufacturer: number;
    color: number;
    code: string;
    length: number;
    distance: number;
    depth: number;
    price: string;
    material: number;
    texture: number;
    description: string;
    image_1: string;
    length_category: number;
}

export interface IFurnitureItem {
    id: number;
    item_id: string;
    orderby: number;
    type: number;
    title: string;
    description: string;
    width: number;
    height: number;
    depth: number;
    price: number;
    extra_description_1: string;
    extra_description_2: string;
    extra_description_3: string;
    extra_description_4: string;
    extra_description_5: string;
    extra_price_1: number;
    extra_price_2: number;
    extra_price_3: number;
    extra_price_4: number;
    extra_price_5: number;
    price_from: number;
    quality: number;
    installation: number;
    installationType: number;
    knob: IKnobItem;
    knob_id: number;
    material: iPanelItem;
    material_id: number;
    material_type: number;
    material_is_mdf: boolean;

    collect_myself: boolean;
    carry_myself: boolean;

    material_different_sides: boolean;
    sliding_door_profiles: boolean;

    material_ls_mdf: iMDFItem;
    material_rs_mdf: iMDFItem;
    material_ts_mdf: iMDFItem;
    material_bs_mdf: iMDFItem;

    material_ls_lmdp: iPanelItem;
    material_rs_lmdp: iPanelItem;
    material_ts_lmdp: iPanelItem;
    material_bs_lmdp: iPanelItem;

    material_ls_lmdp_id: number;
    material_rs_lmdp_id: number;
    material_ts_lmdp_id: number;
    material_bs_lmdp_id: number;

    material_ls_type: number;
    material_rs_type: number;
    material_ts_type: number;
    material_bs_type: number;

    material_ls_is_mdf: boolean;
    material_rs_is_mdf: boolean;
    material_ts_is_mdf: boolean;
    material_bs_is_mdf: boolean;

    material_ls_mdf_id: number;
    material_rs_mdf_id: number;
    material_ts_mdf_id: number;
    material_bs_mdf_id: number;

    material_ls_mdf_gloss: number;
    material_rs_mdf_gloss: number;
    material_ts_mdf_gloss: number;
    material_bs_mdf_gloss: number;

    material_ls_mdf_has_fillings: boolean;
    material_rs_mdf_has_fillings: boolean;
    material_ts_mdf_has_fillings: boolean;
    material_bs_mdf_has_fillings: boolean;

    material_ls_mdf_filling_id: number;
    material_rs_mdf_filling_id: number;
    material_ts_mdf_filling_id: number;
    material_bs_mdf_filling_id: number;

    material_ls_mdf_filling: iFillingItem;
    material_rs_mdf_filling: iFillingItem;
    material_ts_mdf_filling: iFillingItem;
    material_bs_mdf_filling: iFillingItem;

    material_has_fillings: boolean;
    material_mdf: iMDFItem;
    material_mdf_id: number;
    material_mdf_thickness: number;
    material_mdf_gloss: number;
    material_mdf_filling: iFillingItem;
    material_mdf_filling_id: number;

    category: number;
    edges: number;
    additional: {
        option_1: boolean;
        option_2: boolean;
        option_3: boolean;
    };
    additional_2_height: number;
    additional_2_depth: number;
    additional_2_unknow: boolean;
    plinth: IPlinth;
    columnsCount: number;
    columns: IColumnItem[];
    entresol: boolean;
    entresolHeight: number;
    visible: boolean;
    image_1: string;
    image_2: string,
    image_3: string,
    image_4: string,
    image_5: string,
    rails: number,
    doors_count: number,
    with_drawers: boolean,
    draft: boolean,
    with_hangers: boolean,
    show_in_fp: boolean,

    slidingDoors: ISlidingDoorItem[];
    slidingDoorsCount: number;

    // UI
    activeDoor: ISlidingDoorItem;
    activeDoorRow: IDivideRows;
    activeColumn: IColumnItem;
    activeRow: IRowItem;
    modified: boolean;

    wall_width: number;
    wall_height: number;
}

export interface ISlidingDoorItem {
    id: number;
    door_id: number;
    order_no: number;
    divide: number;
    width: number;
    height: number;

    // UI
    active: boolean;
    divideRows: IDivideRows[];
}

export interface IMirrorItem {
    id: number;
    supplier: number;
    manufacturer: number;
    color: number;
    name: string;
    code: string;
    price_indicative: number;
    price: string;
    image_1: string;
    image_2: string;
    image_3: string;

    // UI
    active: boolean;
    mirror_id: number;
}

export interface IKnobItem {
    id: number;
    color: number;
    name: string;
    code: string;
    price_indicative: number;
    price_0_55: string;
    price_55_60: string;
    price_60_65: string;
    price_65_70: string;
    price_70_75: string;
    price_75_80: string;
    price_80_85: string;
    price_85_90: string;
    price_90_95: string;
    price_95_100: string;
    price_100_105: string;
    price_105_110: string;
    price_110_115: string;
    price_115_120: string;
    price_division: string;
    image_1: string;
    image_2: string;
    image_3: string;

    // UI
    active: boolean;
    knob_id: number;
}

export interface IColumnItem {
    id: number;
    order_no: number;
    column_id: number;
    width: number;
    inner_width: number;
    height: number;
    outer_height: number;
    rowsCount: number;
    rows: IRowItem[];

    // UI
    active: boolean;
}

export interface IMergedRows {
    row_id: number;
    parent_row_id: number;
    row_height: number;
    row_width: number;
    order_no: number;
}

export interface IRowItem {
    id: number;
    row_id: number;
    order_no: number;
    width: number;
    inner_width: number;
    height: number;
    inner_height: number;
    inner: number;
    outer: number;
    handleType: number;
    handle: iHandleItem;
    handle_internal: iHandleInternalItem;
    panel: iPanelItem;
    panelType: number;

    milled_knob: iMilledKnob;
    milled_knob_id: number;

    is_mdf: boolean;
    fillings: boolean;
    mdf: iMDFItem;
    mdf_id: number;
    
    mdf_thickness: number;
    mdf_gloss: number;

    filling: iFillingItem;
    filling_id: number;

    merged: boolean;
    parent: IRowItem;
    parent_row_id: number;
    merged_with: IMergedRows[];

    // UI
    active: boolean;
    panel_id: number;
    handle_id: number;
    handle_internal_id: number;

}

export interface iMilledKnob {
    id: number;
    name: string;
    code: string;
    price: string;
    description: string;
    image_1: string;
    image_2: string;
    image_3: string;
    image_4: string;
}

export interface iMDFItem {
    id: number;
    color_tone: number;
    color: number;
    roughness: number;
    pattern: number;
    name: string;
    code: string;
    price_matte_1_side: string;
    price_matte_2_side: string;
    price_shiny_1_side: string;
    price_super_shiny_1_side: string;
    price_super_shiny_2_side: string;
    height: string;
    width: string;
    price_extra_22_depth: string;
    price_extra_25_depth: string;
    image_1: string;
    image_2	: string;
}

export interface iFillingItem {
    id: number;
    filling_count: number;
    style: number;
    name: string;
    code: string;
    price_items: string;
    price_m2: string;
    description: string;
    thickness_18: boolean;
    thickness_22: boolean;
    thickness_25: boolean;
    image_1: string;
    image_2: string;
    image_3: string;
}

export interface IDivideRows {
    id: number;
    row_id: number;
    order_no: number;
    panel_id: number;
    mirror_id: number;
    knob_id: number;
    width: number;
    height: number;
    panel_type: number;
    handle_type: number;
    handle_id: number;
    filling_id: number;
    fillings: boolean;
    mdf_gloss: number;
    mdf_id: number

    handle_internal_id: number;
    milled_knob_id: number;
    mdf_thickness: number;

    // UI
    mdf: iMDFItem;
    active: boolean;
    panel: iPanelItem;
    mirror: IMirrorItem;
    knob: IKnobItem;
    handle: iHandleItem;
    filling: iFillingItem;
    handle_internal: iHandleInternalItem;
    milled_knob: iMilledKnob;
}

export interface IPlinth {
    type: number;
    height: number;
    legs_id: number;

    // UI
    legs: iLegItem;
    inner_height: number;
}

export interface IUser { 
    id: string;
    name: string;
    surname: string;
    email: string;
    phone: string;
    city: string;
    address: string;
    zip: string;
    company: string;
    company_name: string;
    company_code: string;
    company_vat: string;
    company_address: string;
    advertising: boolean;
    created: string;
    last_login: string;
    favorites: string;
    orders: string;
    admin: boolean;
    not_registered: boolean;
}