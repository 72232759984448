import './FiFilter.scss';
import { FiFilterService } from '../../components/fiFilter/FiFilterService';
import { IFurnitureItem } from '../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiFilter.html'),
    bindings: {
    },
})
export class FiFilter {
    static $inject = [
        '$scope',
        'fiFilterService',
        '$http',
        '$rootScope',
        '$timeout',
        '$location',
    ];

    constructor(
        private $scope: angular.IScope,
        private ss: FiFilterService,
        private $http: angular.IHttpService,
        private $rootScope: angular.IRootScopeService,
        private $timeout: angular.ITimeoutService,
        private $location: angular.ILocationService,
    ) {
        //
    }

    results = [];
    getTypeFromUrl: number = 0;
    // showInside: boolean = true;

    getDoorsFromUrl: number = 0;
    getOrderByFromUrl: number = 0;

   

    p_type: string = '';
    p_width: string = '';
    p_height: string = '';
    p_doors: string = '';
    p_drawers: string = '';
    p_inside: boolean = false;
    p_order: number = 1;

    $onInit() {

        

        this.$scope.$watch(() => this.$location.search().t, () => {
            this.setDefaultEmptyUrl();
            this.p_type = this.$location.search().t;

            if (this.p_type.includes('1')) { this.ss.settings.type1 = true; } else { this.ss.settings.type1 = false; }
            if (this.p_type.includes('2')) { this.ss.settings.type2 = true; } else { this.ss.settings.type2 = false; }
            if (this.p_type.includes('3')) { this.ss.settings.type3 = true; } else { this.ss.settings.type3 = false; }
            if (this.p_type.includes('4')) { this.ss.settings.type4 = true; } else { this.ss.settings.type4 = false; }
            if (this.p_type.includes('5')) { this.ss.settings.type5 = true; } else { this.ss.settings.type5 = false; }
            if (this.p_type.includes('6')) { this.ss.settings.type6 = true; } else { this.ss.settings.type6 = false; }

        });

        this.$scope.$watch(() => this.$location.search().w, () => {
            this.setDefaultEmptyUrl();
            this.p_width = this.$location.search().w;

            if (this.p_width.includes('1')) { this.ss.settings.width_0_60 = true; } else { this.ss.settings.width_0_60 = false; }
            if (this.p_width.includes('2')) { this.ss.settings.width_60_120 = true; } else { this.ss.settings.width_60_120 = false; }
            if (this.p_width.includes('3')) { this.ss.settings.width_120_180 = true; } else { this.ss.settings.width_120_180 = false; }
            if (this.p_width.includes('4')) { this.ss.settings.width_180_240 = true; } else { this.ss.settings.width_180_240 = false; }
            if (this.p_width.includes('5')) { this.ss.settings.width_240_300 = true; } else { this.ss.settings.width_240_300 = false; }
            if (this.p_width.includes('6')) { this.ss.settings.width_300_more = true; } else { this.ss.settings.width_300_more = false; }
        });


        this.$scope.$watch(() => this.$location.search().h, () => {
            this.setDefaultEmptyUrl();
            this.p_height = this.$location.search().h;

            if (this.p_height.includes('1')) { this.ss.settings.height_0_60 = true; } else { this.ss.settings.height_0_60 = false; }
            if (this.p_height.includes('2')) { this.ss.settings.height_60_120 = true; } else { this.ss.settings.height_60_120 = false; }
            if (this.p_height.includes('3')) { this.ss.settings.height_120_180 = true; } else { this.ss.settings.height_120_180 = false; }
            if (this.p_height.includes('4')) { this.ss.settings.height_180_220 = true; } else { this.ss.settings.height_180_220 = false; }
            if (this.p_height.includes('5')) { this.ss.settings.height_220_240 = true; } else { this.ss.settings.height_220_240 = false; }
            if (this.p_height.includes('6')) { this.ss.settings.height_240_more = true; } else { this.ss.settings.height_240_more = false; }
        
        });
        

        this.$scope.$watch(() => this.$location.search().d, () => {
            this.setDefaultEmptyUrl();
            this.p_doors = this.$location.search().d;

            if (this.p_doors.includes('1')) { this.ss.settings.doors_count_0 = true; } else { this.ss.settings.doors_count_0 = false; }
            if (this.p_doors.includes('2')) { this.ss.settings.doors_count_1 = true; } else { this.ss.settings.doors_count_1 = false; }
            if (this.p_doors.includes('3')) { this.ss.settings.doors_count_2 = true; } else { this.ss.settings.doors_count_2 = false; }
            if (this.p_doors.includes('4')) { this.ss.settings.doors_count_3 = true; } else { this.ss.settings.doors_count_3 = false; }
            if (this.p_doors.includes('5')) { this.ss.settings.doors_count_4 = true; } else { this.ss.settings.doors_count_4 = false; }
            if (this.p_doors.includes('6')) { this.ss.settings.doors_count_5 = true; } else { this.ss.settings.doors_count_5 = false; }
            if (this.p_doors.includes('7')) { this.ss.settings.doors_count_6 = true; } else { this.ss.settings.doors_count_6 = false; }
            if (this.p_doors.includes('8')) { this.ss.settings.doors_count_6_more = true; } else { this.ss.settings.doors_count_6_more = false; }
        });


        this.$scope.$watch(() => this.$location.search().dr, () => {
            this.setDefaultEmptyUrl();
            this.p_drawers = this.$location.search().dr;

            if (this.p_drawers.includes('1')) { this.ss.settings.drawers = true; } else { this.ss.settings.drawers = false; }
            if (this.p_drawers.includes('2')) { this.ss.settings.hangers = true; } else { this.ss.settings.hangers = false; }

        });


        this.$scope.$watch(() => this.$location.search().i, () => {
           
            if (this.$location.search().i == 'true') {
                this.ss.showInside = true;
            }
            else {
                this.ss.showInside = false;
            }

        });

        this.$scope.$watch(() => this.ss.showInside, () => {
            this.setLocationUrl();
        });

        this.$scope.$watch(() => this.ss.orderBy, () => {
            this.setLocationUrl();
        });


        this.$scope.$watch(() => this.$location.search().o, () => {

            this.ss.orderBy = this.$location.search().o;

            console.log('url order: ' + this.$location.search().o);
            console.log('or: '+ this.ss.orderBy);

            this.selectOrderBy(this.ss.orderBy);
        });

        this.$http.get('https://www.fitit.lt/db/items.php').then((data) => {
            this.ss.items = [];
            this.ss.items.push(data.data);

            this.ss.items = this.ss.items[0].filter(x => x.category == 2 && x.visible == 1);

            this.ss.settings.width.min = this.ss.settings.width.options.floor = Math.min.apply(Math, this.ss.items.map(function(o) { return o.width; }));
            this.ss.settings.width.max = this.ss.settings.width.options.ceil = Math.max.apply(Math, this.ss.items.map(function(o) { return o.width; }));

            this.ss.settings.price.min = this.ss.settings.price.options.floor = Math.min.apply(Math, this.ss.items.map(function(o) { return o.price; }));
            this.ss.settings.price.max = this.ss.settings.price.options.ceil = Math.max.apply(Math, this.ss.items.map(function(o) { return o.price; }));

            this.ss.settings.height.min = this.ss.settings.height.options.floor = Math.min.apply(Math, this.ss.items.map(function(o) { return o.height; }));
            this.ss.settings.height.max = this.ss.settings.height.options.ceil = Math.max.apply(Math, this.ss.items.map(function(o) { return o.height; }));

            this.ss.settings.depth.min = this.ss.settings.depth.options.floor = Math.min.apply(Math, this.ss.items.map(function(o) { return o.depth; }));
            this.ss.settings.depth.max = this.ss.settings.depth.options.ceil = Math.max.apply(Math, this.ss.items.map(function(o) { return o.depth; }));

            this.ss.settings.doors_count.min = this.ss.settings.doors_count.options.floor = Math.min.apply(Math, this.ss.items.map(function(o) { return o.doors_count; }));
            this.ss.settings.doors_count.max = this.ss.settings.doors_count.options.ceil = Math.max.apply(Math, this.ss.items.map(function(o) { return o.doors_count; }));

            this.ss.results = this.ss.items;

            // this.ss.settings.price.min = Number(this.ss.settings.price.min);
            this.ss.settings.price.min = Number(Math.round(this.ss.settings.price.min) - 1);
            this.ss.settings.price.max = Number(Math.round(this.ss.settings.price.max) + 1);

            this.$scope.$watch(() => this.ss.settings.width.min, () => this.applyFilter());
            this.$scope.$watch(() => this.ss.settings.width.max, () => this.applyFilter());

            this.$scope.$watch(() => this.ss.settings.price.min, () => this.applyFilter());
            this.$scope.$watch(() => this.ss.settings.price.max, () => this.applyFilter());  
            
            this.$scope.$watch(() => this.ss.settings.height.min, () => this.applyFilter());
            this.$scope.$watch(() => this.ss.settings.height.max, () => this.applyFilter()); 

            this.$scope.$watch(() => this.ss.settings.depth.min, () => this.applyFilter());
            this.$scope.$watch(() => this.ss.settings.depth.max, () => this.applyFilter()); 

            this.$scope.$watch(() => this.ss.settings.doors_count.min, () => this.applyFilter());
            this.$scope.$watch(() => this.ss.settings.doors_count.max, () => this.applyFilter()); 

            // this.$scope.$watch(() => this.$location.search().type, () => this.applyUrlFilter());


            // this.$scope.$watch(() => this.$location.search().orderby, () => this.selectOrderBy(this.$location.search().orderby));


            // this.$scope.$watch(() => 
            // this.$location.search().showinside, () =>  
            // (this.$location.search().showinside == 'true' ? this.switchShowInside(true) : this.switchShowInside(false)));

        });
        
        this.$timeout(() => {
            this.$rootScope.$broadcast('reCalcViewDimensions');
        }, 250);

    }

    selectOrderBy(order: number) {

        this.ss.orderBy = order;

        if (this.ss.orderBy == 1) {
            this.ss.orderByValue = 'orderby';
            this.ss.orderByOrder = true;
        }
        else if (this.ss.orderBy == 2) {
            this.ss.orderByValue = 'price';
            this.ss.orderByOrder = false;
        }
        else if (this.ss.orderBy == 3) {
            this.ss.orderByValue = 'price';
            this.ss.orderByOrder = true;
        }

        // this.replacePath(order);


        // Setting up URL
        // this.setLocationUrl();
    }

    applyUrlFilter() {
        this.getTypeFromUrl = this.$location.search().type;

        // this.getDoorsFromUrl = this.$location.search().doors;
        // console.log(this.getTypeFromUrl);

        if (this.getTypeFromUrl == 1) {
            this.ss.settings.type1 = true;
            this.ss.settings.type2 = false;
            this.ss.settings.type3 = false;
            this.ss.settings.type4 = false;
            this.ss.settings.type5 = false;
            this.ss.settings.type6 = false;
        }
        else if (this.getTypeFromUrl == 2) {
            this.ss.settings.type1 = false;
            this.ss.settings.type2 = true;
            this.ss.settings.type3 = false;
            this.ss.settings.type4 = false;
            this.ss.settings.type5 = false;
            this.ss.settings.type6 = false;
        }
        else if (this.getTypeFromUrl == 3) {
            this.ss.settings.type1 = false;
            this.ss.settings.type2 = false;
            this.ss.settings.type3 = true;
            this.ss.settings.type4 = false;
            this.ss.settings.type5 = false;
            this.ss.settings.type6 = false;
        }
        else if (this.getTypeFromUrl == 4) {
            this.ss.settings.type1 = false;
            this.ss.settings.type2 = false;
            this.ss.settings.type3 = false;
            this.ss.settings.type4 = true;
            this.ss.settings.type5 = false;
            this.ss.settings.type6 = false;
        }
        else if (this.getTypeFromUrl == 5) {
            this.ss.settings.type1 = false;
            this.ss.settings.type2 = false;
            this.ss.settings.type3 = false;
            this.ss.settings.type4 = false;
            this.ss.settings.type5 = true;
            this.ss.settings.type6 = false;
        }
        else if (this.getTypeFromUrl == 6) {
            this.ss.settings.type1 = false;
            this.ss.settings.type2 = false;
            this.ss.settings.type3 = false;
            this.ss.settings.type4 = false;
            this.ss.settings.type5 = false;
            this.ss.settings.type6 = true;
        }

        this.applyFilter();

    }

    switchShowInside(inside: boolean) {


        this.p_inside = inside;
        this.ss.showInside = inside;

        this.setLocationUrl();

        // this.ss.showInside = !this.ss.showInside;
        // this.ss.showInside = this.$location.search().showinside;

        // this.ss.showInside = showinside;
        
        //this.replacePath(this.$location.search().orderby, showinside);

        // console.log('a');
    }

    replacePath(orderby: number, showinside: boolean) {
        // this.$location.path('/baldai%3Ftype=4&orderby=' + orderby);

        // this.$location.url('/baldai?type=4&orderby=' + orderby + '&showinside=' + showinside);
        // console.log('b');
    }

    setDefaultEmptyUrl() {
        if (!this.p_type) { this.p_type = ''; }
        if (!this.p_width) { this.p_width = ''; }
        if (!this.p_height) { this.p_height = ''; }
        if (!this.p_doors) { this.p_doors = ''; }
        if (!this.p_drawers) { this.p_drawers = ''; }
        // if (!this.p_inside) { this.p_inside = false; }
        // if (!this.p_order) { this.p_order = 1; }
    }

    setLocationUrl() {
        this.$location.url('/baldai?t=' + this.p_type + '&w=' + this.p_width + '&h=' + this.p_height + '&d=' + this.p_doors + '&dr=' + this.p_drawers + '&o=' + this.ss.orderBy + '&i=' + this.ss.showInside);
        this.applyFilter();
    }

    setUrlType(type: string) {

        if (this.p_type.includes(type)) {
            this.p_type = this.p_type.replace(type, '');
        }
        else {
            this.p_type = this.p_type + type;
        }
        
        this.setLocationUrl();

    }

    setUrlWidth(width: string) {

        if (this.p_width.includes(width)) {
            this.p_width = this.p_width.replace(width, '');
        }
        else {
            this.p_width = this.p_width + width;
        }

        this.setLocationUrl();
    }

    setUrlHeight(height: string) {
        if (this.p_height.includes(height)) {
            this.p_height = this.p_height.replace(height, '');
        }
        else {
            this.p_height = this.p_height + height;
        }

        this.setLocationUrl();
    }

    setUrlDoors(doors: string) {
        if (this.p_doors.includes(doors)) {
            this.p_doors = this.p_doors.replace(doors, '');
        }
        else {
            this.p_doors = this.p_doors + doors;
        }

        this.setLocationUrl();
    }

    setUrlDrawers(drawers: string) {
        if (this.p_drawers.includes(drawers)) {
            this.p_drawers = this.p_drawers.replace(drawers, '');
        }
        else {
            this.p_drawers = this.p_drawers + drawers;
        }

        this.setLocationUrl();
    }

    applyFilter() {

        this.ss.results = this.ss.items;
        this.ss.results = this.ss.results.filter(item => 
            item.width >= this.ss.settings.width.min && 
            item.width <= this.ss.settings.width.max && 

            item.price >= this.ss.settings.price.min && 
            item.price <= this.ss.settings.price.max &&

            item.height >= this.ss.settings.height.min && 
            item.height <= this.ss.settings.height.max &&

            item.depth >= this.ss.settings.depth.min && 
            item.depth <= this.ss.settings.depth.max &&

            item.doors_count >= this.ss.settings.doors_count.min && 
            item.doors_count <= this.ss.settings.doors_count.max
        );
        
        // Type
        if (this.ss.settings.type1 || this.ss.settings.type2 || this.ss.settings.type3 || this.ss.settings.type4 || this.ss.settings.type5|| this.ss.settings.type6) {
            this.ss.results = this.ss.results.filter(item => 
                item.type == (this.ss.settings.type1 === true ? 1 : 0) ||
                item.type == (this.ss.settings.type2 === true ? 2 : 0) ||
                item.type == (this.ss.settings.type3 === true ? 3 : 0) ||
                item.type == (this.ss.settings.type4 === true ? 4 : 0) ||
                item.type == (this.ss.settings.type5 === true ? 5 : 0) ||
                item.type == (this.ss.settings.type6 === true ? 6 : 0)
            );
        }

        
        // Quality
        if (this.ss.settings.quality1 || this.ss.settings.quality2 || this.ss.settings.quality3) {
            this.ss.results = this.ss.results.filter(item => 
                item.type == (this.ss.settings.quality1 === true ? 1 : 0) ||
                item.type == (this.ss.settings.quality2 === true ? 2 : 0) ||
                item.type == (this.ss.settings.quality3 === true ? 3 : 0)
            );
        }
        

        // Legs
        if (this.ss.settings.plinth_type1 || this.ss.settings.plinth_type2 || this.ss.settings.plinth_type3 || this.ss.settings.plinth_type4) {
            this.ss.results = this.ss.results.filter(item => 
                item.type == (this.ss.settings.plinth_type1 === true ? 1 : 0) ||
                item.type == (this.ss.settings.plinth_type2 === true ? 2 : 0) ||
                item.type == (this.ss.settings.plinth_type3 === true ? 3 : 0) ||
                item.type == (this.ss.settings.plinth_type4 === true ? 4 : 0)
            );
        }
        

        // ?

        // Edges
        if (this.ss.settings.edges1 || this.ss.settings.edges2 || this.ss.settings.edges3 || this.ss.settings.edges4 || this.ss.settings.edges5) {
            this.ss.results = this.ss.results.filter(item => 
                item.type == (this.ss.settings.edges1 === true ? 1 : 0) ||
                item.type == (this.ss.settings.edges2 === true ? 2 : 0) ||
                item.type == (this.ss.settings.edges3 === true ? 3 : 0) ||
                item.type == (this.ss.settings.edges4 === true ? 4 : 0) ||
                item.type == (this.ss.settings.edges5 === true ? 5 : 0)
            );
        }

        // Width
        if (
            this.ss.settings.width_0_60 || 
            this.ss.settings.width_60_120 ||
            this.ss.settings.width_120_180 ||
            this.ss.settings.width_180_240 ||
            this.ss.settings.width_240_300 ||
            this.ss.settings.width_300_more) {

                this.ss.results = this.ss.results.filter(item => 
                    (this.ss.settings.width_0_60 === true ? item.width > 0 && (item.width < 60 || item.width == 60) : null) ||
                    (this.ss.settings.width_60_120 === true ? (item.width > 60 || item.width == 60) && (item.width < 120 || item.width == 120) : null) ||
                    (this.ss.settings.width_120_180 === true ? (item.width > 120 || item.width == 120) && (item.width < 180 || item.width == 180) : null) ||
                    (this.ss.settings.width_180_240 === true ? (item.width > 180 || item.width == 180) && (item.width < 240 || item.width == 240) : null) ||
                    (this.ss.settings.width_240_300 === true ? (item.width > 240 || item.width == 240) && (item.width < 300 || item.width == 300) : null) ||
                    (this.ss.settings.width_300_more === true ? (item.width > 300 || item.width == 300) : null)
                );
        }


        // Height
        if (
            this.ss.settings.height_0_60 || 
            this.ss.settings.height_60_120 ||
            this.ss.settings.height_120_180 ||
            this.ss.settings.height_180_220 ||
            this.ss.settings.height_220_240 ||
            this.ss.settings.height_240_more) {

                this.ss.results = this.ss.results.filter(item => 
                    (this.ss.settings.height_0_60 === true ? item.height > 0 && (item.height < 60 || item.height == 600) : null) ||
                    (this.ss.settings.height_60_120 === true ? (item.height > 60 || item.height == 60) && (item.height < 120 || item.height == 120) : null) ||
                    (this.ss.settings.height_120_180 === true ? (item.height > 120 || item.height == 120) && (item.height < 180 || item.height == 180) : null) ||
                    (this.ss.settings.height_180_220 === true ? (item.height > 180 || item.height == 180) && (item.height < 220 || item.height == 220) : null) ||
                    (this.ss.settings.height_220_240 === true ? (item.height > 220 || item.height == 220) && (item.height < 240 || item.height == 240) : null) ||
                    (this.ss.settings.height_240_more === true ? (item.height > 240 || item.height == 240) : null)
                );
        }


        // Hangers
        if (this.ss.settings.hangers) {
            this.ss.results = this.ss.results.filter(item => 
                item.with_hangers == 1
            );
        }

        // Drawers
        if (this.ss.settings.drawers) {
            this.ss.results = this.ss.results.filter(item => 
                item.with_drawers == 1
            );
        }

        // Doors count
        if (this.ss.settings.doors_count_0 || 
            this.ss.settings.doors_count_1 || 
            this.ss.settings.doors_count_2 || 
            this.ss.settings.doors_count_3 || 
            this.ss.settings.doors_count_4 || 
            this.ss.settings.doors_count_5 ||
            this.ss.settings.doors_count_6 ||
            this.ss.settings.doors_count_6_more) {


                this.ss.results = this.ss.results.filter(item => 
                    (this.ss.settings.doors_count_0 === true ? item.doors_count == 0 : null) ||
                    (this.ss.settings.doors_count_1 === true ? item.doors_count == 1 : null) ||
                    (this.ss.settings.doors_count_2 === true ? item.doors_count == 2 : null) ||
                    (this.ss.settings.doors_count_3 === true ? item.doors_count == 3 : null) ||
                    (this.ss.settings.doors_count_4 === true ? item.doors_count == 4 : null) ||
                    (this.ss.settings.doors_count_5 === true ? item.doors_count == 5 : null) ||
                    (this.ss.settings.doors_count_6 === true ? item.doors_count == 6 : null) ||
                    (this.ss.settings.doors_count_6_more === true ? item.doors_count > 6 : null)
                );

        }

    }
}
