export class FiWallService {
    
    static $inject = [
    ];

    constructor(
    ) {
    }


    wall: Wall
}

export interface Wall {
    id: string;
    width: number;
    height: number;
    depth: number;
    columnsCount: number;
    columns: Column[];
}

export interface Column {
    id: string;
    active: boolean;
    rows: Row[];
    rowsCount: number;
    order_no: number;
    outer: number;

    // UI
    clickCount: number;
}

export interface Row {
    id: string;
    active: boolean;
    columnsCount: number;
    columns: Column[];
    deepness: number;
    inner: number;
    outer: number;
    order_no: number;

    // UI
    clickCount: number;
}