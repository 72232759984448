import './SuccessOffer.scss';
import { Component } from '../../components/Component';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./SuccessOffer.html'),
    bindings: {
    }
})
export class SuccessOffer {
    static $inject = [
        //
    ];


    constructor(
        //
    ) {
        /* */
    }

    $onInit() {
        //
    }

}
