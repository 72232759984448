import './FiLoginDialog.scss';

import * as angular from 'angular';
import { FiToastService } from './../../components/fiToast/FiToastService';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiLoginDialog.html'),
    bindings: {
        type: '<'
    },
})
export class FiLoginDialog {
    static $inject = [
        '$mdDialog',
        '$http',
        'fiToastService',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $http: angular.IHttpService,
        private ts: FiToastService,
    ) {
        //
    }

    private type: number;
    userEmail: string;
    userPassword: string;
    userRemindPassword: string;
    view: number = 1;

    $onInit() {
        //
        console.log(this.type);
    }

    login() {
        this.$http.post("https://www.fitit.lt/db/get-user.php", { 

            email: this.userEmail,
            password: this.userPassword,

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                if(response.data == 'return') {
                    this.ts.show('Neteisingi prisijungimo duomenys');
                    return;
                }
                else {
                    // this.wrongLogin = false;
                    const currentUser = {
                        id: response.data[0].id,
                        name: response.data[0].name,
                        surname: response.data[0].surname,
                        email: response.data[0].email,
                        phone: response.data[0].phone,
                        city: response.data[0].city,
                        address: response.data[0].address,
                        zip: response.data[0].zip,
                        company: response.data[0].company == 1 ? true : false,
                        company_name: response.data[0].company_name,
                        company_code: response.data[0].company_code,
                        company_vat: response.data[0].company_vat,
                        company_address: response.data[0].company_address,
                        advertising: response.data[0].advertising == 1 ? true : false,
                        created: response.data[0].created,
                        last_login: response.data[0].last_login,
                        favorites: response.data[0].favorites,
                        orders: response.data[0].orders,
                        admin: response.data[0].admin  == 1 ? true : false
                    }

                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    location.reload();

                }
            }
        );
    }

    remind() {
        alert(this.userRemindPassword);
    }
    
    close() {
        this.$mdDialog.cancel();
    }
}