import './Offer.scss';
import * as angular from 'angular';
import { Component } from '../../components/Component';
import { FiSubHeaderService } from '../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService, IOffer } from '../../components/fiFurniture/FiFurnitureService';
import { FiToastService } from '../../components/fiToast/FiToastService';
import * as uirouter from 'angular-ui-router';
import { FiPrice } from '../../services/Price';


@Component({
    controllerAs: 'vm',
    template: require('./Offer.html'),
    bindings: {
    }
})
export class Offer {
    static $inject = [
        '$http',
        'fiSubHeaderService',
        '$window',
        '$mdDialog',
        'fiFurnitureService',
        '$state',
        'fiToastService',
        '$timeout',
        'fiPrice',
    ];


    constructor(
        private $http: angular.IHttpService,
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $state: uirouter.StateService,
        private ts: FiToastService,
        private $timeout: angular.ITimeoutService,
        private ps: FiPrice,
    ) {
        /* */
    }
    
    customer: string;
    customerFirstNameLetters: any;
    order_no: string;
    // carpanterPrice: string;
    today: Date;
    private user: any;
    admin: boolean;
    acceptRules: boolean;
    loading: boolean;
    loadingReload: boolean;
    isNew: boolean;

    $onInit() {

        this.user = JSON.parse(localStorage.getItem('currentUser'));
        if (!this.user || !this.user.admin) {
            this.admin = false;
        }
        else {
            this.admin = true;
        }

        this.fshs.subHeaderIsHidden = true;
        this.fshs.blankPage = true;
        this.today = new Date();
        // this.carpanterPrice = '49.99';

        this.order_no = this.$state.params['orderNo'];

        if (!this.$state.params['orderNo']) {
            this.isNew = true;
        }
        else {
            this.isNew = false;
        }

        if (this.order_no) {
            this.$http.get('https://www.fitit.lt/db/offer/item.php?id=' + this.order_no).then((data) => {
                if (data.data[0].id) {
                    this.fs.offer = data.data[0];
                }
                else {
                    this.$window.location.href = '/uzsakymai';
                }
            });
        }
        else {

            // this.order_no = this.formatDate() + this.createOrderNo();
            this.order_no = this.formatDate();
            const offer = {
                customer: null,
                order_no: this.order_no,
                date: this.today.toString(),
                total_price: null,
                carpanter_price: '49.99',
                delivery_count: 1,
                items: []
            } as IOffer

            this.fs.offer = offer;
        }

    }

    editOrderNo() {
        const name = this.fs.offer.customer.split(' ');
        this.fs.offer.order_no = this.formatDate() + name[0].charAt(0).toUpperCase() + name[1].charAt(0).toUpperCase();
    }

    goBack() {
        this.$window.location.href = '/uzsakymai';
    }

    print() {
        this.$window.print()
    }

    formatDate() {
        var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('');
    }

    reload() {

        this.loadingReload = true;

        for(const item of this.fs.offer.items) {

            this.fs.selectFurniture(item.item_id, false);

            this.$timeout(() => {

                item.assembly = this.ps.getAssemblyPrice();
                item.carry_price = this.ps.getCarryPrice();

                this.$http.post("https://www.fitit.lt/db/offer/reload.php", { 

                    item_id: item.item_id,
                    carry_price: item.carry_price,
                    assembly: item.assembly
        
                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) {
                        //
                });

            }, 1000);

            
        }

        this.$timeout(() => {

            this.loadingReload = false;
            location.reload();

        }, 3000);

    }

    save() {

        this.loading = true;
        // Saving offer
        this.$http.post("https://www.fitit.lt/db/offer/new.php", { 

            order_no: this.fs.offer.order_no,
            customer: this.fs.offer.customer,
            date: this.today,
            total_price: this.fs.offer.total_price,
            delivery_count: this.fs.offer.delivery_count,
            carpanter_price: this.fs.offer.carpanter_price,

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        // Delete all old
        this.$http.post("https://www.fitit.lt/db/offer/delete-old-items.php", { 
            order_no: this.fs.offer.order_no,
        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
        });
     
        this.$timeout(() => {

            // Saving items
            let num = 0;
            for(const item of this.fs.offer.items) {
                this.$http.post("https://www.fitit.lt/db/offer/new-offer-items.php", { 

                    item_id: item.item_id,
                    title: item.title,
                    price: item.price,
                    order_no: this.fs.offer.order_no,
                    item_count: item.item_count,
                    assembly: item.assembly,
                    assembly_count: item.assembly_count,
                    carry_price: item.carry_price,
                    carry_count: item.carry_count,
                    extra_price_1: item.extra_price_1,
                    extra_price_2: item.extra_price_2,
                    extra_price_3: item.extra_price_3,
                    extra_price_4: item.extra_price_4,
                    extra_price_5: item.extra_price_5,
                    extra_description_1: item.extra_description_1,
                    extra_description_2: item.extra_description_2,
                    extra_description_3: item.extra_description_3,
                    extra_description_4: item.extra_description_4,
                    extra_description_5: item.extra_description_5,
                    sequence_no: num = num + 1,
        
                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) {
                        //
                });
            }

            // Success message
            this.ts.show('Išsaugota');
            this.loading = false;

        }, 2000);
        
    }

    pay() {
        const checkoutForm = <HTMLFormElement>document.getElementById("checkoutForm");
        checkoutForm.submit();
    }


    getPayseraAmount() {
        return (Number(this.getTotalPrice()) / 2).toFixed(2).replace(/\./g,'');
    }

    createOrderNo() {
        // const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        // let result = "";
        // for (let i = 0; i < 2; i++) {
            // result += chars.charAt(Math.floor(Math.random() * chars.length));
        // }
        // return result;
    }

    getTotalPrice() {

        let total = 0;
        for(const item of this.fs.offer.items) {
            total = total + ((item.item_count * (Number(item.price) + Number(item.extra_price_1) + Number(item.extra_price_2) + Number(item.extra_price_3) + Number(item.extra_price_4) + Number(item.extra_price_5))) + (Number(item.assembly) * Number(item.assembly_count)) + (Number(item.carry_price) * Number(item.carry_count)));
        }

        total = total + (45 * this.fs.offer.delivery_count);

        this.fs.offer.total_price = total.toString();
        
        if (this.fs.offer.items.length == 0) {
            return 0;
        }
        else {
            return total.toFixed(2);
        }
        

        // if (total) {
            // this.fs.offer.total_price = total.toString();
        // }

        // if (!this.fs.offer.items.length) {
            // this.fs.offer.total_price = '0'
            // return 0;
        // }
        // else {
            // return this.fs.offer.total_price;    
        // }

    }


    addItem() {

        this.$mdDialog.show({
            template: '<fi-offer-items-dialog></fi-offer-items-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    removeItem(item) {
        this.fs.offer.items.splice(this.fs.offer.items.indexOf(item), 1);
    }
}
