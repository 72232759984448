import './FiGalleryItemEditDialog.scss';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiGalleryItemEditDialog.html'),
    bindings: {
        infoTitle: '@',
        infoContent: '@',
    },
})
export class FiGalleryItemEditDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    $onInit() {
        // console.log(this.fs.furniture)
    }

    close() {
        this.$mdDialog.cancel();
    }
}