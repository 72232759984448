import './Demo.scss';

import { Component } from '../../components/Component';
import * as angular from 'angular';

import { FiSubHeaderService } from '../../components/fiSubHeader/FiSubHeaderService';


@Component({
    controllerAs: 'vm',
    template: require('./Demo.html'),
    bindings: {
    }
})
export class Demo {
    static $inject = [
        'fiSubHeaderService',
    ];


    constructor(
        private fshs: FiSubHeaderService,
    ) {
        
    }

    $onInit() {
       
        // For Demo (delete)
        this.fshs.isDemo = true;
        this.fshs.subHeaderIsHidden = true;
    }

}
