import './FiSubHeader.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiSubHeaderService } from './FiSubHeaderService';

@Component({
    controllerAs: 'vm',
    template: require('./FiSubHeader.html'),
    bindings: {
    },
})
export class FiSubHeader {
    static $inject = [
        'fiSubHeaderService',
    ];

    private translateUnhook: any;

    constructor(
        private fshs: FiSubHeaderService,
    ) {
    }

    $onInit() {
        //
    }

}
