const approvedElementList = [
    'MD-BACKDROP'
];

export default [
    '$animateProvider',
    (
        $animateProvider: any,
    ) => {
        $animateProvider.customFilter((node: Element, event: string, options: any) => {
            return document['documentMode'] ? approvedElementList.indexOf(node.nodeName) > -1 : true;
        });
    }
];
