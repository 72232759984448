import { FiFilterService } from '../../components/fiFilter/FiFilterService';

export class FiSubHeaderService {
    static $inject = [
        '$window',
        'fiFilterService',
    ];

    constructor(
        private $window: angular.IWindowService,
        private ss: FiFilterService,
    ) {
        //   
    }

    page: number = 0;
    subHeaderIsHidden: boolean = false;

    isDemo: boolean = false;
    blankPage: boolean = false;

    switchPage(page: number) {

        // this.$window.location.href = '/baldai?type=' + page;

        this.$window.location.href = '/baldai?t=' + page + '&w=&h=&d=&dr=&o=1&i=false';
        
    }
   
}
