import './FiMirrorDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiMirrorDialog.html'),
    bindings: {
        hideButton: '<'
    },
})
export class FiMirrorDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    private type: number;
    mainImage: number = 1;
    hideButton: boolean;

    $onInit() {
        //

        console.log(this.fs.mirrorItem);
    }

    next() {
        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.mirrorItem);
        const nextItem = index + 1;
        if (nextItem == this.fs.items.length) {
            return false;
        }
        else {
            this.fs.mirrorItem = this.fs.items[nextItem];
        }
    }

    previous() {

        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.mirrorItem);
        const nextItem = index - 1;
        if (nextItem < 0) {
            return false;
        }
        else {
            this.fs.mirrorItem = this.fs.items[nextItem];
        }
    }

    select() {
      

            if (this.fs.sameSlidingDoorsPanel) {
                for(const column of this.fs.furniture.slidingDoors) {
                    for(const row of column.divideRows) {
                        row.mirror = this.fs.mirrorItem;
                        row.mirror_id = this.fs.mirrorItem.id;
                    }
                }
            }
            else {
                this.fs.furniture.activeDoorRow.mirror = this.fs.mirrorItem;
                this.fs.furniture.activeDoorRow.mirror_id = this.fs.mirrorItem.id;
            }

        
        
        this.$mdDialog.cancel();
    }

    close() {
        this.$mdDialog.cancel();
    }

    switchImage(imageNumber) {

        if (imageNumber < 1 || imageNumber > 5) {
            return
        }

        this.mainImage = imageNumber;
    }

    viewImage(img) {
        this.$window.open('/img/mirrors/' + img, '_blank');
    }
}