export class FiExpansionPanelService {

    static $inject = [
        '$location'
    ];

    constructor(
        private $location: angular.ILocationService,
    ) {
    }

    activeExpansionPanel: number = 0;

    expand(panelNumber: number) {

        if (this.activeExpansionPanel == panelNumber) {
            this.activeExpansionPanel = 0;
        }
        else {
            this.activeExpansionPanel = panelNumber;
            // this.$location.hash('1964');
        }

    }
}
