import './UserOrders.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from './../../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./UserOrders.html'),
    bindings: {
    }
})
export class UserOrders {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    user: any;
    orders = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.post("https://www.fitit.lt/db/get-user-orders.php", { 
                userID: this.user.id,
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    this.orders.push(response.data);
                    console.log(response.data);
            });
        }
    }

    getOrderStatus(status: number) {
        if (status == 0) {
            return 'Laukiama apmokėjimo'; 
        }
        else if (status == 1) {
            return 'Apmokėtas';
        }
    }

    viewOrder() {
        
    }
}
