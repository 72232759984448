import './FiSummary.scss';

import * as angular from 'angular';
import { FiFurnitureService, IColumnItem, IRowItem } from './../../components/fiFurniture/FiFurnitureService';
import { Component } from '../Component';
import { FiPrice } from '../../services/Price';
import { FiCart } from '../../services/Cart';
import { FiFurniture } from '../fiFurniture/FiFurniture';


@Component({
    controllerAs: 'vm',
    template: require('./FiSummary.html'),
    bindings: {

    },
})
export class FiSummary {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
        '$mdSidenav',
        'fiPrice',
        'fiCart',
    ];

    zoom: number;
    mobile: boolean;
    cartStorage: FiCart;
    counter: number = 0;

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private $mdSidenav: angular.material.ISidenavService,
        private ps: FiPrice,
        private cart: FiCart,
    ) {
        //
    }

    $onInit() {
        this.mobile = this.$window.innerWidth <= 768;
    }

    closeDetails() {
        this.$mdSidenav('item-details').close();
    }

    openRequest() {

        this.$mdDialog.show({
            template: '<fi-request-dialog></fi-panel-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }

    openTypeSelectionDialog(canChange: boolean) {

        this.$mdDialog.show({
            template: '<fi-furniture-type-selection-dialog></fi-furniture-type-selection-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: canChange,
            controllerAs: 'vm'
        });

    }
    
    countDoors() {

        if (this.fs.furniture) {
            this.counter = 0;

            this.fs.furniture.columns.forEach((column: IColumnItem) => {
                column.rows.forEach((row: IRowItem) => {
                    if (row.outer == 1 || row.outer == 2 || row.outer == 3 || row.outer == 4 || row.outer == 5) {
                        this.counter = this.counter + 1;
                    }
                });
            });
    
            return this.counter;
        }
        
    }

    addToCart() {
        
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        this.fs.furniture.price = this.ps.getPrice(this.fs.furniture);

        if (this.cartStorage) {
            this.cart.items = this.cartStorage.items;
        }
        this.cart.items.push({
            item: this.fs.furniture,
            count: 1,
            assemblyPrice: this.ps.getAssemblyPrice(),
            carryPrice: this.ps.getCarryPrice()
        });

        // console.log(this.cart.items);

        this.cart.count = this.cart.items.length;

        localStorage.setItem('cart', JSON.stringify(this.cart));

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'EUR',
            'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
                'name': this.fs.furniture.title,
                'id': this.fs.furniture.item_id,
                'price': this.fs.furniture.price,
                'quantity': 1
            }]
            }
        }
        });

        this.$window.location.href = '/krepselis';

        // this.showCartLink = true;
        
    }

    resize(height: number, width: number) {

        let containerW = 0;
        let containerH = 0;

        if (this.mobile) {
            containerW = 320;
            containerH = 450;
        }
        else {
            containerW = 320;
            containerH = 450;
        }
      
        this.zoom = (containerH / ((height + 80) / 100)) / 100;

        if ((width + 80) * this.zoom < containerW) {
            return this.zoom;
        }
        else {
            return (containerW / ((width + 80) / 100)) / 100;
        }
    }

    downSize(height: number, width: number) {
        const scaleSize = this.resize(height, width);
        return 1 / scaleSize;
    }

    showDialog(item, category) {
        if (category == 1) {

            this.fs.panelItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="1" hide-button="true"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
            
        }
        else if (category == 2) {

            this.fs.legItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-leg-dialog hide-button="true"></fi-fe-leg-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });

        }
        else if (category == 3) {
            this.fs.handleItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-dialog></fi-fe-handle-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
        else if (category == 4) {
            this.fs.handleInternalItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-internal-dialog></fi-fe-handle-internal-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
    }

    getInstallationPic(type: number) {
            if (type == 1) { return 'imontavimas_1.jpg'; }
            else if (type == 2) { return 'imontavimas_2.jpg'; }
            else if (type == 3) { return 'imontavimas_3.jpg'; }
            else if (type == 4) { return 'imontavimas_4.jpg'; }
            else if (type == 5) { return 'imontavimas_5.jpg'; }
            else if (type == 6) { return 'imontavimas_6.jpg'; }
            else if (type == 7) { return 'imontavimas_7.jpg'; }
            else if (type == 8) { return 'imontavimas_5.jpg'; }
            else if (type == 9) { return 'imontavimas_6.jpg'; }
            else if (type == 10) { return 'imontavimas_2.jpg'; }
            else if (type == 11) { return 'imontavimas_3.jpg'; }
            else if (type == 12) { return 'imontavimas_4.jpg'; }
            else if (type == 13) { return 'imontavimas_5.jpg'; }
            else if (type == 14) { return 'imontavimas_6.jpg'; }
            else if (type == 15) { return 'imontavimas_7.jpg'; }
            else if (type == 16) { return 'imontavimas_6.jpg'; }
     
    }

    getInstallationInfoTitle(installationType: number) {
        if (installationType == 1) { return 'Įmontavimas'; }
        else if (installationType == 2) { return 'Laisvai pastatoma nišoje'; }
        else if (installationType == 3) { return 'Įmontuojama'; }
        else if (installationType == 4) { return 'Pakabinama'; }
    }

    getInstallationInfo(type: number) {
        if (type == 1) { return 'Laisvai pastatoma'; }
        else if (type == 2) { return 'Tvirtinama tik prie lubų'; }
        else if (type == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
        else if (type == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
        else if (type == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
        else if (type == 6) { return 'Tvirtinama prie abiejų sienų'; }
        else if (type == 7) { return 'Nepasirinkta'; }
        else if (type == 8) { return 'Nepasirinkta'; }
        else if (type == 9) { return 'Nepasirinkta'; }
        else if (type == 10) { return 'Iki lubų'; }
        else if (type == 11) { return 'Iki lubų ir k. sienos'; }
        else if (type == 12) { return 'Iki lubų ir d. sienos'; }
        else if (type == 13) { return 'Iki lubų ir abiejų sienų'; }
        else if (type == 14) { return 'Iki abiejų sienų'; }
        else if (type == 15) { return 'Tik pakabinama'; }
        else if (type == 16) { return 'iki abiejų sienų'; }
    }

    getInfoType(type: number) {
        if (type == 1) { return 'Spinta su varstomomis durimis'; }
        else if (type == 2) { return 'Spinta su stumdomis durimis'; }
        else if (type == 3) { return 'Komoda'; }
        else if (type == 4) { return 'Lentyna'; }
        else if (type == 5) { return 'TV spintelė'; }
        else if (type == 6) { return 'Spintelė'; }
    }

    getInfoEdges(type: number) {
        if (type == 1) { return 'Durelės dengia briaunas'; }
        else if (type == 2) { return 'Matosi visos'; }
        else if (type == 3) { return 'Matosi viršus ir apačia'; }
        else if (type == 4) { return 'Matosi viršus'; }
        else if (type == 5) { return 'Matosi išorinės'; }
        else if (type == 6) { return 'Be stogelio (matosi šonai)'; }
        else if (type == 7) { return 'Su stogeliu (matosi visos)'; }
        else if (type == 8) { return 'Durelės dengia briaunas'; }
        else if (type == 9) { return 'Matosi šonai'; }
        else if (type == 10) { return 'Matosi viršus ir šonai'; }
        else if (type == 11) { return 'Matosi išorinės ir vertikalios'; }
        else if (type == 12) { return 'Matosi išorinės ir horizontalius'; }
        else if (type == 13) { return 'Matosi šonai'; }

    }


    getInfoPlinth(type: number) {
        if (type == 1) { return 'Padas'; }
        else if (type == 2) { return 'Kojelės'; }
        else if (type == 3) { return 'Plokštė'; }
        else if (type == 4) { return 'Be pagrindo'; }
        else if (type == 5) { return 'Cokolis'; }
    }

    getInfoQuality(type: number) {
        if (type == 1) { return 'Ekonominė'; }
        else if (type == 2) { return 'Premium'; }
        else if (type == 3) { return 'Premium +'; }
    }

    getInfo(type: string) {
        if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return 'Laisvai pastatoma'; }
            else if (this.fs.furniture.installation == 2) { return 'Tvirtinama tik prie lubų'; }
            else if (this.fs.furniture.installation == 3) { return 'Tvirtinama prie lubų ir k. sienos'; }
            else if (this.fs.furniture.installation == 4) { return 'Tvirtinama prie lubų ir d. sienos'; }
            else if (this.fs.furniture.installation == 5) { return 'Tvirtinama prie lubų ir abiejų sienų'; }
            else if (this.fs.furniture.installation == 6) { return 'Tvirtinama prie abiejų sienų'; }
            else if (this.fs.furniture.installation == 7) { return 'Pakabinama'; }
        }
    }


    getInfoPicPlinth(type: number) {
        if (type == 1) { return '/img/icons/pagrindas/pagrindas_1_active.jpg'; }
        else if (type == 2) { return '/img/icons/pagrindas/pagrindas_2_active.jpg'; }
        else if (type == 3) { return '/img/icons/pagrindas/pagrindas_3_active.jpg'; }
        else if (type == 4) { return '/img/icons/pagrindas/pagrindas_4_active.jpg'; }
        else if (type == 5) { return '/img/icons/pagrindas/pagrindas_5_active.jpg'; }
    }

    getInfoPicQuality(type: number) {
        if (type == 1) { return '/img/icons/papildomos_paslaugos/furnituros_klase_1.jpeg'; }
        else if (type == 2) { return '/img/icons/papildomos_paslaugos/furnituros_klase_2.jpeg'; }
        else if (type == 3) { return '/img/icons/papildomos_paslaugos/furnituros_klase_3.jpeg'; }
    }

    getInfoPicEdges(type: number) {
        if (type == 1) { return '/img/icons/briaunos/briaunos_1.jpg'; }
        else if (type == 2) { return '/img/icons/briaunos/briaunos_2.jpg'; }
        else if (type == 3) { return '/img/icons/briaunos/briaunos_3.jpg'; }
        else if (type == 4) { return '/img/icons/briaunos/briaunos_4.jpg'; }
        else if (type == 5) { return '/img/icons/briaunos/briaunos_5.jpg'; }
        else if (type == 6) { return '/img/icons/briaunos/briaunos_6.jpg'; }
        else if (type == 7) { return '/img/icons/briaunos/briaunos_7.jpg'; }
        else if (type == 8) { return '/img/icons/briaunos/briaunos_8.jpg'; }
        else if (type == 9) { return '/img/icons/briaunos/briaunos_9.jpg'; }
        else if (type == 10) { return '/img/icons/briaunos/briaunos_10.jpg'; }
    }

    getInfoPic(type: string) {
        if (type == 'type') {
            if (this.fs.furniture.type == 1) { return '/img/icons/baldotipas/baldo_tipas_1.jpg'; }
            else if (this.fs.furniture.type == 2) { return '/img/icons/baldotipas/baldo_tipas_2.svg'; }
            else if (this.fs.furniture.type == 3) { return '/img/icons/baldotipas/baldo_tipas_3.jpg'; }
            else if (this.fs.furniture.type == 4) { return '/img/icons/baldotipas/baldo_tipas_4.jpg'; }
            else if (this.fs.furniture.type == 5) { return '/img/icons/baldotipas/baldo_tipas_5.jpg'; }
            else if (this.fs.furniture.type == 6) { return '/img/icons/baldotipas/baldo_tipas_6.jpg'; }
        }
        else if (type == 'installation') {
            if (this.fs.furniture.installation == 1) { return '/img/icons/imontavimas/imontavimas_1_active.jpg'; }
            else if (this.fs.furniture.installation == 2) { return '/img/icons/imontavimas/imontavimas_2_active.jpg'; }
            else if (this.fs.furniture.installation == 3) { return '/img/icons/imontavimas/imontavimas_3_active.jpg'; }
            else if (this.fs.furniture.installation == 4) { return '/img/icons/imontavimas/imontavimas_4_active.jpg'; }
            else if (this.fs.furniture.installation == 5) { return '/img/icons/imontavimas/imontavimas_5_active.jpg'; }
            else if (this.fs.furniture.installation == 6) { return '/img/icons/imontavimas/imontavimas_6_active.jpg'; }
            else if (this.fs.furniture.installation == 7) { return '/img/icons/imontavimas/imontavimas_7_active.jpg'; }
        }
        else if (type == 'edges') {
            
        }
    }

    getOuterName(type: number) {
        if (type == 0) {
            return 'Be durelių';
        }
        else if (type == 1) {
            return 'Durys (Kairė)';
        }
        else if (type == 2) {
            return 'Durys (Dešinė)';
        }
        else if (type == 3) {
            return 'Durys (Dvigubos)';
        }
        else if (type == 4) {
            return 'Durys (Viršus)';
        }
        else if (type == 5) {
            return 'Durys (Apačia)';
        }
        else if (type == 6) {
            return 'Stalčius';
        }
    }
}