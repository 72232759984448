import './FiSelectSlidingDoorsPanelMaterialDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, iMDFItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiSelectSlidingDoorsPanelMaterialDialog.html'),
    bindings: {
        hideButton: '<' 
    },
})
export class FiSelectSlidingDoorsPanelMaterialDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    lmdp_items = [];
    lmdp_results = [];

    mirrors_items = [];
    mirrors_results = [];

    mdf_items = [];
    mdf_results = [];


    filter = {
        lmdp: {
            color_tone: 0,
            color: 0,
            gloss: 0,
            roughness: 0,
            pattern: 0,
            manufacturer: 0,
            supplier: 0
        }
    };

    $onInit() {

        // LMDP
        this.$http.get('https://www.fitit.lt/db/lmdp.php').then((data) => {
            this.lmdp_items.push(data.data);
            this.lmdp_results = this.lmdp_items[0];
        });

        // mirrors
        this.$http.get('https://www.fitit.lt/db/mirrors.php').then((data) => {
            this.mirrors_items.push(data.data);
            this.mirrors_results = this.mirrors_items[0];
        });

        // MDF
        this.$http.get('https://www.fitit.lt/db/mdf.php').then((data) => {
            this.mdf_items.push(data.data);
            this.mdf_results = this.mdf_items[0];
        });

    }

    applyFilter(filterCategory: number) {

        if (filterCategory == 2) {

            this.lmdp_results = this.lmdp_items[0];

            if (this.filter.lmdp.color_tone) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color_tone == this.filter.lmdp.color_tone );
            }
            
            if (this.filter.lmdp.color) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color == this.filter.lmdp.color );
            }

            if (this.filter.lmdp.gloss) {
                this.lmdp_results = this.lmdp_results.filter(item => item.gloss == this.filter.lmdp.gloss );
            }
            
            if (this.filter.lmdp.roughness) {
                this.lmdp_results = this.lmdp_results.filter(item => item.roughness == this.filter.lmdp.roughness );
            }

            if (this.filter.lmdp.pattern) {
                this.lmdp_results = this.lmdp_results.filter(item => item.pattern == this.filter.lmdp.pattern );
            }
            
            if (this.filter.lmdp.supplier) {
                this.lmdp_results = this.lmdp_results.filter(item => item.supplier == this.filter.lmdp.supplier );
            }
        }
    }

    applyMDFFilter() {
        this.fs.furniture.activeDoorRow.mdf_gloss = this.fs.selected_mdf_gloss;
    }

    getMDFPanelPrice(mdf: iMDFItem) {

        if (this.fs.selected_mdf_gloss == 0) {
            return mdf.price_matte_2_side;
        }
        else if (this.fs.selected_mdf_gloss == 1) {
            return mdf.price_matte_1_side;
         }
        else if (this.fs.selected_mdf_gloss == 2) {
            return mdf.price_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 3) {
            return mdf.price_super_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 4) {
            return mdf.price_super_shiny_2_side;
        }
    }

    selectSlidingDoorPanelType(selectedType: number) {
        if (this.fs.sameSlidingDoorsPanel) {
            for(const door of this.fs.furniture.slidingDoors) {
                for(const row of door.divideRows) {
                    row.panel_type = selectedType;
                    // console.log(selectedType);
                    // Cia zymeti ta pati LMDP arba veidrodi
                }
            }
        }
        else {
            this.fs.furniture.activeDoorRow.panel_type = selectedType;
        }
    }

    close() {
        this.$mdDialog.cancel();
    }

}