import './UserMenu.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./UserMenu.html'),
    bindings: {
        active: '<'
    },
})
export class UserMenu {
    static $inject = [
        '$rootScope',
    ];

    private translateUnhook: any;
    private user: any;

    constructor(
        private $rootScope: angular.IRootScopeService,
    ) {
    }

    $onInit() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));
    }

}
