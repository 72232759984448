import './FiKnobDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiKnobDialog.html'),
    bindings: {
        hideButton: '<'
    },
})
export class FiKnobDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    private type: number;
    mainImage: number = 1;
    hideButton: boolean;

    $onInit() {
        //
    }

    next() {
        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.knobItem);
        const nextItem = index + 1;
        if (nextItem == this.fs.items.length) {
            return false;
        }
        else {
            this.fs.knobItem = this.fs.items[nextItem];
        }
    }

    previous() {

        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.knobItem);
        const nextItem = index - 1;
        if (nextItem < 0) {
            return false;
        }
        else {
            this.fs.knobItem = this.fs.items[nextItem];
        }
    }

    select() {
      
        this.fs.furniture.knob = this.fs.knobItem;
        this.fs.furniture.knob_id = this.fs.knobItem.id;

        this.$mdDialog.cancel();
        
    }

    close() {
        this.$mdDialog.cancel();
    }

    switchImage(imageNumber) {

        if (imageNumber < 1 || imageNumber > 5) {
            return
        }

        this.mainImage = imageNumber;
    }

    viewImage(img) {
        this.$window.open('/img/knob_large/' + img, '_blank');
    }
}