import './SketchMeasurement.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./SketchMeasurement.html'),
    bindings: {
    },
})
export class SketchMeasurement {
    static $inject = [
        'fiFurnitureService',
    ];


    constructor(
        private fs: FiFurnitureService,
    ) {
    }

    $onInit() {
        //
    }

}
