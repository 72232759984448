import './FiLegDialog.scss';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiLegDialog.html'),
    bindings: {

    },
})
export class FiLegDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
    }

    close() {
        this.$mdDialog.cancel();
    }

    save() {

        // Cia priskirti ir php Update
        alert('save');
    }

    attachFile() {
        console.log('atach');
    }

}