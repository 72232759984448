import './UserProfile.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from './../../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../../components/fiToast/FiToastService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./UserProfile.html'),
    bindings: {
    }
})
export class UserProfile {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
        '$timeout',
        'fiToastService',
    ];

    user: any;

    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private $timeout: angular.ITimeoutService,
        private ts: FiToastService,
    ) {
        /* */
    }

    $onInit() {
        this.fshs.subHeaderIsHidden = false;
        
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        console.log(this.user);

        if (!this.user || this.user.admin) {
            this.$window.location.href = '/';
        }
    }

    update() {

        this.$http.post("https://www.fitit.lt/db/user-profile.php", { 

            id: this.user.id,
            name: this.user.name,
            surname: this.user.surname,
            email: this.user.email,
            phone: this.user.phone,
            city: this.user.city,
            address: this.user.address,
            zip: this.user.zip,
            company: this.user.company,
            company_name: this.user.company_name,
            company_code: this.user.company_code,
            company_vat: this.user.company_vat,
            company_address: this.user.company_address,
            advertising: this.user.advertising

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                if(response.data == 'return') {
                    return
                }
                else {
                    
                    const currentUser = {
                        id: this.user.id,
                        name: this.user.name,
                        surname: this.user.surname,
                        email: this.user.email,
                        phone: this.user.phone,
                        city: this.user.city,
                        address: this.user.address,
                        zip: this.user.zip,
                        company: this.user.company == 1 ? true : false,
                        company_name: this.user.company_name,
                        company_code: this.user.company_code,
                        company_vat: this.user.company_vat,
                        company_address: this.user.company_address,
                        advertising: this.user.advertising  == 1 ? true : false,
                        created: this.user.created,
                        last_login: this.user.last_login,
                        favorites: this.user.favorites,
                        orders: this.user.orders,
                        admin: this.user.admin  == 1 ? true : false
                    }

                    localStorage.setItem('currentUser', JSON.stringify(currentUser));

                    this.ts.show('Informacija atnaujinta');
                    
                }
            }
        );

    }
}
