import '../css/app.scss';
// import './app.scss';
import './components';
import './directives';
import './filters';
import './services';
import './viewComponents';
import './views';
//import '@uirouter/angularjs';
import 'angular-ui-router';
import 'angular-cookies';
import 'angular-dynamic-locale';
import 'angular-material';
import 'angular-messages';
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-storage-cookie';
import 'angular-translate-storage-local';
import 'ng-infinite-scroll';
import 'angularjs-color-picker';
import 'angularjs-slider';
import 'ngstorage';
import 'tinymce';
import 'angular-ui-tinymce';
import 'angular-drag-drop';
import 'angular-drag-and-drop-lists';
import 'angular-highlightjs';

import * as angular from 'angular';

import Compiler from './configuration/Compiler';
import Routing from './configuration/Routing';
import Animation from './configuration/Animation';
import KeyDownEvent from './configuration/KeyDownEvent';

export const app = angular.module('app', [
    'ngAnimate',
    'dndLists',
    'ngCookies',
    'ngStorage',
    'ngMaterial',
    'ngMessages',
    'ngSanitize',
    'ui.router',
    'infinite-scroll',
    'color.picker',
    'pascalprecht.translate',
    'tmh.dynamicLocale',
    'ui.tinymce',
    'filearts.dragDrop',
    'hljs',
    'rzSlider',
    'app.components',
    'app.directives',
    'app.services',
    'app.filters',
    'app.views',
    'app.viewComponents'])
    .config(Routing)
    .config(Compiler)
    .config(Animation)
    .run(KeyDownEvent)
;

app.filter('startFrom', function() {
    return function(input, start) {
        start = +start; //parse to int
        return input.slice(start);
    }
});
