import './FiModalTopFour.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiModalTopFour.html'),
    bindings: {

    },
})
export class FiModalTopFour {
    static $inject = [
        '$mdDialog',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
    ) {
        //
    }

    $onInit() {
        //
    }

    close() {
        this.$mdDialog.cancel();
    }

}