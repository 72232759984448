import './AdminItems.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from './../../../components/FiSubHeader/FiSubHeaderService';
import { IFurnitureItem, FiFurnitureService } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./AdminItems.html'),
    bindings: {
    }
})
export class AdminItems {
    static $inject = [
        'fiSubHeaderService',
        'fiFurnitureService',
        '$window',
        '$http',
        '$mdDialog',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    private user: any;
    loading: boolean;
    view: number;
    data = [];
    dataConfigurator = [];
    requests = [];

    // Paging
    pageSize: number;
    currentPage: number;
    numberOfPages: number;
    searchText: string;
    draft: boolean;


    $onInit() {

        this.loading = true;

        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        }

        this.currentPage = 0;
        this.pageSize = 50;

        this.$http.get('https://www.fitit.lt/db/admin-list-items.php').then((data) => {
            this.data.push(data);
            

            // console.log(this.data[0].data);
            // console.log(this.data[0].data.length);

            this.numberOfPages = Math.ceil(this.data[0].data.length/this.pageSize); 

            this.loading = false;
        });

        this.$http.get('https://www.fitit.lt/db/requests_list.php').then((data) => {
            this.requests.push(data);
            this.loading = false;
        });

        this.$http.get('https://www.fitit.lt/db/admin-list-items-configurator.php').then((dataConfigurator) => {
            this.dataConfigurator.push(dataConfigurator);
            this.loading = false;
        });

        this.view = 1;

        
    }

    navBack(page: number) {
        if (this.currentPage > 0) {
            this.currentPage = this.currentPage - 1;
        }
    }
    
    getType(type: number) {
        if (type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }

    viewDetails(itemId) {
        this.$window.location.href = '/baldai/' + itemId;

        // this.$window.location.href = '/item?id=' + itemId;
    }

    editImages(item: IFurnitureItem) {

        this.fs.furniture = item;

        this.$mdDialog.show({
            template: '<fi-image-editor-dialog></fi-image-editor-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }
    
    goToConfigurator(item_id: string, newItem: boolean) {

        if (newItem) {
            this.$window.location.href = '/konfiguratorius/new';
        }
        else {
            this.$window.location.href = '/konfiguratorius/' + item_id + '?edit=1';
        }

        // this.fs.selectFurniture(item_id);
        // this.fs.newItem = newItem;
        // this.$window.location.href = '/konfiguratorius/' + item_id;
    }

    switchFPVisibility(item: IFurnitureItem) {

        item.show_in_fp = !item.show_in_fp;
       
        this.$http.post("https://www.fitit.lt/db/item-show-in-fp.php", { 

            id: item.id,
            show_in_fp: item.show_in_fp

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

              // 

            }
        );
    }

    switchCollectMyself(item: IFurnitureItem) {

        item.collect_myself = !item.collect_myself;
       
        this.$http.post("https://www.fitit.lt/db/item-collect-myself.php", { 

            id: item.id,
            collect_myself: item.collect_myself

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

              // 

            }
        );
    }


    switchVisibility(item: IFurnitureItem) {

        item.visible = !item.visible;
       
        this.$http.post("https://www.fitit.lt/db/item-visibility.php", { 

            id: item.id,
            visible: item.visible

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

              // 

            }
        );
    }
}
