import * as angular from 'angular';

import { FiHeader } from './fiHeader/FiHeader';
import { FiSubHeader } from './fiSubHeader/FiSubHeader';
import { FiFooter } from './fiFooter/FiFooter';
import { FiFilter } from './fiFilter/FiFilter';
import { FiMeter } from './fiMeter/FiMeter';
import { UserMenu } from './userMenu/UserMenu';
import { FiExpansionPanel } from './fiExpansionPanel/FiExpansionPanel';
import { FiExpansionPanelService } from './fiExpansionPanel/FiExpansionPanelService';
import { FiFilterService } from './fiFilter/FiFilterService';
import { FiFurniture } from './fiFurniture/FiFurniture';
import { FiConfiguratorSettings } from './fiConfiguratorSettings/FiConfiguratorSettings';
import { FiFurnitureService } from './fiFurniture/FiFurnitureService';
import { FiCart } from './../services/Cart';
import { FiPrice } from './../services/Price';
import { FiBox } from './FiBox/FiBox';
import { FiMaterialDialog } from './fiMaterialDialog/FiMaterialDialog';
import { FiMirrorDialog } from './fiMirrorDialog/FiMirrorDialog';
import { FiReloadDialog } from './fiReloadDialog/FiReloadDialog';
import { FiRearwallDialog } from './fiRearwallDialog/FiRearwallDialog';
import { FiInfoDialog } from './fiInfoDialog/FiInfoDialog';
import { FiImageEditorDialog } from './fiImageEditorDialog/FiImageEditorDialog';
import { FiGalleryItemEditDialog } from './fiGalleryItemEditDialog/FiGalleryItemEditDialog';
import { FiAddPhotoDialog } from './fiAddPhotoDialog/FiAddPhotoDialog';
import { FiRemoveFromCartDialog } from './fiRemoveFromCartDialog/FiRemoveFromCartDialog';
import { FiSubHeaderService } from './fiSubHeader/FiSubHeaderService';
import { FiDuk } from './fiDuk/FiDuk';
import { FiDukService } from './FiDuk/FiDukService';
import { FiPanelDialog } from './fiPanelDialog/FiPanelDialog';
import { FiImageDialog } from './fiImageDialog/FiImageDialog';
import { FiHandleDialog } from './fiHandleDialog/FiHandleDialog';
import { FiLegDialog } from './fiLegDialog/FiLegDialog';
import { FiFeLegDialog } from './fiFeLegDialog/FiFeLegDialog';
import { FiChooseInternal } from './fiChooseInternal/FiChooseInternal';
import { FiChooseDivide } from './fiChooseDivide/FiChooseDivide';
import { FiOfferItemsDialog } from './fiOfferItemsDialog/FiOfferItemsDialog';

import { History } from './../services/History';
import { FiWallService } from './../views/kitchen/KitchenService';

import { FiChooseOuter } from './fiChooseOuter/FiChooseOuter';

import { FiFeHandleDialog } from './fiFeHandleDialog/FiFeHandleDialog';
import { FiMilledKnobDialog } from './fiMilledKnobDialog/FiMilledKnobDialog';

import { FiFeHandleInternalDialog } from './fiFeHandleInternalDialog/FiFeHandleInternalDialog';
import { FiUserDialog } from './fiUserDialog/FiUserDialog';
import { FiToast } from './fiToast/FiToast';
import { FiToastService } from './fiToast/FiToastService';
import { FiLoginDialog } from './fiLoginDialog/FiLoginDialog';
import { FiNoResults } from './fiNoResults/FiNoResults';
import { FiItemCard } from './fiItemCard/FiItemCard';
import { FiKnobDialog } from './fiKnobDialog/FiKnobDialog';

import { FiModalTopOne } from './fiModalTopOne/FiModalTopOne';
import { FiModalTopTwo } from './fiModalTopTwo/FiModalTopTwo';
import { FiModalTopThree } from './fiModalTopThree/FiModalTopThree';
import { FiModalTopFour } from './fiModalTopFour/FiModalTopFour';
import { FiModalTopFive } from './fiModalTopFive/FiModalTopFive';
import { FiModalTopSix } from './fiModalTopSix/FiModalTopSix';
import { FiModalTopSeven } from './fiModalTopSeven/FiModalTopSeven';

import { FiTestimonials } from './fiTestimonials/FiTestimonials';
import { FiSelectPlinthDialog } from './fiSelectPlinthDialog/FiSelectPlinthDialog';
import { FiSelectMaterialDialog } from './fiSelectMaterialDialog/FiSelectMaterialDialog';
import { FiSelectPanelMaterialDialog } from './fiSelectPanelMaterialDialog/FiSelectPanelMaterialDialog';
import { FiSelectSlidingDoorsPanelMaterialDialog } from './fiSelectSlidingDoorsPanelMaterialDialog/FiSelectSlidingDoorsPanelMaterialDialog';
import { FiSelectPanelFillingDialog } from './fiSelectPanelFillingDialog/FiSelectPanelFillingDialog';
import { FiPanelFillingDialog } from './fiPanelFillingDialog/FiPanelFillingDialog';
import { FiSelectPanelHandleDialog } from './fiSelectPanelHandleDialog/FiSelectPanelHandleDialog';
import { FiSelectMaterialFillingDialog } from './fiSelectMaterialFillingDialog/FiSelectMaterialFillingDialog';

import { FiSelectSlidingDoorHandleDialog } from './fiSelectSlidingDoorHandleDialog/FiSelectSlidingDoorHandleDialog';
import { FiSelectFurnitureKnob } from './fiSelectFurnitureKnob/FiSelectFurnitureKnob';

import { FiRequestDialog } from './fiRequestDialog/FiRequestDialog';
import { FiFurnitureTypeSelectionDialog } from './fiFurnitureTypeSelectionDialog/FiFurnitureTypeSelectionDialog';

import { FiTextModal } from './fiTextModal/FiTextModal';

import { FiContactMe } from './fiContactMe/FiContactMe';

import { SketchOutside } from './sketchOutside/SketchOutside';
import { SketchSlidingDoors } from './sketchSlidingDoors/SketchSlidingDoors';

import { SketchInside } from './sketchInside/SketchInside';
import { SketchMeasurement } from './sketchMeasurement/SketchMeasurement';

import { FiSummary } from './fiSummary/FiSummary';
import { FiChip } from './fiChip/FiChip';


export default angular.module('app.components', [])

    .component('fiHeader', FiHeader as angular.IComponentOptions)
    .component('fiSubHeader', FiSubHeader as angular.IComponentOptions)
    .component('fiFooter', FiFooter as angular.IComponentOptions)
    .component('fiFilter', FiFilter as angular.IComponentOptions)
    .component('fiSelectPlinthDialog', FiSelectPlinthDialog as angular.IComponentOptions)
    .component('fiSelectMaterialDialog', FiSelectMaterialDialog as angular.IComponentOptions)
    .component('fiSelectPanelMaterialDialog', FiSelectPanelMaterialDialog as angular.IComponentOptions)
    .component('fiSelectSlidingDoorsPanelMaterialDialog', FiSelectSlidingDoorsPanelMaterialDialog as angular.IComponentOptions)
    .component('fiSelectPanelFillingDialog', FiSelectPanelFillingDialog as angular.IComponentOptions)
    .component('fiSelectPanelHandleDialog', FiSelectPanelHandleDialog as angular.IComponentOptions)
    .component('fiSelectSlidingDoorHandleDialog', FiSelectSlidingDoorHandleDialog as angular.IComponentOptions)
    .component('fiSelectFurnitureKnob', FiSelectFurnitureKnob as angular.IComponentOptions)
    .component('fiSelectMaterialFillingDialog', FiSelectMaterialFillingDialog as angular.IComponentOptions)
    .component('fiPanelFillingDialog', FiPanelFillingDialog as angular.IComponentOptions)
    .component('userMenu', UserMenu as angular.IComponentOptions)
    .component('fiExpansionPanel', FiExpansionPanel as angular.IComponentOptions)
    .component('fiFurniture', FiFurniture as angular.IComponentOptions)
    .component('fiConfiguratorSettings', FiConfiguratorSettings as angular.IComponentOptions)
    .component('fiBox', FiBox as angular.IComponentOptions)
    .component('fiChooseInternal', FiChooseInternal as angular.IComponentOptions)
    .component('fiChooseDivide', FiChooseDivide as angular.IComponentOptions)
    .component('fiChooseOuter', FiChooseOuter as angular.IComponentOptions)
    .component('fiChip', FiChip as angular.IComponentOptions)
    .component('fiFurnitureTypeSelectionDialog', FiFurnitureTypeSelectionDialog as angular.IComponentOptions)
    .component('fiMeter', FiMeter as angular.IComponentOptions)
    .component('fiMaterialDialog', FiMaterialDialog as angular.IComponentOptions)
    .component('fiReloadDialog', FiReloadDialog as angular.IComponentOptions)
    .component('fiRearwallDialog', FiRearwallDialog as angular.IComponentOptions)
    .component('fiInfoDialog', FiInfoDialog as angular.IComponentOptions)
    .component('fiImageEditorDialog', FiImageEditorDialog as angular.IComponentOptions)
    .component('fiGalleryItemEditDialog', FiGalleryItemEditDialog as angular.IComponentOptions)
    .component('fiAddPhotoDialog', FiAddPhotoDialog as angular.IComponentOptions)
    .component('fiRemoveFromCartDialog', FiRemoveFromCartDialog as angular.IComponentOptions)
    .component('fiDuk', FiDuk as angular.IComponentOptions)
    .component('fiPanelDialog', FiPanelDialog as angular.IComponentOptions)
    .component('fiImageDialog', FiImageDialog as angular.IComponentOptions)
    .component('fiHandleDialog', FiHandleDialog as angular.IComponentOptions)
    .component('fiLegDialog', FiLegDialog as angular.IComponentOptions)
    .component('fiFeLegDialog', FiFeLegDialog as angular.IComponentOptions)
    .component('fiFeHandleDialog', FiFeHandleDialog as angular.IComponentOptions)
    .component('fiMilledKnobDialog', FiMilledKnobDialog as angular.IComponentOptions)
    .component('fiOfferItemsDialog', FiOfferItemsDialog as angular.IComponentOptions)

    .component('fiFeHandleInternalDialog', FiFeHandleInternalDialog as angular.IComponentOptions)
    .component('fiUserDialog', FiUserDialog as angular.IComponentOptions)
    .component('fiToast', FiToast as angular.IComponentOptions)
    .component('fiLoginDialog', FiLoginDialog as angular.IComponentOptions)
    .component('fiNoResults', FiNoResults as angular.IComponentOptions)
    .component('fiItemCard', FiItemCard as angular.IComponentOptions)
    .component('fiMirrorDialog', FiMirrorDialog as angular.IComponentOptions)
    .component('fiModalTopOne', FiModalTopOne as angular.IComponentOptions)
    .component('fiModalTopTwo', FiModalTopTwo as angular.IComponentOptions)
    .component('fiModalTopThree', FiModalTopThree as angular.IComponentOptions)
    .component('fiModalTopFour', FiModalTopFour as angular.IComponentOptions)
    .component('fiModalTopFive', FiModalTopFive as angular.IComponentOptions)
    .component('fiModalTopSix', FiModalTopSix as angular.IComponentOptions)
    .component('fiModalTopSeven', FiModalTopSeven as angular.IComponentOptions)
    .component('fiKnobDialog', FiKnobDialog as angular.IComponentOptions)
    .component('fiTestimonials', FiTestimonials as angular.IComponentOptions)

    .component('fiTextModal', FiTextModal as angular.IComponentOptions)
    .component('fiRequestDialog', FiRequestDialog as angular.IComponentOptions)

    .component('fiContactMe', FiContactMe as angular.IComponentOptions)

    .component('fiSummary', FiSummary as angular.IComponentOptions)
    
    .component('sketchSlidingDoors', SketchSlidingDoors as angular.IComponentOptions)
    .component('sketchOutside', SketchOutside as angular.IComponentOptions)
    .component('sketchInside', SketchInside as angular.IComponentOptions)
    .component('sketchMeasurement', SketchMeasurement as angular.IComponentOptions)

    // Services
    .service('fiSubHeaderService', FiSubHeaderService)
    .service('fiExpansionPanelService', FiExpansionPanelService)
    .service('fiFilterService', FiFilterService)
    .service('fiDukService', FiDukService)
    .service('fiFurnitureService', FiFurnitureService)
    .service('fiToastService', FiToastService)
    .service('fiCart', FiCart)
    .service('fiPrice', FiPrice)

    .service('fiWallService', FiWallService)
    .service('history', History)

    .config(function($sceDelegateProvider) {  
        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain. **.
            'https://www.fitit.lt/**'
        ]);
    })
;