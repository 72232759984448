export class FiDukService {
    static $inject = [
        //
    ];

    activeDukItem: number = 0;

    expand(dukItem: number) {
        if (this.activeDukItem == dukItem) {
            this.activeDukItem = 0;
        }
        else {
            this.activeDukItem = dukItem;
        }
    }

}
