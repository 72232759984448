import { IFurnitureItem } from "../components/fiFurniture/FiFurnitureService";

export class FiCart {
    count: number = 0;
    totalPrice: number = 0;
    delivery: boolean = true;
    assembly: boolean = true;
    carry: boolean = true;
    environment: number = 0;
    items: ICartItem[] = [];
    // user: IUserService;
}

export interface ICartItem {
    item: IFurnitureItem;
    assemblyPrice: number;
    count: number;
    carryPrice: number;
}