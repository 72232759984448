export class FiFilterService {
    static $inject = [
        //
    ];

    showInside: boolean = false;
    
    orderBy: number = 1;
    
    orderByValue: string = 'orderby';
    orderByOrder: boolean = true;
    

    type1: boolean = false;
    type2: boolean = false;
    type3: boolean = false;
    type4: boolean = false;
    type5: boolean = false;
    type6: boolean = false;

    quality1: boolean = false;
    quality2: boolean = false;
    quality3: boolean = false;

    clearFilters() {
        this.settings.type1 = false;
        this.settings.type2 = false;
        this.settings.type3 = false;
        this.settings.type4 = false;
        this.settings.type5 = false;
        this.settings.type6 = false;
    }

    items = [];
    results = [];

    settings = {
        test: 1,
        type1: false,
        type2: false,
        type3: false,
        type4: false,
        type5: false,
        type6: false,

        quality1: false,
        quality2: false,
        quality3: false,

        edges1: false,
        edges2: false,
        edges3: false,
        edges4: false,
        edges5: false,

        plinth_type1: false,
        plinth_type2: false,
        plinth_type3: false,
        plinth_type4: false,

        doors_count_0: false,
        doors_count_1: false,
        doors_count_2: false,
        doors_count_3: false,
        doors_count_4: false,
        doors_count_5: false,
        doors_count_6: false,
        doors_count_6_more: false,

        hangers: false,
        drawers: false,

        width_0_60: false,
        width_60_120: false,
        width_120_180: false,
        width_180_240: false,
        width_240_300: false,
        width_300_more: false,

        height_0_60: false,
        height_60_120: false,
        height_120_180: false,
        height_180_220: false,
        height_220_240: false,
        height_240_more: false,

        price: {
            min: 0,
            max: 100,
            options: {
                floor: 0,
                ceil: 100,
                step: 1,
                noSwitching: true
                // precision: 2
            }
        },
        width: {
            min: 0,
            max: 100,
            options: {
                floor: 0,
                ceil: 100,
                step: 1,
                noSwitching: true
            }
        },
        height: {
            min: 0,
            max: 200,
            options: {
                floor: 0,
                ceil: 100,
                step: 1,
                noSwitching: true
            }
        },
        depth: {
            min: 0,
            max: 200,
            options: {
                floor: 0,
                ceil: 100,
                step: 1,
                noSwitching: true
            }
        },
        doors_count: {
            min: 0,
            max: 20,
            options: {
                floor: 0,
                ceil: 100,
                step: 1,
                noSwitching: true
            }
        }
    };

}