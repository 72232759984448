import './Contacts.scss';

import { Component } from '../../components/Component';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Contacts.html'),
    bindings: {
    }
})
export class Contacts {
    static $inject = [
    ];


    constructor(
    ) {
        /* */
    }

    $onInit() {
        //
    }

}
