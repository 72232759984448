import './FiReloadDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiReloadDialog.html'),
    bindings: {

    },
})
export class FiReloadDialog {
    static $inject = [
        '$mdDialog',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
    ) {
        //
    }

    $onInit() {
        //
    }

    close() {
        this.$mdDialog.cancel();
    }

    reload() {
        location.reload();
    }

}