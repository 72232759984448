import './Rules.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Rules.html'),
    bindings: {
    }
})
export class Rules {
    static $inject = [
        'fiSubHeaderService',
    ];


    constructor(
        private fshs: FiSubHeaderService,
    ) {
        /* */
    }

    $onInit() {
        this.fshs.subHeaderIsHidden = false;
    }

}
