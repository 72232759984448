export class FiToastService {
    static $inject = [
        '$timeout',
    ];

    constructor(
        private $timeout: angular.ITimeoutService,
    ) {
        //
    }

    visible: boolean;
    message: string;
    class: string;
  
    show(message) {
        
        this.message = message;
        
        this.visible = true;
        
        this.$timeout(() => {
            this.class = 'show-toast';
        }, 300);

        this.$timeout(() => {
            this.class = 'hide-toast';

            this.$timeout(() => {
                this.visible = false;
            }, 300);

        }, 6000);
    }

    hide() {
        this.visible = false;
        this.class = 'hide-toast';
    }
   
}