import './FiSelectSlidingDoorHandleDialog.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiSelectSlidingDoorHandleDialog.html'),
    bindings: {
        //
    },
})
export class FiSelectSlidingDoorHandleDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    handles_items = [];
    handles_results = [];

    handles_internal_items = [];
    handles_internal_results = [];


    milled_knobs_items = [];
    milled_knobs_results = [];

    knob_profiles_items = [];
    knob_profiles_results = [];

    selectHandleType(selection) {
        this.fs.furniture.activeDoorRow.handle_type = selection;
        this.fs.furniture.activeDoorRow.handle_id = 0;
    }

    $onInit() {
        // handles
        this.$http.get('https://www.fitit.lt/db/handles.php').then((data) => {
            this.handles_items.push(data.data);
            this.handles_results = this.handles_items[0];
        });

        // handles internal
        this.$http.get('https://www.fitit.lt/db/handles_internal.php').then((data) => {
            this.handles_internal_items.push(data.data);
            this.handles_internal_results = this.handles_internal_items[0];
        });

        // milled_knobs
        this.$http.get('https://www.fitit.lt/db/milled_knobs.php').then((data) => {
            this.milled_knobs_items.push(data.data);
            this.milled_knobs_results = this.milled_knobs_items[0];
        });

        // knob_profiles
        this.$http.get('https://www.fitit.lt/db/knob_profiles.php').then((data) => {
            this.knob_profiles_items.push(data.data);
            this.knob_profiles_results = this.knob_profiles_items[0];
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

}