import './FiModalTopFive.scss';

import * as angular from 'angular';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiModalTopFive.html'),
    bindings: {

    },
})
export class FiModalTopFive {
    static $inject = [
        '$mdDialog',
        '$http',
        'fiFurnitureService',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $http: angular.IHttpService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    lmdp_items = [];
    lmdp_results = [];

    rightIsActive: boolean;
    firstImg: string;
    secondImg: string;
    firstImgCode: string;
    secondImgCode: string;

    filter = {
        lmdp: {
            color_tone: 0,
            color: 0,
            gloss: 0,
            roughness: 0,
            pattern: 0,
            manufacturer: 0,
            supplier: 0
        }
    };

    $onInit() {
        // LMDP
        this.$http.get('https://www.fitit.lt/db/lmdp.php').then((data) => {
            this.lmdp_items.push(data.data);
            this.lmdp_results = this.lmdp_items[0];
        });

        this.firstImg = 'B-0088_PR.jpg';
        this.firstImgCode = 'B-0088/PR';

        this.secondImg = 'B-0112_PE.jpg';
        this.secondImgCode = 'B-0112/PE';
    }

    selectItem(img: string, code: string) {
        if (!this.rightIsActive) {
            this.firstImg = img;
            this.firstImgCode = code;
        }
        else {
            this.secondImg = img;
            this.secondImgCode = code;
        }
    }

    selectSide(side: string) {
        if (side == 'left') {
            this.rightIsActive = false;
        }
        else {
            this.rightIsActive = true;
        }
    }

    applyFilter(filterCategory: number) {

        if (filterCategory == 2) {

            this.lmdp_results = this.lmdp_items[0];

            if (this.filter.lmdp.color_tone) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color_tone == this.filter.lmdp.color_tone );
            }
            
            if (this.filter.lmdp.color) {
                this.lmdp_results = this.lmdp_results.filter(item => item.color == this.filter.lmdp.color );
            }

            if (this.filter.lmdp.gloss) {
                this.lmdp_results = this.lmdp_results.filter(item => item.gloss == this.filter.lmdp.gloss );
            }
            
            if (this.filter.lmdp.roughness) {
                this.lmdp_results = this.lmdp_results.filter(item => item.roughness == this.filter.lmdp.roughness );
            }

            if (this.filter.lmdp.pattern) {
                this.lmdp_results = this.lmdp_results.filter(item => item.pattern == this.filter.lmdp.pattern );
            }
            
            if (this.filter.lmdp.supplier) {
                this.lmdp_results = this.lmdp_results.filter(item => item.supplier == this.filter.lmdp.supplier );
            }
        }
    }
    close() {
        this.$mdDialog.cancel();
    }

}