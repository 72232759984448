import './FiHeader.scss';

import * as angular from 'angular';
import { Component } from '../Component';
import { FiCart } from '../../services/Cart';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';
// import * as uirouter from '@uirouter/angularjs';

// For Demo (delete)
import { FiSubHeaderService } from './../FiSubHeader/FiSubHeaderService';


@Component({
    controllerAs: 'vm',
    template: require('./FiHeader.html'),
    bindings: {
    },
})
export class FiHeader {
    static $inject = [
        '$scope',
        'fiCart',
        '$mdSidenav',
        '$window',
        'fiFurnitureService',
        '$http',
        'fiSubHeaderService',
        '$mdDialog',
    ];

    private user: any;
    cartStorage: FiCart;
    dialogTemplate: string;

    constructor(
        private $scope: angular.IScope,
        private cart: FiCart,
        private $mdSidenav: angular.material.ISidenavService,
        private $window: angular.IWindowService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
        private fshs: FiSubHeaderService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        this.$scope.$watch(() => this.cart.count, () => this.cartStorage = JSON.parse(localStorage.getItem('cart')));
    }

    $onInit() {
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.$http.post("https://www.fitit.lt/db/user-exit.php", { 

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                //
        });
        
        location.reload();
    }

    openMobileMenu() {
        this.$mdSidenav('mobile-menu').open();
    }

    closeMobileMenu() {
        this.$mdSidenav('mobile-menu').close();
    }

    goToConfigurator() {
        this.$window.location.href = '/konfiguratorius/';
    }


    showReloadDialog(type: number) {

        if (type == 1) {
            this.dialogTemplate = '<fi-modal-top-one></fi-modal-top-one>';
        }
        else if (type == 2) {
            this.dialogTemplate = '<fi-modal-top-two></fi-modal-top-two>';
        }
        else if (type == 3) {
            this.dialogTemplate = '<fi-modal-top-three></fi-modal-top-three>';
        }
        else if (type == 4) {
            this.dialogTemplate = '<fi-modal-top-four></fi-modal-top-four>';
        }
        else if (type == 5) {
            this.dialogTemplate = '<fi-modal-top-five></fi-modal-top-five>';
        }
        else if (type == 6) {
            this.dialogTemplate = '<fi-modal-top-six></fi-modal-top-six>';
        }
        else if (type == 7) {
            this.dialogTemplate = '<fi-modal-top-seven></fi-modal-top-seven>';
        }


        this.$mdDialog.show({
            template: this.dialogTemplate,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

}
