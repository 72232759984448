import * as angular from 'angular';

export default [
    '$rootScope',
    (
        $rootScope: angular.IRootScopeService,
    ) => {
        angular.element(document).bind('keyup', (e) => {
            $rootScope.$broadcast('GlobalKeyDown', e)
        });
    }
];
