import './FiFeHandleInternalDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiFeHandleInternalDialog.html'),
    bindings: {
        hideButton: '<'
    },
})
export class FiFeHandleInternalDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    hideButton: boolean;

    $onInit() {
        //
    }

    next() {
        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.handleInternalItem);
        const nextItem = index + 1;
        if (nextItem == this.fs.items.length) {
            return false;
        }
        else {
            this.fs.handleInternalItem = this.fs.items[nextItem];
        }
    }

    previous() {

        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.handleInternalItem);
        const nextItem = index - 1;
        if (nextItem < 0) {
            return false;
        }
        else {
            this.fs.handleInternalItem = this.fs.items[nextItem];
        }
    }

    select() {

        if (this.fs.sameInternalHandles) {
            for(const column of this.fs.furniture.columns) {
                for(const row of column.rows) {
                    row.handle_internal = this.fs.handleInternalItem;
                }
            }
        }
        else {
            this.fs.furniture.activeRow.handle_internal = this.fs.handleInternalItem;
        }

        this.$mdDialog.cancel();
    }

    close() {
        this.$mdDialog.cancel();
    }

    viewImage(img) {
        this.$window.open('/img/handles_internal_large/' + img, '_blank');
    }
}