import './FiMeter.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiMeter.html'),
    bindings: {
        size: '<',
        type: '<',
        noCm: '<'
    },
})
export class FiMeter {
    static $inject = [
    ];


    constructor(
    ) {
    }

    $onInit() {
        //
    }

}
