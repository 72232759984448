import './UserFavorites.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from './../../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./UserFavorites.html'),
    bindings: {
    }
})
export class UserFavorites {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    user: any;
    favorites = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.post("https://www.fitit.lt/db/get-user-favorites.php", { 
                userEmail: this.user.email,
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    
                    this.favorites.push(response.data);
                    
            });

        }

        console.log(this.favorites);
    }

    viewDetails(itemId) {
        this.$window.location.href = '/baldai/' + itemId;
    }

    getType(type: number) {
        if (type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }
}
