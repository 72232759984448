import './Duk.scss';

import { Component } from '../../components/Component';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Duk.html'),
    bindings: {
    }
})
export class Duk {
    static $inject = [
        '$mdDialog',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    cAssembly: boolean;
    cDesign: boolean;
    cName: string;
    cEmail: string;
    cPhone: string;
    cCity: string;
    cRules: boolean;

    thanks: boolean = false;

    $onInit() {
        
    }

    showAlignmentPopup() {

        this.$mdDialog.show({
            template: '<fi-modal-top-five></fi-reload-five>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }
}
