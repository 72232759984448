import './FiChooseDivide.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, ISlidingDoorItem, IDivideRows } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiChooseDivide.html'),
    bindings: {
        hideButton: '<' 
    },
})
export class FiChooseDivide {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    divideRows: IDivideRows[] = [];
    divideRowsCount: number = 1;
    divideRow_no: number;
    divideRow_id: number;
    divideRow_height: number;
    divideRow_width: number;

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
    }

    unActivateDoor() {
        this.fs.furniture.slidingDoors.forEach((c: ISlidingDoorItem) => {
            c.active = false;
        });
        this.fs.furniture.activeDoor = null;
    }


    setDivide(type: number) {
        this.fs.furniture.activeDoor.divide = type;

        if (type == 0) {
            this.divideRow_height = this.fs.furniture.activeDoor.height - this.fs.edgeWidth;
            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(this.divideRow_height, 1, false);
        }
        else if (type == 1) {
            this.divideRow_height = (this.fs.furniture.activeDoor.height - (this.fs.edgeWidth * 2)) / 2;
            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(this.divideRow_height, 2, false);
        }
        else if (type == 2) {
            this.divideRow_height = (this.fs.furniture.activeDoor.height - (this.fs.edgeWidth * 3)) / 3;
            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(this.divideRow_height, 3, false);
        }
        else if (type == 3) {
            this.divideRow_height = (this.fs.furniture.activeDoor.height - (this.fs.edgeWidth * 4)) / 4;
            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(this.divideRow_height, 4, false);
        }
        else if (type == 4) {
            this.divideRow_height = (this.fs.furniture.activeDoor.height - (this.fs.edgeWidth * 3)) / 3;

            let firstRowHeight = this.fs.furniture.activeDoor.height * 0.4;
            let secondRowHeight = this.fs.furniture.activeDoor.height * 0.2;
            let thirdRowHeight = this.fs.furniture.activeDoor.height * 0.4;

            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(firstRowHeight, 1, true);
            this.createDivideRow(secondRowHeight, 1, true);
            this.createDivideRow(thirdRowHeight, 1, true);
        }
        else if (type == 5) {
            this.divideRow_height = (this.fs.furniture.activeDoor.height - (this.fs.edgeWidth * 3)) / 3;

            let firstRowHeight = this.fs.furniture.activeDoor.height * 0.2;
            let secondRowHeight = this.fs.furniture.activeDoor.height * 0.6;
            let thirdRowHeight = this.fs.furniture.activeDoor.height * 0.2;

            this.divideRows = [];
            this.divideRow_no = 0;
            this.createDivideRow(firstRowHeight, 1, true);
            this.createDivideRow(secondRowHeight, 1, true);
            this.createDivideRow(thirdRowHeight, 1, true);
        }

        this.unActivateDoor();
        this.$mdDialog.cancel();
    }

    createDivideRow(height: number, count: number, group: boolean) {

        for(var i = 0; i < count; i++) {

            this.divideRow_id = Math.floor(Math.random() * 89999999999 + 10000000000);

            this.divideRows.push(
                {
                    id: 0,
                    row_id: this.divideRow_id,
                    order_no: this.divideRow_no = this.divideRow_no + 1,
                    panel_id: 0,
                    width: this.fs.furniture.activeDoor.width,
                    height: height,
                    mirror_id: 0,
                    knob_id: 0,
                    mirror: null,
                    panel: this.fs.furniture.material,
                    active: false,
                    panel_type: 0,
                    knob: null,
                    filling_id: 0,
                    fillings: false,
                    mdf: null,
                    filling: null,
                    mdf_gloss: 0,
                    mdf_id: 0,
                    handle_type: 0,
                    mdf_thickness: 0,
                    handle_id: 0,
                    handle_internal_id: 0,
                    milled_knob_id: 0,
                    handle_internal: null,
                    milled_knob: null,
                    handle: null
                }
            )
        }
        this.fs.furniture.activeDoor.divideRows = this.divideRows;
    }

    close() {
        this.unActivateDoor();
        this.$mdDialog.cancel();
    }
}