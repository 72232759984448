import './FiMaterialDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, iMDFItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiMaterialDialog.html'),
    bindings: {
        type: '<',
        hideButton: '<',
        gloss: '<',
        thickness: '<',
        price: '<',
        mdfprice: '<'
    },
})
export class FiMaterialDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    private type: number;
    mainImage: number = 1;
    hideButton: boolean;

    gloss: number;
    thickness: number;
    price: string;

    $onInit() {
        //
    }

    next() {
        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.panelItem);
        const nextItem = index + 1;
        if (nextItem == this.fs.items.length) {
            return false;
        }
        else {
            this.fs.panelItem = this.fs.items[nextItem];
        }
    }

    previous() {

        const index = this.fs.items.sort((a, b) => a.code.localeCompare(b.code)).indexOf(this.fs.panelItem);
        const nextItem = index - 1;
        if (nextItem < 0) {
            return false;
        }
        else {
            this.fs.panelItem = this.fs.items[nextItem];
        }
    }

    select() {
        if (this.type == 1) {
            this.fs.furniture.material_id = this.fs.panelItem.id;
            this.fs.furniture.material = this.fs.panelItem;
        }
        else if (this.type == 2) {

            if (this.fs.sameDoorsPanel) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.panel = this.fs.panelItem;
                        row.panel_id = this.fs.panelItem.id;
                    }
                }
            }
            else {
                this.fs.furniture.activeRow.panel = this.fs.panelItem;
                this.fs.furniture.activeRow.panel_id = this.fs.panelItem.id;
            }

        }
        else if (this.type == 3) {
            if (this.fs.sameSlidingDoorsPanel) {
                for(const column of this.fs.furniture.slidingDoors) {
                    for(const row of column.divideRows) {
                        row.panel = this.fs.panelItem;
                        row.panel_id = this.fs.panelItem.id;
                    }
                }
            }
            else {
                this.fs.furniture.activeDoorRow.panel_id = this.fs.panelItem.id;
                this.fs.furniture.activeDoorRow.panel == this.fs.panelItem;
            }
        }
        else if (this.type == 4) {
            // MDF
        }
        
        this.$mdDialog.cancel();
    }

    getMDFPanelPrice(mdf: iMDFItem) {

        if (this.fs.selected_mdf_gloss == 0) {
            return mdf.price_matte_2_side;
        }
        else if (this.fs.selected_mdf_gloss == 1) {
            return mdf.price_matte_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 2) {
            return mdf.price_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 3) {
            return mdf.price_super_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 4) {
            return mdf.price_super_shiny_2_side;
        }
    }

    getMDFColor(color: number) {
        if (color == 1) {
            return 'Balta';
        }
        else if (color == 2) {
            return 'Pilkšva';
        }
        else if (color == 3) {
            return 'Rusva';
        }
        else if (color == 4) {
            return 'Rausva';
        }
        else if (color == 5) {
            return 'Pilka';
        }
        else if (color == 6) {
            return 'Gelsva';
        }
        else if (color == 7) {
            return 'Rausva';
        }
        else if (color == 8) {
            return 'Žalsva';
        }
        else if (color == 9) {
            return 'Ruda';
        }
        else if (color == 10) {
            return 'Juoda';
        }
        else if (color == 11) {
            return 'Pasirinktinai';
        }
    }

    getMDFColorTone(colorTone: number) {
        if (colorTone == 1) {
            return 'Šviesus';
        }
        else if (colorTone == 2) {
            return 'Vidutinis';
        }
        else if (colorTone == 3) {
            return 'Tamsus';
        }
        else if (colorTone == 4) {
            return 'Pasirinktinai';
        }
    }

    close() {
        this.$mdDialog.cancel();
    }

    switchImage(imageNumber) {

        if (imageNumber < 1 || imageNumber > 5) {
            return
        }

        this.mainImage = imageNumber;
    }

    viewImage(img) {
        this.$window.open('/img/lmdp_large/' + img, '_blank');
    }

    viewMDFImage(img) {
        this.$window.open('/img/mdf/' + img, '_blank');
    }
}