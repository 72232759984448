import './FiChip.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiChip.html'),
    bindings: {
        content: '@'
    },
})

export class FiChip {
    static $inject = [
    ];

    constructor(
    ) {
    }

    $onInit() {
        //
    }

}
