import './Order.scss';

import { Component } from '../../components/Component';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import { FiSubHeaderService } from '../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';
import { FiFurnitureService, IFurnitureItem, IOrder } from '../../components/fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./Order.html'),
    bindings: {
    }
})
export class Order {
    static $inject = [
        'fiSubHeaderService',
        'fiFurnitureService',
        '$window',
        '$http',
        '$state',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private $state: uirouter.StateService,
    ) {
        /* */
    }

    user: any;
    order: IOrder;
    view: number;
    order_id: string;
    orderItems = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        this.order_id = this.$state.params['orderId'];

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        } else {

            this.$http.post("https://www.fitit.lt/db/order.php", { 
                order_id: this.order_id,
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    
                    // onsole.log(response.data[0])

                    this.fs.order = response.data[0];
                    this.orderItems = [];

                    if (!this.fs.order) {
                        this.$window.location.href = '/uzsakymai';
                    }


                    this.$http.post("https://www.fitit.lt/db/order-items.php", { 
                        order_id: this.fs.order.order_id,
                    }, { headers: {'Content-Type': 'application/json'} })
                        .then((response: any) => {
                            
                            this.orderItems.push(response.data);
                            this.fs.order.items = this.orderItems;

                            this.$http.post("https://www.fitit.lt/db/order-user.php", { 
                                user_id: this.fs.order.user_id,
                            }, { headers: {'Content-Type': 'application/json'} })
                                .then((response: any) => {
                                    this.fs.order.user = response.data[0];
                            });


                            for(const item of this.orderItems[0]) {
                                this.$http.post("https://www.fitit.lt/db/order-item.php", { 
                                    item_id: item.item_id,
                                }, { headers: {'Content-Type': 'application/json'} })
                                    .then((response: any) => {

                                        item.item = response.data;
                                        
                                });
                            }
                            
                    });
            });
        }

        this.view = 1;
    }

    getExtraTotal(extra1: number, extra2: number, extra3: number, extra4: number, extra5: number) {
        return Number(extra1) + Number(extra2) + Number(extra3) + Number(extra4) + Number(extra5);
    }

    test() {
        this.$http.post("https://www.fitit.lt/db/order-items.php", { 
            order_id: this.fs.order.order_id,
        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                
                this.orderItems.push(response.data);
                this.fs.order.items = this.orderItems;

                this.$http.post("https://www.fitit.lt/db/order-user.php", { 
                    user_id: this.fs.order.user_id,
                }, { headers: {'Content-Type': 'application/json'} })
                    .then((response: any) => {
                        this.fs.order.user = response.data[0];
                });


                for(const item of this.orderItems[0]) {
                    this.$http.post("https://www.fitit.lt/db/order-item.php", { 
                        item_id: item.item_id,
                    }, { headers: {'Content-Type': 'application/json'} })
                        .then((response: any) => {

                            item.item = response.data;
                            
                    });
                }
                
        });
    }

    viewOrderItem(item_id: string) {
        // this.fs.order = item;
        this.fs.selectFurniture(item_id, false);
        this.view = 2;
    }

    getOrderStatus(status: number) {
        if (status == 0) {
            return 'Laukiama apmokėjimo'; 
        }
        else if (status == 1) {
            return 'Apmokėtas';
        }
    }
}
