import './ItemList.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';
import { IFurnitureItem, FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';
import { FiFilterService } from '../../components/fiFilter/FiFilterService';
import { FiCart } from '../../services/Cart';
import * as uirouter from 'angular-ui-router';

@Component({
    controllerAs: 'vm',
    template: require('./ItemList.html'),
    bindings: {
    }
})
export class ItemList {
    static $inject = [
        '$http',
        'fiSubHeaderService',
        '$window',
        'fiFurnitureService',
        'fiCart',
        'fiFilterService',
        '$mdSidenav',
        '$state',
        '$location',
        '$scope',
    ];


    constructor(
        private $http: angular.IHttpService,
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private fs: FiFurnitureService,
        private cart: FiCart,
        private ss: FiFilterService,
        private $mdSidenav: angular.material.ISidenavService,
        private $state: uirouter.StateService,
        private $location: angular.ILocationService,
        private $scope: angular.IScope,
    ) {
        
    }

    data = [];
    showInside: boolean = true;
    cartStorage: FiCart;
    itemCartCount: number = 1;
    mobile: boolean;
    
    private user: any;

    items: IFurnitureItem[];

    

    $onInit() {
        this.mobile = this.$window.innerWidth <= 768;
        this.fshs.subHeaderIsHidden = false;
        this.user = JSON.parse(localStorage.getItem('currentUser'));
    }

    sortBy() {
        return "price";
    }
    
    sortPrice(price: string) {
        return parseInt(price);
    }

    openMobileFilter() {
        this.$mdSidenav('mobile-filter').open();
    }

    closeMobileFilter() {
        this.$mdSidenav('mobile-filter').close();
    }

    clearFilters() {
        location.reload();
    }

    selectOrderBy(order: number) {

        // this.ss.orderBy = order;

        if (this.ss.orderBy == 1) {
            this.ss.orderByValue = 'orderby';
            this.ss.orderByOrder = true;
        }
        else if (this.ss.orderBy == 2) {
            this.ss.orderByValue = 'price';
            this.ss.orderByOrder = false;
        }
        else if (this.ss.orderBy == 3) {
            this.ss.orderByValue = 'price';
            this.ss.orderByOrder = true;
        }
    }
}
