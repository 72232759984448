import './AdminMaterial.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from '../../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService, iPanelItem } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./AdminMaterial.html'),
    bindings: {
    }
})
export class AdminMaterial {
    static $inject = [
        'fiSubHeaderService',
        'fiFurnitureService',
        '$window',
        '$http',
        '$mdDialog',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    private user: any;
    view: number;
    panel = [];
    handles = [];
    legs = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        }

        if (this.user && this.user.admin) {

            // panel
            this.$http.get('https://www.fitit.lt/db/lmdp.php').then((data) => {
                this.panel.push(data);
            });

            // handles
            this.$http.get('https://www.fitit.lt/db/handles.php').then((data) => {
                this.handles.push(data);
            });

            // legs
            this.$http.get('https://www.fitit.lt/db/legs.php').then((data) => {
                this.legs.push(data);
            });

        }

        this.view = 1;
    }

    deletePanel(itemId) {
        this.$http.post("https://www.fitit.lt/db/delete-panel.php", { 

            itemId: itemId

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        location.reload();
    }

    deleteHandle(itemId) {
        this.$http.post("https://www.fitit.lt/db/delete-handle.php", { 

            itemId: itemId

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        location.reload();
    }

    deleteLeg(itemId) {
        this.$http.post("https://www.fitit.lt/db/delete-leg.php", { 

            itemId: itemId

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        location.reload();
    }

    showImageDialog(image) {

        this.fs.zoomImage = image;

        this.$mdDialog.show({
            template: '<fi-image-dialog></fi-image-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    showPanelDialog(item) {

        this.fs.panelItem = item;

        this.$mdDialog.show({
            template: '<fi-panel-dialog></fi-panel-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    showLegDialog(item) {

        this.fs.legItem = item;

        console.log(this.fs.legItem);

        this.$mdDialog.show({
            template: '<fi-leg-dialog></fi-leg-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }

    showHandleDialog(item) {

        this.fs.handleItem = item;

        console.log(this.fs.handleItem);

        this.$mdDialog.show({
            template: '<fi-handle-dialog></fi-handle-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
        
    }
}
