import './SketchInside.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./SketchInside.html'),
    bindings: {
    },
})
export class SketchInside {
    static $inject = [
        'fiFurnitureService',
    ];


    constructor(
        private fs: FiFurnitureService,
    ) {
    }

    $onInit() {
        //
    }

}
