import './FiOfferItemsDialog.scss';
import { FiFurnitureService, IOfferItem } from '../fiFurniture/FiFurnitureService';
import { FiPrice } from '../../services/Price';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiOfferItemsDialog.html'),
    bindings: {

    },
})
export class FiOfferItemsDialog {
    static $inject = [
        '$http',
        '$mdDialog',
        'fiFurnitureService',
        'fiPrice',
        '$timeout',
    ];

    constructor(
        private $http: angular.IHttpService,
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private ps: FiPrice,
        private $timeout: angular.ITimeoutService,
    ) {
      //
    }

    items = [];
    loading: boolean;
    selectedItem: IOfferItem;

    $onInit() {
        this.$http.get('https://www.fitit.lt/db/offer/items.php').then((items) => {
            this.items.push(items);
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

    add() {
        this.loading = true;
        this.selectedItem.item_count = 1;
        this.selectedItem.assembly_count = 1;
        this.selectedItem.carry_count = 1;

        this.fs.selectFurniture(this.selectedItem.item_id, false);

        this.$timeout(() => {

            this.selectedItem.assembly = this.ps.getAssemblyPrice();
            this.selectedItem.carry_price = this.ps.getCarryPrice();
            
            // this.fs.offerItems.push(this.selectedItem);
            // this.fs.offer.items = this.fs.offerItems;

            this.fs.offer.items.push(this.selectedItem);

            this.$mdDialog.cancel();
            this.loading = false;

        }, 2000);
        

    }

}