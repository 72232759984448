import './FiFooter.scss';

import * as angular from 'angular';

import { Component } from '../Component';

// For Demo (delete)
import { FiSubHeaderService } from './../FiSubHeader/FiSubHeaderService';

@Component({
    controllerAs: 'vm',
    template: require('./FiFooter.html'),
    bindings: {
    },
})
export class FiFooter {
    static $inject = [
        '$rootScope',
        'fiSubHeaderService',
        '$mdDialog',
        '$window',
    ];

    private translateUnhook: any;
    dialogTemplate: string;

    constructor(
        private $rootScope: angular.IRootScopeService,
        private fshs: FiSubHeaderService,
        private $mdDialog: angular.material.IDialogService,
        private $window: angular.IWindowService,
    ) {
    }

    $onInit() {
        //
    }

    goToGoogleReviews() {
        this.$window.open('https://www.google.com/maps/place/Fitit/@54.7392005,25.2185107,17z/data=!4m8!3m7!1s0x46dd9130a8c7ec7d:0x8e2eeb4f8a65553e!8m2!3d54.7392005!4d25.2185107!9m1!1b1!16s%2Fg%2F11lm1b9q4c?entry=ttu', '_blank');
    }

    showReloadDialog(type: number) {

        if (type == 1) {
            this.dialogTemplate = '<fi-modal-top-one></fi-reload-one>';
        }
        else if (type == 2) {
            this.dialogTemplate = '<fi-modal-top-two></fi-reload-two>';
        }
        else if (type == 3) {
            this.dialogTemplate = '<fi-modal-top-three></fi-reload-three>';
        }
        else if (type == 4) {
            this.dialogTemplate = '<fi-modal-top-four></fi-reload-four>';
        }
        else if (type == 5) {
            this.dialogTemplate = '<fi-modal-top-five></fi-reload-five>';
        }


        this.$mdDialog.show({
            template: this.dialogTemplate,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

}
