import './Configuratornew.scss';

import { Component } from '../../components/Component';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Configuratornew.html'),
    bindings: {
    }
})
export class Configuratornew {
    static $inject = [

    ];


    constructor(

    ) {
        /* */
    }

    // Set up our canvas
    canvas = <HTMLCanvasElement> document.getElementById('canvas-element-2');
    ctx = this.canvas.getContext('2d');

    $onInit() {

        // const canvas_element = document.getElementById("canvas-element"); 
        // const context = canvas_element.getContext("2d"); 
        
        // For Yellow Rectangle 
        
        // context.fillStyle = "yellow"; 
        // context.fillRect(0, 10, 50, 100) 
        
        // For Blue Rectangle 
        
        // context.fillStyle = "blue"; 
        // context.fillRect(100, 100, 100, 100) 
        
        // For Red Rectangle 
        
        // context.fillStyle = "red"; 
        // context.fillRect(200, 200, 100, 100)


        this.drawCube(
            200,
            300,
            100,
            150,
            100,
            '#ddd'
        );
    }


    // Colour adjustment function
    // Nicked from http://stackoverflow.com/questions/5560248

    shadeColor(color, percent) {
        color = color.substr(1);
        var num = parseInt(color, 16),
            amt = Math.round(2.55 * percent),
            R = (num >> 16) + amt,
            G = (num >> 8 & 0x00FF) + amt,
            B = (num & 0x0000FF) + amt;
        return '#' + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1);
    }

    // Draw a cube to the specified specs
    drawCube(x, y, wx, wy, h, color) {
        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x - wx, y - wx * 0.5);
        this.ctx.lineTo(x - wx, y - h - wx * 0.5);
        this.ctx.lineTo(x, y - h * 1);
        this.ctx.closePath();
        this.ctx.fillStyle = this.shadeColor(color, -10);
        this.ctx.strokeStyle = color;
        this.ctx.stroke();
        this.ctx.fill();

        this.ctx.beginPath();
        this.ctx.moveTo(x, y);
        this.ctx.lineTo(x + wy, y - wy * 0.5);
        this.ctx.lineTo(x + wy, y - h - wy * 0.5);
        this.ctx.lineTo(x, y - h * 1);
        this.ctx.closePath();
        this.ctx.fillStyle = this.shadeColor(color, 10);
        this.ctx.strokeStyle = this.shadeColor(color, 50);
        this.ctx.stroke();
        this.ctx.fill();

        this.ctx.beginPath();
        this.ctx.moveTo(x, y - h);
        this.ctx.lineTo(x - wx, y - h - wx * 0.5);
        this.ctx.lineTo(x - wx + wy, y - h - (wx * 0.5 + wy * 0.5));
        this.ctx.lineTo(x + wy, y - h - wy * 0.5);
        this.ctx.closePath();
        this.ctx.fillStyle = this.shadeColor(color, 20);
        this.ctx.strokeStyle = this.shadeColor(color, 60);
        this.ctx.stroke();
        this.ctx.fill();
2    }
}
