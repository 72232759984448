import './FiAddPhotoDialog.scss';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiAddPhotoDialog.html'),
    bindings: {
        infoTitle: '@',
        infoContent: '@',
        category: '@',
    },
})
export class FiAddPhotoDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    $onInit() {
        // console.log(this.fs.furniture)
    }

    close() {
        this.$mdDialog.cancel();
    }

    viewImage(img: string) {
        this.$window.open('https://www.fitit.lt/gallery/' + img, '_blank');
    }
}