import './SuccessGiftCard.scss';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { Component } from '../../components/Component';
import { FiCart } from '../../services/Cart';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./SuccessGiftCard.html'),
    bindings: {
    }
})
export class SuccessGiftCard {
    static $inject = [
        'fiSubHeaderService',
        'fiCart',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private cart: FiCart,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    order = [];

    $onInit() {

        this.fshs.subHeaderIsHidden = true;
        this.order = JSON.parse(localStorage.getItem('orderInfo'));

        this.$http.post("https://www.fitit.lt/db/giftcard.php", { 

            order_id: this.order['order_id']

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                this.$http.post("https://www.fitit.lt/db/success-new-giftcard.php", { 
            
                    name:this.order['name'],
                    email: this.order['email'],
                    order_id: this.order['order_id'],
                    gift_card_amount: this.order['gift_card_amount'],
                    gift_card_user: this.order['gift_card_user'],
                    gift_card_greeting: this.order['gift_card_greeting']

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        this.order = [];
                        localStorage.removeItem('orderInfo');
                });

        });
        
    }
}
