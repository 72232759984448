import './FiDuk.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiDukService } from './FiDukService';

@Component({
    controllerAs: 'vm',
    template: require('./FiDuk.html'),
    bindings: {
        id: '@',
        dukTitle: '@',
    },
    transclude: true
})

export class FiDuk {
    static $inject = [
        'fiDukService',
    ];

    constructor(
        private fds: FiDukService,
    ) {
    }

    $onInit() {
        //
    }


}
