import './OrderCarpenter.scss';

import { Component } from '../../components/Component';
import { FiCart } from '../../services/Cart';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../components/fiToast/FiToastService';
import * as angular from 'angular';
import { IUser } from '../../components/fiFurniture/FiFurnitureService';
import { FiPrice } from '../../services/Price';

@Component({
    controllerAs: 'vm',
    template: require('./OrderCarpenter.html'),
    bindings: {
    }
})
export class OrderCarpenter {
    static $inject = [
        '$window',
        'fiSubHeaderService',
        'fiCart',
        '$http',
        'fiToastService',
        'fiPrice',     
        '$scope',
        '$mdDialog',
        '$timeout',
    ];

    acceptRules: boolean;
    cartStorage: FiCart;
    cartTotalPrice: number = 0;
    totalPrice: string = '49.99';
    order_id: string;
    item_id: string;
    user: IUser;
    // logedin: boolean;
    userComments: string;
    userEmailExist: boolean = false;
    assemblyPriceTotal: number = 0;
    submitForm: boolean = false;
    payseraAmount: string = '4999';
    discount: boolean;
    discountCode: string;
    useDiscount: boolean;
    testPayment: boolean;
    freeDelivery: boolean;
    forFree: boolean;
    cartTotalAllItems: number = 0;
    loading: boolean;

    isKitchen: boolean = false;

    constructor(
        private $window: angular.IWindowService,
        private fshs: FiSubHeaderService,
        private cart: FiCart,
        private $http: angular.IHttpService,
        private ts: FiToastService,
        private ps: FiPrice,
        private $scope: angular.IScope,
        private $mdDialog: angular.material.IDialogService,
        private $timeout: angular.ITimeoutService,
    ) {
        /* */
    }

    hbspt: any;

    hubspot_name: string;
    hubspot_surname: string;
    hubspot_email: string;
    hubspot_phone: string;
    hubspot_timestamp: number;

    $onInit() {
        this.fshs.subHeaderIsHidden = true;

        this.$scope.$watch(() => this.discountCode, () => this.useDiscountCode());

        // this.user = JSON.parse(localStorage.getItem('currentUser'));
        // this.logedin = JSON.parse(localStorage.getItem('currentUser'));

        // if (!this.user || this.user.admin) {
            //
        // }

        
        // const script = document.createElement('script');
        // script.src='https://js.hsforms.net/forms/v2.js';
        // document.body.appendChild(script);

        // script.addEventListener('load', () => {
            // @TS-ignore
            // if (window.hbspt) {
                // @TS-ignore
                // window.hbspt.forms.create({
                    // region: "eu1",
                    // portalId: "25831285",
                    // formId: "59f26b76-649d-4a48-8228-6ad096bfca13",
                    // target: '#hubspotForm'
                // })
            // }
        // });

        const current = new Date();
        const timestamp = current.getTime();
        this.hubspot_timestamp = timestamp;
    }

    sendToHubspot() {
        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/25831285/59f26b76-649d-4a48-8228-6ad096bfca13'
        var data = {
        "submittedAt": this.hubspot_timestamp,
        "fields": [
            {
                "objectTypeId": "0-1",
                "name": "email",
                "value": this.hubspot_email
            },
            {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": this.hubspot_name
            },
            {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": this.hubspot_surname
            },
            {
                "objectTypeId": "0-1",
                "name": "phone",
                "value": this.hubspot_phone
            }
        ],
        "context": {
            // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            "pageUri": "www.fitit.lt/meistro-uzsakymas",
            "pageName": "Meistro užsakymas"
        },
        "legalConsentOptions":{ // Include this object when GDPR options are enabled
            "consent":{
            "consentToProcess":true,
            "text":"I agree to allow Example Company to store and process my personal data.",
            "communications":[
                {
                "value":true,
                "subscriptionTypeId":999,
                "text":"I agree to receive marketing communications from Example Company."
                }
            ]
            }
        }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 
        
        xhr.send(final_data)
    }

    recalculateCart() {
        if (this.isKitchen) {
            this.payseraAmount = '14999';
            this.totalPrice = '149.99';
        }
        else {
            this.payseraAmount = '4999';
            this.totalPrice = '49.99';
        }
    }

    useDiscountCode() {
        
        if (this.discount && this.discountCode == 'F2022-A74R!') {
            this.totalPrice = '0.01';
            this.payseraAmount = '1';
            this.useDiscount = true;
        }
        else if (this.discount && this.discountCode == 'fititfree') {
            this.totalPrice = '0';
            this.forFree = true;
            this.useDiscount = true;
        }
        else {
            if (this.isKitchen) {
                this.payseraAmount = '14999';
                this.totalPrice = '149.99';
            }
            else {
                this.payseraAmount = '4999';
                this.totalPrice = '49.99';
            }
            
            this.useDiscount = false;
            this.forFree = false;
        }
    }

    createOrder() {

        this.loading = true;
        
        this.order_id = 'FIT' + Math.floor(Math.random() * 899999 + 100000);

        const orderInfo = {
            name: this.user.name,
            email: this.user.email,
            order_id: this.order_id
        }

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
            'event': 'checkout-carpenter',
            'ecommerce': {
            'checkout': {
                'actionField': {'step': 2 },
                'user_data': {
                    'email_address': this.user.email,
                    'phone_number': this.user.phone,
                    'address': {
                        'first_name': this.user.name,
                        'last_name': this.user.surname,
                        'city': this.user.city,
                        'country': 'lt'     
                    }
                },
                'products': [{
                'name': this.user.name,
                'id': this.order_id,
                'price': this.totalPrice,
                'quantity': 1
            }]
            }
        }
        });

        localStorage.setItem('orderInfo', JSON.stringify(orderInfo));
            
        if (this.forFree) {

            this.$http.post("https://www.fitit.lt/db/new-carpenter-order.php", { 

                order_id: this.order_id,
                user_comments: this.userComments,
                name: this.user.name,
                surname: this.user.surname,
                email: this.user.email,
                phone: this.user.phone,
                city: this.user.city,
                address: this.user.address,
                zip: this.user.zip,
                company: this.user.company,
                company_name: this.user.company_name,
                company_code: this.user.company_code,
                company_vat: this.user.company_vat,
                company_address: this.user.company_address,
                is_kitchen: this.isKitchen

            }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
            });

            this.$timeout(() => {
                this.$window.location.href = '/meistro-uzsakymo-patvirtinimas';
                this.loading = false;
            }, 5000);
            
        }
        else {

            const checkoutForm = <HTMLFormElement>document.getElementById("checkoutForm");
            checkoutForm.submit();
            this.loading = false;
          
        }

    }

    isOkToGo() {
        if (!this.acceptRules) {
            return true;
        }
        else {
            return false;
        }
    }
}
