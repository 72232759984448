import './FiToast.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiToastService } from './FiToastService';


@Component({
    controllerAs: 'vm',
    template: require('./FiToast.html'),
    bindings: {
    },
})
export class FiToast {
    static $inject = [
        'fiToastService',
    ];

    constructor(
        private ts: FiToastService,
    ) {
        //
    }

    $onInit() {
        //
    }
}