import './UserPassword.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from './../../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../../components/fiToast/FiToastService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./UserPassword.html'),
    bindings: {
    }
})
export class UserPassword {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        'fiToastService',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private ts: FiToastService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    private user: any;
    oldPassword: string;
    newPassword: string;
    repeatNewPassword: string;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || this.user.admin) {
            this.$window.location.href = '/';
        }
    }

    change() {

        if (this.newPassword != this.repeatNewPassword) {
            this.ts.show('Nesutampa nauji slaptažodžiai');
            return;
        }
        else if (this.newPassword == this.oldPassword) {
            this.ts.show('Senasis ir naujasis slaptažodžiai yra vienodi');
            return;
        }
        else {
            this.$http.post("https://www.fitit.lt/db/change-password.php", { 

                userID: this.user.id,
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
    
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
    
                    console.log(response);
    
                    if(response.data == 'return') {
                        this.ts.show('Neteisingai įvestas senasis slaptažodis');
                    }
                    else {
                        this.ts.show('Slaptažodis sėkmingai pakeistas');
                    }
            });
        }

        
    }
}
