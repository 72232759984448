import './FiSelectFurnitureKnob.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiSelectFurnitureKnob.html'),
    bindings: {
        //
    },
})
export class FiSelectFurnitureKnob {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    knob_profiles_items = [];
    knob_profiles_results = [];

    $onInit() {
      
        // knob_profiles
        this.$http.get('https://www.fitit.lt/db/knob_profiles.php').then((data) => {
            this.knob_profiles_items.push(data.data);
            this.knob_profiles_results = this.knob_profiles_items[0];
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

}