import './Kitchen.scss';
import { History, HistoryItem } from '../../services/History';
import { FiWallService, Wall, Column, Row } from './KitchenService';

import { Component } from '../../components/Component';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Kitchen.html'),
    bindings: {
    }
})

export class Kitchen {
    static $inject = [
        'history',
        'fiWallService'
    ];

    constructor(
        private h: History,
        private ws: FiWallService,
    ) {
        //
    }

    wall: Wall;

    rowIsActive: boolean;
    rowEditCount: number;
    columns: Column[] = [];
    rows: Row[] = [];

    rowsCount: number = 1;
    columnsCount: number = 1;

    activeColumn: Column;
    activeRow: Row;

    activeFirstRow: Row;
    activeFirstColumn: Column;

    activeSecondRow: Row;
    activeSecondColumn: Column;
    
    clickDeepness: Number = 0;
    clickedSecondRow: boolean = false;
    

    deepness: Number = 0;
    historyStorage: History;

    historyStep: number = 0;

    orderNo: number = 0;
    selectedOuter: number = 0;

    // wallHistory: History;
    // wallItem: HistoryItem[];

    // historyItems: HistoryItem[] = [];

    $onInit() {

        // Set Wall
        const kWall = {
            id: this.uuidv4(),
            width: 500,
            height: 500,
            depth: 60,
            columnsCount: 1,
            columns: [{
                id: this.uuidv4(),
                active: false,
                rowsCount: 1,
                rows: [{
                    id: this.uuidv4(),
                    active: false,
                    columnsCount: 1,
                    columns: [],
                    deepness: 1
                }],
                
            }]
        } as Wall;

        this.ws.wall = kWall;

        // Clearin history
        this.historyStep = 0;
        localStorage.setItem('history', null);

    }

    historyBack() {
        this.historyStep = this.historyStep - 1;
        const setToStep = this.historyStorage.items.length - this.historyStep;
        this.ws.wall = this.historyStorage.items[this.historyStep].item;
    }

    historyForward() {
        this.historyStep = this.historyStep + 1;
        const setToStep = this.historyStorage.items.length - this.historyStep;
        this.ws.wall = this.historyStorage.items[this.historyStep].item;
    }

    addToHistory() {
        this.historyStorage = JSON.parse(localStorage.getItem('history'));
        if (this.historyStorage) {
             this.h.items = this.historyStorage.items;
        }
        this.h.items.push({
            item: this.ws.wall
        });
        localStorage.setItem('history', JSON.stringify(this.h));
        this.historyStep = this.historyStep + 1;
    }

    uuidv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
    }

    selectOuter() {
        this.activeColumn.outer = this.activeRow.outer;
    }

    changeColumnsCount(cCount: number) {
        
        // this.addToHistory();
        

        this.columns = [];
        this.rows = [];

        this.orderNo = 0;
        
        for(var i = 0; i < cCount; i++) {
            this.columns.push(
                {
                    id: this.uuidv4(),
                    active: false,
                    rowsCount: 1,
                    clickCount: this.activeColumn.clickCount + 1,
                    order_no: this.orderNo = this.orderNo + 1,
                    outer: 0,
                    rows: [{
                        id: this.uuidv4(),
                        active: false,
                        columns: [],
                        columnsCount: 0,
                        deepness: cCount === 1 ? this.activeRow.deepness : this.activeRow.deepness + 1,
                        inner: 0,
                        outer: 0,
                        clickCount: this.activeRow.clickCount + 1,
                        order_no: 1,
                    }]
                }
            )
        }

        this.activeRow.columnsCount = cCount;
        this.activeRow.columns = this.columns;

        this.addToHistory();
    }

    getActiveCount() {
        if (this.activeFirstColumn) {
            return 2;
        }
        else if (this.activeSecondColumn) {
            return 3;
        }
        else {
            return 1;
        }
    }

    changeRowsCount(rCount: number) {
        this.rows = [];
        this.rowsCount  = rCount;
        
        this.orderNo = 0;

        for(var i = 0; i < this.rowsCount; i++) {
            
            this.rows.push(
                {
                    id: this.uuidv4(),
                    active: false,
                    columns: [],
                    columnsCount: 0,
                    deepness: this.getActiveCount(),
                    inner: 0,
                    outer: 0,
                    clickCount: this.activeRow.clickCount + 1,
                    order_no: this.orderNo = this.orderNo + 1,
                }
            )
        }

        this.activeColumn.rowsCount = rCount;
        this.activeColumn.rows = this.rows;

        this.addToHistory();
    }

    rowClick(column: Column, row: Row, isActive: boolean) {

        this.unactive();

        if (row.deepness == 1) {

            if (isActive) {

                console.log('click 1 a');                

                row.active = false;
                column.active = false;

                this.activeRow = null;
                this.activeColumn = null;

                this.activeFirstRow = null;
                this.activeFirstColumn = null;
  
            }
            else {

                console.log('click 1 b');  

                row.active = true;
                column.active = true;

                this.activeRow = row;
                this.activeColumn = column;

                this.activeFirstRow = row;
                this.activeFirstColumn = column;
                this.activeFirstRow.clickCount = 1;
                this.activeFirstColumn.clickCount = 1;

            }
            
            
        }
        else if (row.deepness == 2) {

            if (isActive) {

                console.log('click 2 a');  

                row.active = false;
                column.active = false;
                this.activeRow = this.activeFirstRow;
                this.activeColumn = this.activeFirstColumn;

                this.activeFirstRow.clickCount = 2;
                this.activeFirstColumn.clickCount = 2;

                this.activeRow.active = true;
                this.activeColumn.active = true;
            }
            else {

                console.log('click 2 b');  

                row.active = true;
                column.active = true;
                this.activeRow = row;
                this.activeColumn = column;

                this.activeSecondRow = row;
                this.activeSecondColumn = column;
                this.activeSecondRow.clickCount = 1;
                this.activeSecondColumn.clickCount = 1;
            }
        }

        else if (row.deepness == 3 || row.deepness == 4) {

            if (isActive) {

                console.log('click 3 a');  

                row.active = false;
                column.active = false;
                this.activeRow = this.activeSecondRow;
                this.activeColumn = this.activeSecondColumn;

                this.activeSecondRow = row;
                this.activeSecondColumn = column;
                this.activeSecondRow.clickCount = 2;
                this.activeSecondColumn.clickCount = 2;

            }
            else {

                console.log('click 3 b');  

                row.active = true;
                column.active = true;
                this.activeRow = row;
                this.activeColumn = column;
            }
        }

    }

    rowClickOLD(column: Column, row: Row, isActive: boolean) {

        if (row.active && row.deepness == 1) {
            this.activeFirstColumn = column;
            this.activeFirstRow = row;
        }

        this.unactive();
        
        if (!isActive) {

            console.log('a: ' + row.deepness);

            this.activeColumn = column;
            this.activeRow = row;
            this.activeColumn.active = true;
            this.activeRow.active = true;
        }
        else {

            if (this.activeFirstRow) {

                console.log('b: ' + row.deepness);

                this.activeRow = this.activeFirstRow;
                this.activeColumn = this.activeFirstColumn;
                this.activeRow.active = true;
                this.activeColumn.active = true;
            }
            else {

                console.log('c4: ' + row.deepness);

                this.activeFirstRow = null;
                this.activeFirstColumn = null;
                this.activeRow.active = true;
                this.activeColumn.active = true;
            }
            
            
        }
    }


    rowClickActivate() {

    }

    unactive() {

        // Unactive all (not rowActive)

        for(const column of this.ws.wall.columns) {
            
            column.active = false;

            for(const row of column.rows) {

                row.active = false;

                for(const column of row.columns) {

                    column.active = false;

                    for(const row of column.rows) {
                        row.active = false;

                        for(const column of row.columns) {

                            column.active = false;

                            for(const row of column.rows) {
                                row.active = false;
                            }
                        }
                    }
                }
                
            }
        }
    }
}
