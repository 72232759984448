import './Gallery.scss';

import { Component } from '../../components/Component';
import * as angular from 'angular';

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";

@Component({
    controllerAs: 'vm',
    template: require('./Gallery.html'),
    bindings: {
    }
})
export class Gallery {
    static $inject = [
        '$http',
        '$window',
        '$timeout',
        '$scope',
    ];


    constructor(
        private $http: angular.IHttpService,
        private $window: angular.IWindowService,
        private $timeout: angular.ITimeoutService,
        private $scope: angular.IScope,
    ) {
        /* */
    }

    gallery = [];
    galleryFade: boolean;
    
    $onInit() {
        this.$http.get('https://www.fitit.lt/db/admin-gallery.php').then((data) => {
            this.gallery.push(data);
            
        });

        this.swapImage();

        this.$timeout(() => {
            this.constructGallery();
        }, 1000);

    }

    constructGallery() {
        const $galleryContainer = document.getElementById("gallery-container");

        const lg = lightGallery($galleryContainer, {
            speed: 500,
            plugins: [lgThumbnail],
            enableDrag: false,
            selector: '.item-details-gallery-item',
            enableSwipe: true,
            download : false,
            controls: true,
            closeOnTap  : false,
            mobileSettings: {
                showCloseIcon: true,
                controls: true,
            }
        });

        lg.outer.on("click", (e) => {
        const $item = lg.outer.find(".lg-current .lg-image");
        if (
            e.target.classList.contains("lg-image") ||
            $item.get().contains(e.target)
        ) {
            lg.goToNextSlide();
        }
        });
    }

    getImageUrl(url: string) {
        return 'https://www.fitit.lt/gallery/' + url;
    }

    goToConfiguratorNewItem() {
        this.$window.location.href = '/konfiguratorius/';
    }

    swapImage() { 
        this.galleryFade = !this.galleryFade;

        this.$timeout(() => {
            this.swapImage();
        }, 5000);

    }

    

}
