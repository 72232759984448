import './FiRemoveFromCartDialog.scss';

import * as angular from 'angular';
import { FiCart, ICartItem } from '../../services/Cart';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiRemoveFromCartDialog.html'),
    bindings: {
        itemIndex: '<'
    },
})
export class FiRemoveFromCartDialog {
    static $inject = [
        '$mdDialog',
        'fiCart',
    ];

    cartStorage: FiCart;

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private cart: FiCart,
    ) {
        //
    }

    $onInit() {
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
    }

    close() {
        this.$mdDialog.cancel();
    }

    deleteFromCart(item: number) {

        this.cart.items = this.cartStorage.items;
        console.log(this.cart);
        console.log(item);
        //console.log(this.cart.items.indexOf(item));

        //this.cart.items = this.cartStorage.items;
        //this.cart.items.splice(this.cart.items.indexOf(item), 1);
        // this.cart.count = this.cart.items.length;
        //localStorage.setItem('cart', JSON.stringify(this.cart));
        //location.reload();
    }

}