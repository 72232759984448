import './Three.scss';
import { Component } from '../../components/Component';
import * as THREE from 'three';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Three.html'),
    bindings: {
    }
})
export class Three {
    static $inject = [
        //
    ];


    constructor(
        //
        ) {
        /* */
    }
    $onInit() {
        // this.createThreeJsBox();

        this.createThreeJsBox2();
    }

    createThreeJsBox2(): void {
        const canvas = document.getElementById('canvas-box');
    
        const scene = new THREE.Scene();
    
        const material = new THREE.MeshToonMaterial();
    
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
    
        const pointLight = new THREE.PointLight(0xffffff, 0.5);
        pointLight.position.x = 2;
        pointLight.position.y = 2;
        pointLight.position.z = 2;
        scene.add(pointLight);
    
        const box = new THREE.Mesh(new THREE.BoxGeometry(10, 10, 10), material);
    

    
        scene.add(box);
    
        const canvasSizes = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
    
        const camera = new THREE.PerspectiveCamera(
          75,
          canvasSizes.width / canvasSizes.height,
          0.001,
          1000
        );
        camera.position.z = 30;
        scene.add(camera);
    
        if (!canvas) {
          return;
        }
    
        const renderer = new THREE.WebGLRenderer({
          canvas: canvas,
        });
        renderer.setClearColor(0xe232222, 1);
        renderer.setSize(canvasSizes.width, canvasSizes.height);
    
        window.addEventListener('resize', () => {
          canvasSizes.width = window.innerWidth;
          canvasSizes.height = window.innerHeight;
    
          camera.aspect = canvasSizes.width / canvasSizes.height;
          camera.updateProjectionMatrix();
    
          renderer.setSize(canvasSizes.width, canvasSizes.height);
          renderer.render(scene, camera);
        });
    
        const clock = new THREE.Clock();
    
        const animateGeometry = () => {
          const elapsedTime = clock.getElapsedTime();
    
          // Update animaiton objects
          box.rotation.x = elapsedTime;
          box.rotation.y = elapsedTime;
          box.rotation.z = elapsedTime;
    
          // torus.rotation.x = -elapsedTime;
          // torus.rotation.y = -elapsedTime;
          // torus.rotation.z = -elapsedTime;
    
          // Render
          renderer.render(scene, camera);
    
          // Call tick again on the next frame
          window.requestAnimationFrame(animateGeometry);
        };
    
        animateGeometry();
    }

    createCube() {
        const canvas = document.getElementById('canvas-box');

        const renderer = new THREE.WebGLRenderer();
        renderer.setSize( window.innerWidth, window.innerHeight );
        document.body.appendChild( renderer.domElement );

        const camera = new THREE.PerspectiveCamera( 45, window.innerWidth / window.innerHeight, 1, 500 );
        camera.position.set( 0, 0, 100 );
        camera.lookAt( 0, 0, 0 );

        const scene = new THREE.Scene();

        //create a blue LineBasicMaterial
        const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );

        const points = [];
        points.push( new THREE.Vector3( - 10, 0, 0 ) );
        points.push( new THREE.Vector3( 0, 10, 0 ) );
        points.push( new THREE.Vector3( 10, 0, 0 ) );

        const geometry = new THREE.BufferGeometry().setFromPoints( points );
        const line = new THREE.Line( geometry, material );

        scene.add( line );
        renderer.render( scene, camera );

    }

    createThreeJsBox(): void {
        const canvas = document.getElementById('canvas-box');
    
        const scene = new THREE.Scene();
    
        const material = new THREE.MeshToonMaterial();
    
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        scene.add(ambientLight);
    
        const pointLight = new THREE.PointLight(0xffffff, 0.5);
        pointLight.position.x = 2;
        pointLight.position.y = 2;
        pointLight.position.z = 2;
        scene.add(pointLight);
    
        const box = new THREE.Mesh(new THREE.BoxGeometry(1.5, 1.5, 1.5), material);
    
        const torus = new THREE.Mesh(
          new THREE.TorusGeometry(5, 1.5, 16, 100),
          material
        );
    
        scene.add(torus, box);
    
        const canvasSizes = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
    
        const camera = new THREE.PerspectiveCamera(
          75,
          canvasSizes.width / canvasSizes.height,
          0.001,
          1000
        );
        camera.position.z = 30;
        scene.add(camera);
    
        if (!canvas) {
          return;
        }
    
        const renderer = new THREE.WebGLRenderer({
          canvas: canvas,
        });
        renderer.setClearColor(0xe232222, 1);
        renderer.setSize(canvasSizes.width, canvasSizes.height);
    
        window.addEventListener('resize', () => {
          canvasSizes.width = window.innerWidth;
          canvasSizes.height = window.innerHeight;
    
          camera.aspect = canvasSizes.width / canvasSizes.height;
          camera.updateProjectionMatrix();
    
          renderer.setSize(canvasSizes.width, canvasSizes.height);
          renderer.render(scene, camera);
        });
    
        const clock = new THREE.Clock();
    
        const animateGeometry = () => {
          const elapsedTime = clock.getElapsedTime();
    
          // Update animaiton objects
          box.rotation.x = elapsedTime;
          box.rotation.y = elapsedTime;
          box.rotation.z = elapsedTime;
    
          torus.rotation.x = -elapsedTime;
          torus.rotation.y = -elapsedTime;
          torus.rotation.z = -elapsedTime;
    
          // Render
          renderer.render(scene, camera);
    
          // Call tick again on the next frame
          window.requestAnimationFrame(animateGeometry);
        };
    
        animateGeometry();
      }
}