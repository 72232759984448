import './GalleryEdit.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from '../../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';
import { IUser } from '../../../components/fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./GalleryEdit.html'),
    bindings: {
    }
})
export class GalleryEdit {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
        'fiFurnitureService',
        '$mdDialog',
        '$timeout',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private fs: FiFurnitureService,
        private $mdDialog: angular.material.IDialogService,
        private $timeout: angular.ITimeoutService,
    ) {
        /* */
    }

    private user: any;
    gallery = [];
    loading: boolean;
    view: number;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.get('https://www.fitit.lt/db/admin-gallery.php').then((data) => {
                this.gallery.push(data);
            });
        }

        this.view = 1;
    }

    changeImageType(id: number, type: number) {
        // this.loading = true;
        this.$http.post("https://www.fitit.lt/db/edit-gallery-photo.php", { 
            id: id,
            type: type
        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) { 
                this.loading = false;
        });

        this.$timeout(() => {
            // this.loading = false;
        }, 5000);
    }

    changeImageFPlace(id: number, fplace: number) {
        // this.loading = true;
        this.$http.post("https://www.fitit.lt/db/edit-gallery-photo-fplace.php", { 
            id: id,
            fplace: fplace
        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) { 
                this.loading = false;
        });

        this.$timeout(() => {
            // this.loading = false;
        }, 5000);
    }

    changeImagePlace(id: number, place: number) {
        // this.loading = true;
        this.$http.post("https://www.fitit.lt/db/edit-gallery-photo-place.php", { 
            id: id,
            place: place
        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) { 
                this.loading = false;
        });

        this.$timeout(() => {
            // this.loading = false;
        }, 5000);
    }

    editImage() {
        // this.$mdDialog.show({
            //template: '<fi-gallery-item-edit-dialog></fi-gallery-item-edit-dialog>',
            //parent: angular.element(document.body),
            //clickOutsideToClose: true,
            //controllerAs: 'vm'
        //});
    }

    deleteImageOld(imageName: string) {

        var confirm = this.$mdDialog.confirm()
            .title('Ar tikrai norite ištrinti?')
            .textContent(imageName)
            .ariaLabel('')
            //.targetEvent(imageName)
            .ok('Taip')
            .cancel('Ne');

            this.$mdDialog.show(confirm).then(function () {
                this.$http.post("https://www.fitit.lt/db/delete-gallery-photo.php", { 

                    imageName: imageName

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) {
                        //
                });
                
            }, function () {
                this.$mdDialog.cancel();
            });
    }

    deleteImage(itemID: string, imageName: string) {

        this.$http.post("https://www.fitit.lt/db/delete-gallery-photo.php", { 

            itemID: itemID,
            imageName: imageName

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });

        location.reload();
    }

    viewImage(img: string) {
        this.$window.open('https://www.fitit.lt/gallery/' + img, '_blank');
    }

    addPhoto() {

        this.$mdDialog.show({
            template: '<fi-add-photo-dialog category=' + (this.view - 1) + '></fi-add-photo-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }
}
