import './FiBox.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService, iPanelItem } from './../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiBox.html'),
    bindings: {
        all: '<',
        active: '<',
        image: '@',
        imageActive: '@',
        title: '@',
        listItem: '<',
        price: '@',
        type: '@',
        item: '<',
        category: '<',
        showMoreLink: '<',
        side: '<',
    },
})
export class FiBox {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
    }

    type: number;
    mdfprice: string;
    side: number;
    category: number;
    all: any;

    $onInit() {
        //console.log(this.item);
    }

    showDialog(item, type, mdfprice) {

        if (this.category == 1 || this.category == 16) {

            this.fs.items = this.all;
            this.fs.panelItem = item;
            this.fs.mdfItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="' + this.type + '"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
            
        }
        else if (this.category == 2) {

            this.fs.items = this.all;
            this.fs.legItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-leg-dialog></fi-fe-leg-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });

        }
        else if (this.category == 3 || this.category == 21) {
            this.fs.items = this.all;
            this.fs.handleItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-dialog></fi-fe-handle-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 4 || this.category == 22) {
            this.fs.items = this.all;
            this.fs.handleInternalItem = item;
            this.$mdDialog.show({
                template: '<fi-fe-handle-internal-dialog></fi-fe-handle-internal-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 5) {
            this.fs.items = this.all;
            this.fs.panelItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="' + this.type + '"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 6) {
            this.fs.items = this.all;
            this.fs.mirrorItem = item;
            this.$mdDialog.show({
                template: '<fi-mirror-dialog></fi-mirror-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 7 || this.category == 20) {
            this.fs.items = this.all;
            this.fs.knobItem = item;
            this.$mdDialog.show({
                template: '<fi-knob-dialog></fi-knob-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 8) {
            this.fs.items = this.all;
            this.fs.fillingItem = item;
            this.$mdDialog.show({
                template: '<fi-panel-filling-dialog></fi-panel-filling-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }

        else if (this.category == 9) {
            this.fs.items = this.all;
            this.fs.mdfItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="4" mdfprice="' + this.mdfprice + '"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 10 || this.category == 23) {
            this.fs.items = this.all;
            this.fs.milledKnobItem = item;
            this.$mdDialog.show({
                template: '<fi-milled-knob-dialog></fi-milled-knob-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm',
                multiple: true,
                hasBackdrop: true
            });
        }
        else if (this.category == 10) {
            this.fs.items = this.all;
            this.fs.mdfItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="' + this.type + '" mdfprice="' + this.mdfprice + '"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }

        else if (this.category == 11) {
            this.fs.items = this.all;
            this.fs.mdfItem = item;
            this.$mdDialog.show({
                template: '<fi-material-dialog type="' + this.type + '" mdfprice="' + this.mdfprice + '"></fi-material-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }

        else if (this.category == 12) {
            this.fs.fillingItem = item;
            this.$mdDialog.show({
                template: '<fi-panel-filling-dialog></fi-panel-filling-dialog>',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                controllerAs: 'vm'
            });
        }
    }


    cancel() {
        this.$mdDialog.cancel();
    }

    select(item) {
        if (this.category == 1) {

            this.fs.mdfItem = item;
            this.fs.panelItem = item;
          
            // 1 - Fasadas
            if(this.side == 1) {

                if (this.fs.furniture.material_is_mdf) {
                    this.fs.furniture.material_mdf = this.fs.mdfItem;
                    this.fs.furniture.material_mdf_id = this.fs.mdfItem.id;
                    this.fs.selected_mdf_gloss = this.fs.furniture.material_mdf_gloss;
                }
                else {
                    this.fs.furniture.material = this.fs.panelItem;
                    this.fs.furniture.material_id = this.fs.panelItem.id;
                }
 
            }

            // 2 - Left side
            else if (this.side == 2) {
                if (this.fs.furniture.material_ls_is_mdf) {
                    this.fs.furniture.material_ls_mdf = this.fs.mdfItem;
                    this.fs.furniture.material_ls_mdf_id = this.fs.mdfItem.id;
                    this.fs.selected_mdf_gloss = this.fs.furniture.material_ls_mdf_gloss;
                }
                else {
                    this.fs.furniture.material_ls_lmdp = this.fs.panelItem;
                    this.fs.furniture.material_ls_lmdp_id = this.fs.panelItem.id;
                }
                
            }

            // 3 - Right side
            else if (this.side == 3) {

                if (this.fs.furniture.material_rs_is_mdf) {
                    this.fs.furniture.material_rs_mdf = this.fs.mdfItem;
                    this.fs.furniture.material_rs_mdf_id = this.fs.mdfItem.id;
                    this.fs.selected_mdf_gloss = this.fs.furniture.material_rs_mdf_gloss;
                }
                else {
                    this.fs.furniture.material_rs_lmdp = this.fs.panelItem;
                    this.fs.furniture.material_rs_lmdp_id = this.fs.panelItem.id;
                }

            }

            // 4 - Top side
            else if (this.side == 4) {
                if (this.fs.furniture.material_ts_is_mdf) {
                    this.fs.furniture.material_ts_mdf = this.fs.mdfItem;
                    this.fs.furniture.material_ts_mdf_id = this.fs.mdfItem.id;
                    this.fs.selected_mdf_gloss = this.fs.furniture.material_ts_mdf_gloss;
                }
                else {
                    this.fs.furniture.material_ts_lmdp = this.fs.panelItem;
                    this.fs.furniture.material_ts_lmdp_id = this.fs.panelItem.id;
                }
                
            }

            // 5 - Bottom side
            else if (this.side == 5) {
                if (this.fs.furniture.material_bs_is_mdf) {
                    this.fs.furniture.material_bs_mdf = this.fs.mdfItem;
                    this.fs.furniture.material_bs_mdf_id = this.fs.mdfItem.id;
                    this.fs.selected_mdf_gloss = this.fs.furniture.material_bs_mdf_gloss;
                }
                else {
                    this.fs.furniture.material_bs_lmdp = this.fs.panelItem;
                    this.fs.furniture.material_bs_lmdp_id = this.fs.panelItem.id;
                }
                
            }
            
            
        }
        else if (this.category == 16) {

            this.fs.mdfItem = item;
            this.fs.panelItem = item;
            
            if (this.type == 1) {
                this.fs.furniture.material = this.fs.panelItem;
                this.fs.furniture.material_id = this.fs.panelItem.id;
            }
            else if (this.type == 2) {

                if (this.fs.sameDoorsPanel) {
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {

                            row.is_mdf = false;
                            row.mdf = null;
                            row.mdf_id = null;

                            row.panel = this.fs.panelItem;
                            row.panel_id = this.fs.panelItem.id;
                        }
                    }
                }
                else {
                    this.fs.furniture.activeRow.is_mdf = false;
                    this.fs.furniture.activeRow.panel = this.fs.panelItem;
                    this.fs.furniture.activeRow.panel_id = this.fs.panelItem.id;
                }
                
            }
        }
        else if (this.category == 2) {

            this.fs.legItem = item;
            this.fs.furniture.plinth.legs = this.fs.legItem;
            this.fs.furniture.plinth.legs_id = this.fs.legItem.id;
            this.fs.furniture.plinth.height = (Number(this.fs.furniture.plinth.legs.height) / 10) + this.fs.edgeWidth;

            for(const column of this.fs.furniture.columns) {
                column.height = this.fs.furniture.height - this.fs.furniture.entresolHeight - this.fs.furniture.plinth.height;
    
                for(const row of column.rows) {
                    row.height = column.height / column.rowsCount;
                    row.inner_height = (column.height - (this.fs.edgeWidth * column.rowsCount)) / column.rowsCount;
                }
            }

        }
        else if (this.category == 21) {
            this.fs.handleItem = item;
            this.fs.furniture.activeDoorRow.handle = this.fs.handleItem;
            this.fs.furniture.activeDoorRow.handle_id = this.fs.handleItem.id;
        }
        else if (this.category == 3) {
            this.fs.handleItem = item;
            
            if (this.fs.sameHandles) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle = this.fs.handleItem;
                        row.handle_id = this.fs.handleItem.id;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle_internal = null;
                        row.handle_internal_id = 0;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.milled_knob = null;
                        row.milled_knob_id = 0;
                    }
                }

                this.fs.furniture.activeRow.milled_knob = null;
                this.fs.furniture.activeRow.milled_knob_id = 0;

                this.fs.furniture.activeRow.handle_internal = null;
                this.fs.furniture.activeRow.handle_internal_id = 0;
            }
            else {
                this.fs.furniture.activeRow.handle = this.fs.handleItem;
                this.fs.furniture.activeRow.handle_id = this.fs.handleItem.id;
            }

            
        }
        else if (this.category == 22) {
            this.fs.handleInternalItem = item;
            this.fs.furniture.activeDoorRow.handle_internal = this.fs.handleInternalItem;
            this.fs.furniture.activeDoorRow.handle_internal_id = this.fs.handleInternalItem.id;
        }
        else if (this.category == 4) {
            this.fs.handleInternalItem = item;

            if (this.fs.sameHandles) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle_internal = this.fs.handleInternalItem;
                        row.handle_internal_id = this.fs.handleInternalItem.id;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle = null;
                        row.handle_id = 0;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.milled_knob = null;
                        row.milled_knob_id = 0;
                    }
                }

                this.fs.furniture.activeRow.milled_knob = null;
                this.fs.furniture.activeRow.milled_knob_id = 0;

                this.fs.furniture.activeRow.handle = null;
                this.fs.furniture.activeRow.handle_id = 0;
            }
            else {
                this.fs.furniture.activeRow.handle_internal = this.fs.handleInternalItem;
                this.fs.furniture.activeRow.handle_internal_id = this.fs.handleInternalItem.id;
            }
        }

        // Sliding door lmdp
        else if (this.category == 5) {

            this.fs.slidingDoorPanelItem = item;

            if (this.fs.sameSlidingDoorsPanel) {
                for(const door of this.fs.furniture.slidingDoors) {
                    for(const row of door.divideRows) {
                        row.panel = this.fs.slidingDoorPanelItem;
                        row.panel_id = this.fs.slidingDoorPanelItem.id;
                    }
                }

                this.fs.furniture.activeDoorRow.mirror = null;
                this.fs.furniture.activeDoorRow.mirror_id = 0;
            }
            else {
                this.fs.furniture.activeDoorRow.panel = this.fs.slidingDoorPanelItem;
                this.fs.furniture.activeDoorRow.panel_id = this.fs.slidingDoorPanelItem.id;
            }
        }

        // Mirorr
        else if (this.category == 6) {
            this.fs.mirrorItem = item;

            if (this.fs.sameSlidingDoorsPanel) {
                for(const door of this.fs.furniture.slidingDoors) {
                    for(const row of door.divideRows) {
                        row.mirror = this.fs.mirrorItem;
                        row.mirror_id = this.fs.mirrorItem.id;
                    }
                }
                this.fs.furniture.activeDoorRow.panel = null;
                this.fs.furniture.activeDoorRow.panel_id = 0;
            }
            else {
                this.fs.furniture.activeDoorRow.mirror = this.fs.mirrorItem;
                this.fs.furniture.activeDoorRow.mirror_id = this.fs.mirrorItem.id;
            }
        }

        // MDF sliding doors
        else if (this.category == 15) {
            this.fs.mdfItem = item;

            if (this.fs.sameSlidingDoorsPanel) {
                for(const door of this.fs.furniture.slidingDoors) {
                    for(const row of door.divideRows) {
                        row.mdf = this.fs.mdfItem;
                        row.mdf_id = this.fs.mdfItem.id;
                    }
                }
                this.fs.furniture.activeDoorRow.mdf = null;
                this.fs.furniture.activeDoorRow.mdf_id = 0;
            }
            else {
                this.fs.furniture.activeDoorRow.mdf = this.fs.mdfItem;
                this.fs.furniture.activeDoorRow.mdf_id = this.fs.mdfItem.id;
            }
        }

        // Knob
        else if (this.category == 7) {
            this.fs.knobItem = item;

            this.fs.furniture.knob = this.fs.knobItem;
            this.fs.furniture.knob_id = this.fs.knobItem.id;

        }

        // Knob
        else if (this.category == 20) {
            this.fs.knobItem = item;

            this.fs.furniture.activeDoorRow.knob = this.fs.knobItem;
            this.fs.furniture.activeDoorRow.knob_id = this.fs.knobItem.id;

        }

        // Filling
        else if (this.category == 8) {

            this.fs.fillingItem = item;
            

            if (this.fs.sameFilling) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {

                        if (this.fs.sameFillings) {
                            row.fillings = true;
                            row.filling = this.fs.fillingItem;
                            row.filling_id = this.fs.fillingItem.id;
                        }
                        else {
                            row.fillings = false;
                            row.filling = null;
                            row.filling_id = null;
                        }
                        

                    }
                }
            }
            else {
                if (this.fs.sameFillings) {
                    this.fs.furniture.activeRow.fillings = true;
                    this.fs.furniture.activeRow.filling = this.fs.fillingItem;
                    this.fs.furniture.activeRow.filling_id = this.fs.fillingItem.id;
                }
                else {
                    this.fs.furniture.activeRow.fillings = false;
                    this.fs.furniture.activeRow.filling = null;
                    this.fs.furniture.activeRow.filling_id = null;
                }
                
            }
                
        }

        // MDF
        else if (this.category == 9) {

            this.fs.mdfItem = item;

            if (this.fs.sameDoorsPanel) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {

                        row.panel = null;
                        row.panel_id = null;
                        
                        row.is_mdf = true;
                        row.mdf = this.fs.mdfItem;
                        row.mdf_id = this.fs.mdfItem.id;
                        row.mdf_gloss = this.fs.selected_mdf_gloss;
                    }
                }
            }
            else {
                this.fs.furniture.activeRow.is_mdf = true;
                this.fs.furniture.activeRow.mdf = this.fs.mdfItem;
                this.fs.furniture.activeRow.mdf_id = this.fs.mdfItem.id;
                this.fs.furniture.activeRow.mdf_gloss = this.fs.selected_mdf_gloss;
            }
                
        }

        // Milled kbob
        else if (this.category == 23) {
            this.fs.milledKnobItem = item;
            this.fs.furniture.activeDoorRow.milled_knob = this.fs.milledKnobItem;
            this.fs.furniture.activeDoorRow.milled_knob_id = this.fs.milledKnobItem.id;
        }

        // Milled kbob
        else if (this.category == 10) {
            this.fs.milledKnobItem = item;
            
            if (this.fs.sameHandles) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.milled_knob = this.fs.milledKnobItem;
                        row.milled_knob_id = this.fs.milledKnobItem.id;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle = null;
                        row.handle_id = 0;
                    }
                }

                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.handle_internal = null;
                        row.handle_internal_id = 0;
                    }
                }

                this.fs.furniture.activeRow.handle = null;
                this.fs.furniture.activeRow.handle_id = 0;

                this.fs.furniture.activeRow.handle_internal = null;
                this.fs.furniture.activeRow.handle_internal_id = 0;
            }
            else {
                this.fs.furniture.activeRow.milled_knob = this.fs.milledKnobItem;
                this.fs.furniture.activeRow.milled_knob_id = this.fs.milledKnobItem.id;
            }
        }
        else if (this.category == 11) {
            this.fs.mdfItem = item;
            this.fs.furniture.material_mdf = this.fs.mdfItem;
            this.fs.furniture.material_mdf_id = this.fs.mdfItem.id;
         
        }
        else if (this.category == 12) {
            this.fs.fillingItem = item;
            this.fs.furniture.material_mdf_filling = this.fs.fillingItem;
            this.fs.furniture.material_mdf_filling_id = this.fs.fillingItem.id;
         
        }
        

        this.$mdDialog.cancel();
    }
}
