import './FiNoResults.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiNoResults.html'),
    bindings: {
    },
})
export class FiNoResults {
    static $inject = [
    ];


    constructor(
    ) {
    }

    $onInit() {
        //
    }

}
