import './FiTestimonials.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiTestimonials.html'),
    bindings: {
    },
})
export class FiTestimonials {
    static $inject = [
    ];

    private translateUnhook: any;

    constructor(
    ) {
    }

    $onInit() {
        //
    }

}
