import './FiSelectPanelFillingDialog.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiSelectPanelFillingDialog.html'),
    bindings: {
        //
    },
})
export class FiSelectPanelFillingDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    filling_items = [];
    filling_results = [];

    filter = {
        fillings: {
            style: 0,
            filling_count: 0,
        }
    };

    $onInit() {
        // Fillings
        this.$http.get('https://www.fitit.lt/db/fillings.php').then((data) => {
            this.filling_items.push(data.data);
            this.filling_results = this.filling_items[0];
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

    applySameFillings() {
        if (!this.fs.sameFillings) {

            if (this.fs.sameFilling) {
                for(const column of this.fs.furniture.columns) {
                    for(const row of column.rows) {
                        row.fillings = false;
                        row.filling = null;
                        row.filling_id = null;
                    }
                }
            } else {
                this.fs.furniture.activeRow.fillings = false;
                this.fs.furniture.activeRow.filling = null;
                this.fs.furniture.activeRow.filling_id = null;
            }

        }
    }

    applyFilter(f) {
       
            this.filling_results = this.filling_items[0];

            if (this.filter.fillings.style) {
                this.filling_results = this.filling_results.filter(item => item.style == this.filter.fillings.style );
            }

            if (this.filter.fillings.filling_count) {
                this.filling_results = this.filling_results.filter(item => item.filling_count == this.filter.fillings.filling_count );
            }

       
    }

}