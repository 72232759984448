import './FiSelectMaterialDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, iMDFItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiSelectMaterialDialog.html'),
    bindings: {
        hideButton: '<',
        side: '<' 
    },
})
export class FiSelectMaterialDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    lmdp_items = [];
    lmdp_results = [];

    mdf_items = [];
    mdf_results = [];

    side: number;

    filter = {
        lmdp: {
            color_tone: 0,
            color: 0,
            gloss: 0,
            roughness: 0,
            pattern: 0,
            manufacturer: 0,
            supplier: 0
        },
        mdf: {
            plate_thickness: 0,
            gloss: 0
        }
    };

    $onInit() {
        // LMDP
        this.$http.get('https://www.fitit.lt/db/lmdp.php').then((data) => {
            this.lmdp_items.push(data.data);
            this.lmdp_results = this.lmdp_items[0];
        });

        // MDF
        this.$http.get('https://www.fitit.lt/db/mdf.php').then((data) => {
            this.mdf_items.push(data.data);
            this.mdf_results = this.mdf_items[0];
        });

        if (this.side == 1) {
            this.fs.selected_mdf_gloss = this.fs.furniture.material_mdf_gloss;
        }
        else if (this.side == 2) {
            this.fs.selected_mdf_gloss = this.fs.furniture.material_ls_mdf_gloss;
        }
        else if (this.side == 3) {
            this.fs.selected_mdf_gloss = this.fs.furniture.material_rs_mdf_gloss;
        }
        else if (this.side == 4) {
            this.fs.selected_mdf_gloss = this.fs.furniture.material_ts_mdf_gloss;
        }
        else if (this.side == 5) {
            this.fs.selected_mdf_gloss = this.fs.furniture.material_bs_mdf_gloss;
        }
    }

    applyFilter(filterCategory: number) {


        this.lmdp_results = this.lmdp_items[0];

        if (this.filter.lmdp.color_tone) {
            this.lmdp_results = this.lmdp_results.filter(item => item.color_tone == this.filter.lmdp.color_tone );
        }
        
        if (this.filter.lmdp.color) {
            this.lmdp_results = this.lmdp_results.filter(item => item.color == this.filter.lmdp.color );
        }

        if (this.filter.lmdp.gloss) {
            this.lmdp_results = this.lmdp_results.filter(item => item.gloss == this.filter.lmdp.gloss );
        }
        
        if (this.filter.lmdp.roughness) {
            this.lmdp_results = this.lmdp_results.filter(item => item.roughness == this.filter.lmdp.roughness );
        }

        if (this.filter.lmdp.pattern) {
            this.lmdp_results = this.lmdp_results.filter(item => item.pattern == this.filter.lmdp.pattern );
        }
        
        if (this.filter.lmdp.supplier) {
            this.lmdp_results = this.lmdp_results.filter(item => item.supplier == this.filter.lmdp.supplier );
        }
      
    }


    // 1 - Fasadas
    // 2 - Left side
    // 3 - Right side
    // 4 - Top side
    // 5 - Bottom side

    isButtonActiveMDF() {
        if (this.side == 1) {
            if (this.fs.furniture.material_is_mdf) { return true; } else { return false; }
        }
        else if (this.side == 2) {
            if (this.fs.furniture.material_ls_is_mdf) { return true; } else { return false; }
        }
        else if (this.side == 3) {
            if (this.fs.furniture.material_rs_is_mdf) { return true; } else { return false; }
        }
        else if (this.side == 4) {
            if (this.fs.furniture.material_ts_is_mdf) { return true; } else { return false; }
        }
        else if (this.side == 5) {
            if (this.fs.furniture.material_bs_is_mdf) { return true; } else { return false; }
        }
    }

    selectMaterialType(isMdf: boolean) {

        if (this.side == 1) {
            this.fs.furniture.material_is_mdf = isMdf;
        }
        else if (this.side == 2) {
            this.fs.furniture.material_ls_is_mdf = isMdf;
        }
        else if (this.side == 3) {
            this.fs.furniture.material_rs_is_mdf = isMdf;
        }
        else if (this.side == 4) {
            this.fs.furniture.material_ts_is_mdf = isMdf;
        }
        else if (this.side == 5) {
            this.fs.furniture.material_bs_is_mdf = isMdf;
        }
    }

    isBoxMdfActive(code: string) {
        if (this.side == 1) {
            if (this.fs.furniture.material_mdf.code == code) {
                return true;
            }
            else {
                return false;
            }
            
        }
        else if (this.side == 2) {
            if (this.fs.furniture.material_ls_mdf.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 3) {
            if (this.fs.furniture.material_rs_mdf.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 4) {
            if (this.fs.furniture.material_ts_mdf.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 5) {
            if (this.fs.furniture.material_bs_mdf.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    isBoxLmdpActive(code: string) {
        if (this.side == 1) {
            if (this.fs.furniture.material.code == code) {
                return true;
            }
            else {
                return false;
            }
            
        }
        else if (this.side == 2) {
            if (this.fs.furniture.material_ls_lmdp.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 3) {
            if (this.fs.furniture.material_rs_lmdp.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 4) {
            if (this.fs.furniture.material_ts_lmdp.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (this.side == 5) {
            if (this.fs.furniture.material_bs_lmdp.code == code) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    applyMDFFilter() {

        if (this.side == 1) {
            this.fs.furniture.material_mdf_gloss = this.fs.selected_mdf_gloss;
        }
        else if (this.side == 2) {
            this.fs.furniture.material_ls_mdf_gloss = this.fs.selected_mdf_gloss;
        }
        else if (this.side == 3) {
            this.fs.furniture.material_rs_mdf_gloss = this.fs.selected_mdf_gloss;
        }
        else if (this.side == 4) {
            this.fs.furniture.material_ts_mdf_gloss = this.fs.selected_mdf_gloss;
        }
        else if (this.side == 5) {
            this.fs.furniture.material_bs_mdf_gloss = this.fs.selected_mdf_gloss;
        }

    }

    getMDFPanelPrice(mdf: iMDFItem) {

        if (this.fs.selected_mdf_gloss == 0) {
            return mdf.price_matte_2_side;
        }
        else if (this.fs.selected_mdf_gloss == 1) {
            return mdf.price_matte_1_side;
         }
        else if (this.fs.selected_mdf_gloss == 2) {
            return mdf.price_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 3) {
            return mdf.price_super_shiny_1_side;
        }
        else if (this.fs.selected_mdf_gloss == 4) {
            return mdf.price_super_shiny_2_side;
        }
    }

   
    close() {
        this.$mdDialog.cancel();
    }

}