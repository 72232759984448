import './Cart.scss';
import { Component } from '../../components/Component';
import { FiCart, ICartItem } from '../../services/Cart';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService, IFurnitureItem } from './../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Cart.html'),
    bindings: {
    }
})
export class Cart {
    static $inject = [
        '$scope',
        '$http',
        'fiSubHeaderService',
        'fiCart',
        '$mdDialog',
        '$mdSidenav',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $scope: angular.IScope,
        private $http: angular.IHttpService,
        private fshs: FiSubHeaderService,
        private cart: FiCart,
        private $mdDialog: angular.material.IDialogService,
        private $mdSidenav: angular.material.ISidenavService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }
    
    cartStorage: FiCart;
    cartTotalPrice: number = 0;
    assemblyPriceTotal: number = 0;
    carryPriceTotal: number = 0;
    user: any;

    $onInit() {
        this.fshs.subHeaderIsHidden = true;
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
            'event': 'checkout',
            'ecommerce': {
            'checkout': {
                'actionField': {'step': 1 },
                'products': [{
                'name': this.cartStorage.items[0].item.title,
                'id': this.cartStorage.items[0].item.item_id,
                'price': this.cartStorage.items[0].item.price,
                'quantity': 1
            }]
            }
        }
    });

            
       
        
        // console.log(this.cartStorage);
    }

    getAssemblyPrice() {
        this.assemblyPriceTotal = 0;
        for(const item of this.cartStorage.items) {
            this.assemblyPriceTotal = this.assemblyPriceTotal + (item.assemblyPrice * item.count);
        }
        return this.assemblyPriceTotal;
    }

    getCarryPrice() {
        this.carryPriceTotal = 0;
        for(const item of this.cartStorage.items) {
            this.carryPriceTotal = this.carryPriceTotal + (item.carryPrice * item.count);
        }
        return this.carryPriceTotal;
    }

    getTotalPriceWithExtra(item: IFurnitureItem) {
        let count = 0;
        count = Number(item.price) + Number(item.extra_price_1) + Number(item.extra_price_2) + Number(item.extra_price_3) + Number(item.extra_price_4) + Number(item.extra_price_5);
        return count;
    }

    openDetails(item: IFurnitureItem) {
        this.fs.furniture = item;
        this.$mdSidenav('item-details').open();
    }

    closeDetails() {
        this.$mdSidenav('item-details').close();
    }


    editItem(item: IFurnitureItem) {
        this.fs.furniture = item;
        history.back();
    }

    goBack() {
        this.$window.location.href = '/#/konfiguratorius/';
        // history.back();
    }

    getType(type: number) {
        if(type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }

    getCartTotalPrice() {
        this.cartTotalPrice = 0;
        for(const item of this.cartStorage.items) {
            this.cartTotalPrice = this.cartTotalPrice + (this.getTotalPriceWithExtra(item.item) * item.count);
        }
        return this.cartTotalPrice;
    }

    login() {
        this.$mdDialog.show({
            template: '<fi-login-dialog></fi-login-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    removeItem(item: ICartItem) {

        this.cart.items = this.cartStorage.items;
        this.cart.items.splice(this.cart.items.indexOf(item), 1);
        this.cart.count = this.cart.items.length;
        this.cart.totalPrice = this.getCartTotalPrice();
        localStorage.setItem('cart', JSON.stringify(this.cart));
        this.cartStorage.count = this.cart.count;
 
        // this.$mdDialog.show({
          //  template: '<fi-remove-from-cart-dialog item="' + item + '"></fi-remove-from-cart-dialog>',
          //  parent: angular.element(document.body),
          //  clickOutsideToClose: true,
          //  controllerAs: 'vm'
        // });
    }


    modifyCartItemCount(count: number, item: ICartItem) {
        if (count < 1 || count > 99) {
            return;
        }
        this.cart.items = this.cartStorage.items;
        this.cart.items[this.cart.items.indexOf(item)].count = count;
        this.cart.count = this.cart.items.length;
        this.cart.totalPrice = this.getCartTotalPrice();
        localStorage.setItem('cart', JSON.stringify(this.cart));
    }
}
