import './SketchOutside.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService, IColumnItem, IRowItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./SketchOutside.html'),
    bindings: {
    },
})
export class SketchOutside {
    static $inject = [
        'fiFurnitureService',
    ];


    constructor(
        private fs: FiFurnitureService,
    ) {
    }

    $onInit() {
        //
    }

    getMergedRowsHeight(column: IColumnItem, row: IRowItem) {

        if (row.merged) {

            let total = 0;
            for(const mw of row.merged_with) {

                for(const cr of column.rows) {

                    if (cr.row_id == mw.row_id) {
                        total = total + Number(cr.height);
                    }
                }
                
            }
            return total + row.inner_height;
        }
    }

}
