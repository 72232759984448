import './ViewOrder.scss';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService, IFurnitureItem, IOrder } from '../../components/fiFurniture/FiFurnitureService';
import { Component } from '../../components/Component';
import { FiCart } from '../../services/Cart';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./ViewOrder.html'),
    bindings: {
    }
})
export class ViewOrder {
    static $inject = [
        'fiSubHeaderService',
        'fiFurnitureService',
        '$location',
        '$http',
        '$state',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private fs: FiFurnitureService,
        private $location: angular.ILocationService,
        private $http: angular.IHttpService,
        private $state: uirouter.StateService,
    ) {
        /* */
    }

    order_no: string;
    user: any;
    order: IOrder;
    view: number;
    data = [];
    orderItems = [];

    $onInit() {

        this.fshs.subHeaderIsHidden = false;

        // this.order_no = this.$location.search().no;
        this.order_no = this.$state.params['orderId'];

        // console.log(this.order_no);

        this.$http.get('https://www.fitit.lt/db/get-order.php?no=' + this.order_no).then((data) => {
            this.data.push(data);
            this.fs.order = data.data[0];
            this.orderItems = [];

            this.$http.post("https://www.fitit.lt/db/order-items.php", { 
                order_id: this.fs.order.order_id,
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    
                    this.orderItems.push(response.data);
                    this.fs.order.items = this.orderItems;

                    this.$http.post("https://www.fitit.lt/db/order-user.php", { 
                        user_id: this.fs.order.user_id,
                    }, { headers: {'Content-Type': 'application/json'} })
                        .then((response: any) => {
                            this.fs.order.user = response.data[0];
                    });


                    for(const item of this.orderItems[0]) {
                        this.$http.post("https://www.fitit.lt/db/order-item.php", { 
                            item_id: item.item_id,
                        }, { headers: {'Content-Type': 'application/json'} })
                            .then((response: any) => {

                                item.item = response.data;

                                console.log(item.item);
                                
                        });
                    }
                    
            });
        });

        this.view = 1;

    }

    getExtraTotal(extra1: number, extra2: number, extra3: number, extra4: number, extra5: number) {
        return Number(extra1) + Number(extra2) + Number(extra3) + Number(extra4) + Number(extra5);
    }

    viewOrderItem(item_id: string) {
        // this.fs.order = item;
        this.fs.selectFurniture(item_id, false);
        this.view = 2;
    }
}
