import './FiInfoDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiInfoDialog.html'),
    bindings: {
        infoTitle: '@',
        infoContent: '@',
    },
})
export class FiInfoDialog {
    static $inject = [
        '$mdDialog',
        '$sce',
    ];

    infoTitle: string;
    infoContent: string;


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private $sce: angular.ISCEService,
    ) {
        //
    }

    $onInit() {
        // this.content = this.$sce.trustAsHtml(this.content);
        console.log(this.infoTitle);
        console.log(this.infoContent);
    }

    close() {
        this.$mdDialog.cancel();
    }
}