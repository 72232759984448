import './FiChooseInternal.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService, IColumnItem, IRowItem } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiChooseInternal.html'),
    bindings: {
        hideButton: '<' 
    },
})
export class FiChooseInternal {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
    }

    unActivateRow() {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {
            c.active = false;
            c.rows.forEach((r: IRowItem) => {
                    r.active = false;
                
            });
        });
        this.fs.furniture.activeRow = null;
        this.fs.furniture.activeColumn = null;
    }

    setInner(selection) {
        if (selection == 1) {
            if (this.fs.furniture.activeRow.height < 50 ) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 2) {
            if (this.fs.furniture.activeRow.height < 90 ) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 4) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 15 ||
                this.fs.furniture.activeRow.width > 120 ||
                this.fs.furniture.depth < 20) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 5 || selection == 6 || selection == 7) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 30 ||
                this.fs.furniture.activeRow.width > 240 ||
                this.fs.furniture.depth < 20) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 10) {
            if (this.fs.furniture.activeRow.width < 10 || 
                this.fs.furniture.activeRow.width > 90 || 
                this.fs.furniture.depth < 20) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 9) {
            if (this.fs.furniture.activeRow.height < 100 || 
                this.fs.furniture.activeRow.width < 54.5 || 
                this.fs.furniture.activeRow.width > 120) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else if (selection == 8) {
            if (this.fs.furniture.activeRow.height < 50) {
                return false;
            }
            else {
                this.fs.furniture.activeRow.inner = selection;
            }
        }
        else {
            this.fs.furniture.activeRow.inner = selection;
        }

        this.unActivateRow();
        this.$mdDialog.cancel();
    }

    innerIsDisabled(selection) {
        if (selection == 1) {
            if (this.fs.furniture.activeRow.height < 50 ) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 2) {
            if (this.fs.furniture.activeRow.height < 90 ) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 4) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 15 || 
                this.fs.furniture.activeRow.width > 120 || 
                this.fs.furniture.depth < 20) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 5 || selection == 6 || selection == 7) {
            if (this.fs.furniture.activeRow.height < 6 || 
                this.fs.furniture.activeRow.height > 85 || 
                this.fs.furniture.activeRow.width < 30 ||
                this.fs.furniture.activeRow.width > 240 ||
                this.fs.furniture.depth < 20) {
                    return true;
            }
            else {
                return false
            }
        }
        else if (selection == 10) {
            if (this.fs.furniture.activeRow.width < 10 || 
                this.fs.furniture.activeRow.width > 90 || 
                this.fs.furniture.depth < 20) {
                return true;
            }
            else {
                return false
            }
        }
        else if (selection == 9) {
            if (this.fs.furniture.activeRow.height < 100 || 
                this.fs.furniture.activeRow.width < 54.5 || 
                this.fs.furniture.activeRow.width > 120) {
                return true;
            }
            else {
                return false;
            }
        }
        else if (selection == 8) {
            if (this.fs.furniture.activeRow.height < 50) {
                return true;
            }
            else {
                return false
            }
        }
        else {
            return false
        }
    }
    
   

    close() {
        this.unActivateRow();
        this.$mdDialog.cancel();
    }
}