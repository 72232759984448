import './AdminUsers.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from '../../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';
import { IUser } from '../../../components/fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./AdminUsers.html'),
    bindings: {
    }
})
export class AdminUsers {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
        'fiFurnitureService',
        '$mdDialog',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private fs: FiFurnitureService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    private user: any;
    users = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.get('https://www.fitit.lt/db/users.php').then((data) => {
                this.users.push(data);
            });
        }
    }

    viewUser(user: IUser) {

        this.fs.userToView = user;

        this.$mdDialog.show({
            template: '<fi-user-dialog></fi-user-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }
}
