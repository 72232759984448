import './FiImageEditorDialog.scss';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiImageEditorDialog.html'),
    bindings: {
        infoTitle: '@',
        infoContent: '@',
    },
})
export class FiImageEditorDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    deleteImage(imageName: string, itemID: string, imageNo: number) {

        this.$http.post("https://www.fitit.lt/db/delete-item-photo.php", { 

            imageName: imageName,
            itemID: itemID,
            imageNo: imageNo

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                // this.$mdDialog.cancel();
        });
        
        location.reload();
        // this.$window.location.href = '/baldu-sarasas';
        
    }

    $onInit() {
        // console.log(this.fs.furniture)
    }

    close() {
        this.$mdDialog.cancel();
    }

    viewImage(img: string) {
        this.$window.open('https://www.fitit.lt/uploads/' + img, '_blank');
    }
}