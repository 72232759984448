import './FiSelectPlinthDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiSelectPlinthDialog.html'),
    bindings: {
        hideButton: '<' 
    },
})
export class FiSelectPlinthDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    legs_items = [];
    legs_results = [];

    filter = {
        legs: {
            color: 0,
            material: 0,
            height: 0,
            adjustable_height: 0
        }
    };

    $onInit() {
        // legs
        this.$http.get('https://www.fitit.lt/db/legs.php').then((data) => {
            this.legs_items.push(data.data);
            this.legs_results = this.legs_items[0];
        });
    }

    applyFilter(filterCategory: number) {

        if (filterCategory == 1) {
            this.legs_results = this.legs_items[0];

            if (this.filter.legs.color) {
                this.legs_results = this.legs_results.filter(item => item.color == this.filter.legs.color );
            }

            if (this.filter.legs.height) {
                this.legs_results = this.legs_results.filter(item => item.height == this.filter.legs.height );
            }

            if (this.filter.legs.material) {
                this.legs_results = this.legs_results.filter(item => item.material == this.filter.legs.material );
            }

            if (this.filter.legs.adjustable_height) {
                this.legs_results = this.legs_results.filter(item => item.adjustable_height == this.filter.legs.adjustable_height );
            }

        }
    }


    close() {
        this.$mdDialog.cancel();
    }

}