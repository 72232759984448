import './Configurator.scss';

import { Component } from '../../components/Component';
import { FiExpansionPanelService } from './../../components/fiExpansionPanel/FiExpansionPanelService';
import { FiFurnitureService } from './../../components/fiFurniture/FiFurnitureService';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../components/fiToast/FiToastService';
import { FiCart } from '../../services/Cart';
import { FiPrice } from '../../services/Price';
// import * as uirouter from '@uirouter/angularjs';
import * as uirouter from 'angular-ui-router';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Configurator.html'),
    bindings: {
        openChanged: '&?'
    }
})
export class Configurator {
    static $inject = [
        '$scope',
        '$locale',
        'fiExpansionPanelService',
        'fiFurnitureService',
        'fiCart',
        'fiSubHeaderService',
        '$mdDialog',
        'fiPrice',
        '$mdSidenav',
        '$http',
        'fiToastService',
        '$state',
        '$location',
        '$window',
    ];

    zoom: number;
    price: number;
    updateLink: string;

    constructor(
        private $scope: angular.IScope,
        private $locale: angular.ILocaleService,
        private feps: FiExpansionPanelService,
        private fs: FiFurnitureService,
        private cart: FiCart,
        private fshs: FiSubHeaderService,
        private $mdDialog: angular.material.IDialogService,
        private ps: FiPrice,
        private $mdSidenav: angular.material.ISidenavService,
        private $http: angular.IHttpService,
        private ts: FiToastService,
        private $state: uirouter.StateService,
        private $location: angular.ILocationService,
        private $window: angular.IWindowService,
    ) {
        /* */
    }

    private user: any;
    activeStep: number;
    item_id: string;
    item_category: number;
    editMode: boolean;
    newItem: boolean;
    cartStorage: FiCart;
    
    $onInit() {

        this.item_id = this.$state.params['itemId'];
        this.newItem = this.item_id == 'new';

        this.cart.totalPrice = 0;
        this.zoom = 1;
        this.fshs.subHeaderIsHidden = true;
        this.user = JSON.parse(localStorage.getItem('currentUser'));

        

        if (this.newItem) {
            this.editMode = true;
            this.fs.selectFurniture('FIT86378426', true);
        }
        else if (this.$location.search().edit == '1' && this.item_id) {
            this.editMode = true;
            this.fs.selectFurniture(this.item_id, false);
        }
        else if (!this.$location.search().edit && this.item_id) {
            this.editMode = false;
            this.fs.selectFurniture(this.item_id, false);
        }
        else if (!this.fs.furniture) {
            this.editMode = false;
            this.fs.selectFurniture('FIT86378426', false);
        }
        else {
            this.editMode = false;
        }

        if (!this.user || !this.user.admin) {
            this.editMode = false;
        }
        
        this.fs.setActiveStep(1);

        this.openTypeSelectionDialog(false);
       
    }


    openTypeSelectionDialog(canChange: boolean) {

        this.$mdDialog.show({
            template: '<fi-furniture-type-selection-dialog></fi-furniture-type-selection-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: canChange,
            controllerAs: 'vm'
        });

    }
    
    saveItem() {

        if (this.newItem) {
            this.item_id = 'FIT' + Math.floor(Math.random() * 89999999 + 10000000);
            this.updateLink = "https://www.fitit.lt/db/new-furniture.php";
            this.item_category = 2;
            this.createItem();
        }
        else {
            this.item_id = this.fs.furniture.item_id;
            this.item_category = this.fs.furniture.category;
            this.updateLink = "https://www.fitit.lt/db/edit-furniture.php";
            // Creating item
            this.$http.post("https://www.fitit.lt/db/remove-columns-rows.php", { 
                item_id: this.item_id,
            }, { headers: {'Content-Type': 'application/json'} })
                .then((response: any) => {
                    this.createItem();
            });

            // this.$window.location.href = '/baldai/' + this.item_id;
        }
    }

    addToCart() {
        
        this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        this.fs.furniture.price = this.ps.getPrice(this.fs.furniture);

        if (this.cartStorage) {
            this.cart.items = this.cartStorage.items;
        }
        this.cart.items.push({
            item: this.fs.furniture,
            count: 1,
            assemblyPrice: this.ps.getAssemblyPrice(),
            carryPrice: this.ps.getCarryPrice()
        });

        // console.log(this.cart.items);

        this.cart.count = this.cart.items.length;
        this.cart.environment = this.fs.environment;

        localStorage.setItem('cart', JSON.stringify(this.cart));

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
        window['dataLayer'].push({
        'event': 'addToCart',
        'ecommerce': {
            'currencyCode': 'EUR',
            'add': {                                // 'add' actionFieldObject measures.
            'products': [{                        //  adding a product to a shopping cart.
                'name': this.fs.furniture.title,
                'id': this.fs.furniture.item_id,
                'price': this.fs.furniture.price,
                'quantity': 1
            }]
            }
        }
        });


        this.$window.location.href = '/krepselis';

        // this.showCartLink = true;
        
    }

    openRequest() {

        this.$mdDialog.show({
            template: '<fi-request-dialog></fi-panel-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });

    }

    createItem() {
        // Creating item
        this.$http.post(this.updateLink, { 

            item_id: this.item_id,
            title: this.fs.furniture.title,
            orderby: this.fs.furniture.orderby,
            price: this.ps.getPrice(this.fs.furniture),
            extra_description_1: this.fs.furniture.extra_description_1,
            extra_description_2: this.fs.furniture.extra_description_2,
            extra_description_3: this.fs.furniture.extra_description_3,
            extra_description_4: this.fs.furniture.extra_description_4,
            extra_description_5: this.fs.furniture.extra_description_5,
            extra_price_1: this.fs.furniture.extra_price_1,
            extra_price_2: this.fs.furniture.extra_price_2,
            extra_price_3: this.fs.furniture.extra_price_3,
            extra_price_4: this.fs.furniture.extra_price_4,
            extra_price_5: this.fs.furniture.extra_price_5,
            price_from: this.fs.furniture.price_from,
            description: this.fs.furniture.description,
            category: this.item_category,
            width: this.fs.furniture.width,
            height: this.fs.furniture.height,
            depth: this.fs.furniture.depth,
            additional_1: this.fs.furniture.additional.option_1,
            additional_2: this.fs.furniture.additional.option_2,
            additional_3: this.fs.furniture.additional.option_3,
            additional_2_height: this.fs.furniture.additional_2_height,
            additional_2_depth: this.fs.furniture.additional_2_depth,
            additional_2_unknow: this.fs.furniture.additional_2_unknow,
            material_is_mdf: this.fs.furniture.material_is_mdf,
            material_has_fillings: this.fs.furniture.material_has_fillings,
            material_mdf_id: this.fs.furniture.material_mdf_id,
            material_mdf_thickness: this.fs.furniture.material_mdf_thickness,
            material_mdf_gloss: this.fs.furniture.material_mdf_gloss,
            material_mdf_filling_id: this.fs.furniture.material_mdf_filling_id,
            type: this.fs.furniture.type,
            quality: this.fs.furniture.quality,
            installation: this.fs.furniture.installation,
            plinth_type: this.fs.furniture.plinth.type,
            plinth_height: this.fs.furniture.plinth.height,
            plinth_legs_id: this.fs.furniture.plinth.legs_id,
            knob_id: this.fs.furniture.knob_id,
            material_id: this.fs.furniture.material.id,
            material_type: this.fs.furniture.material_type,
            edges: this.fs.furniture.edges,
            columnsCount: this.fs.furniture.columnsCount,
            entresol: this.fs.furniture.entresol,
            entresolHeight: this.fs.furniture.entresolHeight,
            rails: this.fs.furniture.rails,
            doors_count: this.fs.furniture.doors_count,
            with_drawers: this.fs.furniture.with_drawers,
            draft: this.fs.furniture.draft,
            with_hangers: this.fs.furniture.with_hangers,
            visible: false,
            modified: false,
            show_in_fp: false,
            slidingDoorsCount: this.fs.furniture.slidingDoorsCount,
            material_different_sides: this.fs.furniture.material_different_sides,
            material_ls_lmdp_id: this.fs.furniture.material_ls_lmdp_id,
            material_rs_lmdp_id: this.fs.furniture.material_rs_lmdp_id,
            material_ts_lmdp_id: this.fs.furniture.material_ts_lmdp_id,
            material_bs_lmdp_id: this.fs.furniture.material_bs_lmdp_id,
            material_ls_type: this.fs.furniture.material_ls_type,
            material_rs_type: this.fs.furniture.material_rs_type,
            material_ts_type: this.fs.furniture.material_ts_type,
            material_bs_type: this.fs.furniture.material_bs_type,
            material_ls_is_mdf: this.fs.furniture.material_ls_is_mdf,
            material_rs_is_mdf: this.fs.furniture.material_rs_is_mdf,
            material_ts_is_mdf: this.fs.furniture.material_ts_is_mdf,
            material_bs_is_mdf: this.fs.furniture.material_bs_is_mdf,
            material_ls_mdf_id: this.fs.furniture.material_ls_mdf_id,
            material_rs_mdf_id: this.fs.furniture.material_rs_mdf_id,
            material_ts_mdf_id: this.fs.furniture.material_ts_mdf_id,
            material_bs_mdf_id: this.fs.furniture.material_bs_mdf_id,
            material_ls_mdf_gloss: this.fs.furniture.material_ls_mdf_gloss,
            material_rs_mdf_gloss: this.fs.furniture.material_rs_mdf_gloss,
            material_ts_mdf_gloss: this.fs.furniture.material_ts_mdf_gloss,
            material_bs_mdf_gloss: this.fs.furniture.material_bs_mdf_gloss,
            material_ls_mdf_has_fillings: this.fs.furniture.material_ls_mdf_has_fillings,
            material_rs_mdf_has_fillings: this.fs.furniture.material_rs_mdf_has_fillings,
            material_ts_mdf_has_fillings: this.fs.furniture.material_ts_mdf_has_fillings,
            material_bs_mdf_has_fillings: this.fs.furniture.material_bs_mdf_has_fillings,
            material_ls_mdf_filling_id: this.fs.furniture.material_ls_mdf_filling_id,
            material_rs_mdf_filling_id: this.fs.furniture.material_rs_mdf_filling_id,
            material_ts_mdf_filling_id: this.fs.furniture.material_ts_mdf_filling_id,
            material_bs_mdf_filling_id: this.fs.furniture.material_bs_mdf_filling_id,
            sliding_door_profiles: this.fs.furniture.sliding_door_profiles,
            collect_myself: this.fs.furniture.collect_myself

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {
                //
        });


        // Adding columns
        for(const column of this.fs.furniture.columns) {

            this.$http.post("https://www.fitit.lt/db/new-furniture-columns.php", { 
                item_id: this.item_id,
                order_no: column.order_no,
                column_id: column.column_id,
                rows_count: column.rowsCount,
                width: column.width,
                height: column.height,
                outer_height: column.outer_height,
                inner_width: column.inner_width

            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) { 
                    //
            });

            for(const row of column.rows) {
                this.$http.post("https://www.fitit.lt/db/new-furniture-rows.php", { 
                    column_id: column.column_id,
                    order_no: row.order_no,
                    row_id: row.row_id,
                    width: column.width,
                    inner_width: column.inner_width,
                    // height: this.fs.furniture.height / column.rowsCount,
                    // inner_height: (this.fs.furniture.height - this.fs.edgeWidth) / column.rowsCount,
                    height: row.height,
                    inner_height: row.inner_height,
                    item_id: this.item_id,
                    panel_id: row.panel_id,
                    handle_id: row.handle_id,
                    milled_knob_id: row.milled_knob_id,
                    handle_internal_id: row.handle_internal_id,
                    handle_type: row.handleType,
                    inner_type: row.inner,
                    outer_type: row.outer,
                    merged: row.merged,
                    parent_row_id: row.parent_row_id,
                    panel_type: row.panelType,
                    is_mdf: row.is_mdf,
                    fillings: row.fillings,
                    mdf_id: row.mdf_id,
                    filling_id: row.filling_id,
                    mdf_thickness: row.mdf_thickness,
                    mdf_gloss: row.mdf_gloss


                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });

                if (row.merged_with) {
                    for(const mrow of row.merged_with) {
                        this.$http.post("https://www.fitit.lt/db/new-furniture-merged-row.php", {
                            item_id: this.item_id,
                            row_id: mrow.row_id,
                            parent_row_id: mrow.parent_row_id,
                            row_height: mrow.row_height,
                            row_width: mrow.row_width,
                            order_no: mrow.order_no,
    
                        }, { headers: {'Content-Type': 'application/json'} })
                            .then(function (response) { 
                                //
                        });
                    }
                }
                
            }

        }

        // Adding sliding doors
        for(const door of this.fs.furniture.slidingDoors) {
            this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors.php", { 
                item_id: this.item_id,
                door_id: door.door_id,
                order_no: door.order_no,
                divide: door.divide,
                width: door.width,
                height: door.height
            }, { headers: {'Content-Type': 'application/json'} })
                .then(function (response) { 
                    //
            });

            for(const row of door.divideRows) {
                this.$http.post("https://www.fitit.lt/db/new-furniture-sliding-doors-rows.php", { 
                    door_id: door.door_id,
                    row_id: row.row_id,
                    order_no: row.order_no,
                    width: row.width,
                    height: row.height,
                    item_id: this.item_id,
                    panel_id: row.panel_id,
                    mirror_id: row.mirror_id,
                    knob_id: row.knob_id,
                    panel_type: row.panel_type,
                    mdf_gloss: row.mdf_gloss,
                    mdf_id: row.mdf_id,
                    handle_type: row.handle_type,
                    handle_id: row.handle_id,
                    handle_internal_id: row.handle_internal_id,
                    milled_knob_id: row.milled_knob_id,
                    mdf_thickness: row.mdf_thickness

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        //
                });
            }
        }

        // Success message
        this.ts.show('Išsaugota');
    }

    showReloadDialog() {
        this.$mdDialog.show({
            template: '<fi-reload-dialog></fi-reload-dialog>',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    openDetails() {

        // this.$mdDialog.show({
            // template: '<fi-summary></fi-summary>',
            // parent: angular.element(document.body),
            // clickOutsideToClose: true,
            // controllerAs: 'vm'
        // });

        this.$mdSidenav('item-details').open();
    }

    closeDetails() {
        this.$mdSidenav('item-details').close();
    }

    hideReloadDialog() {
        this.$mdDialog.cancel();
    }

    addToFavorites() {
        

        this.$http.post("https://www.fitit.lt/db/add-to-favorites.php", { 

            itemID: 1,
            userID: this.user.id,

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                if(response.data == 'false') {
                    this.ts.show('Įvyko klaida');
                }
                else {

                    // Here need to add to DB new furniture... jei ne default

                    this.ts.show(this.getFurnitureTypeName() + ' išsaugota');
                }
        });

    }

    getFurnitureTypeName() {
        if (this.fs.furniture.type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (this.fs.furniture.type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (this.fs.furniture.type == 3) {
            return 'Komoda';
        }
        else if (this.fs.furniture.type == 4) {
            return 'Lentyna';
        }
        else if (this.fs.furniture.type == 5) {
            return 'TV spintelė';
        }
        else if (this.fs.furniture.type == 6) {
            return 'Spintelė';
        }
    }

    undo() {
        alert('undo');
    }

    showSettingsSidenav() {
        this.$mdSidenav('furniture-settings-sidenav').open();
    }
}
