import './Login.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiToastService } from './../../components/fiToast/FiToastService';

import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Login.html'),
    bindings: {
    }
})
export class Login {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
        'fiToastService',
    ];

    view: number = 1;
    rememberMe: boolean;
    userEmail: string;
    userPassword: string;
    private user: any;
    userRemindPasswordEmail: string;

    newUserEmail: string;
    newUserPassword: string;
    newUserPasswordRepeat: string;
   

    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private ts: FiToastService,
    ) {
        /* */
    }

    wrongLogin: boolean;
    newUserAdvertising: boolean;
    isSent: boolean = false;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (this.user) {
            if (this.user.admin) {
                this.$window.location.href = '/uzsakymai';
            }
            else {
                this.$window.location.href = '/mano-profilis';
            }
        }
    }

    switchView(view: number) {
        this.userRemindPasswordEmail = '';
        this.isSent = false;
        this.view = view;
    }

    sendReminder() {

        this.$http.post("https://www.fitit.lt/db/remind-pass.php", { 

            user_email: this.userRemindPasswordEmail

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                

        });

        this.isSent = true;

    }

    login() {

        this.$http.post("https://www.fitit.lt/db/get-user.php", { 

            email: this.userEmail,
            password: this.userPassword,

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {
                if(response.data == 'return') {
                    this.ts.show('Neteisingi prisijungimo duomenys');
                    //this.wrongLogin = true;
                    return;
                }
                else {
                    // this.wrongLogin = false;
                    const currentUser = {
                        id: response.data[0].id,
                        name: response.data[0].name,
                        surname: response.data[0].surname,
                        email: response.data[0].email,
                        phone: response.data[0].phone,
                        city: response.data[0].city,
                        address: response.data[0].address,
                        zip: response.data[0].zip,
                        company: response.data[0].company == 1 ? true : false,
                        company_name: response.data[0].company_name,
                        company_code: response.data[0].company_code,
                        company_vat: response.data[0].company_vat,
                        company_address: response.data[0].company_address,
                        advertising: response.data[0].advertising == 1 ? true : false,
                        created: response.data[0].created,
                        last_login: response.data[0].last_login,
                        favorites: response.data[0].favorites,
                        orders: response.data[0].orders,
                        admin: response.data[0].admin == 1 ? true : false
                    }

                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                    location.reload();

                    if (response.data[0].admin == 1) {
                        this.$http.post("https://www.fitit.lt/db/set-session.php", { 

                        }, { headers: {'Content-Type': 'application/json'} })
                            .then((response: any) => {
                                //
                        });
                    }
                    
            }
            }
        );
    }


    createNewUser() {
        
        if (this.newUserPassword != this.newUserPasswordRepeat) {
            this.ts.show('Nesutampa slaptažodžiai');
            return;
        }

        this.$http.post("https://www.fitit.lt/db/new-user.php", { 

            email: this.newUserEmail,
            password: this.newUserPassword,
            advertising: this.newUserAdvertising

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                if(response.data == 'exist') {
                    this.ts.show('Vartotojas šiuo el.paštu jau egzistuoja');
                }
                else {
                    this.view = 1;
                    this.ts.show('Registracija sėkminga');
                }
        });
    }
}
