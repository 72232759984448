export default [
    '$compileProvider',
    (
        $compileProvider: angular.ICompileProvider,
    ) => {
        $compileProvider.debugInfoEnabled(false);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
    }
];
