import './FiItemCard.scss';
import { FiFurnitureService, IFurnitureItem } from '../fiFurniture/FiFurnitureService';
import { FiCart } from '../../services/Cart';
import { FiPrice } from '../../services/Price';

import * as angular from 'angular';

import { Component } from '../Component';

@Component({
    controllerAs: 'vm',
    template: require('./FiItemCard.html'),
    bindings: {
        item: '<',
        showInside: '<'
    },
})
export class FiItemCard {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
        'fiCart',
        'fiPrice',
        '$http',
    ];


    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
        private cart: FiCart,
        private ps: FiPrice,
        private $http: angular.IHttpService,
    ) {
        //
    }

    data = [];
    cartStorage: FiCart;
    item: IFurnitureItem;
    itemCartCount: number = 1;

    $onInit() {
        //
    }

    getType(type) {
        if (type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }

    viewDetails(item_id: string) {
        this.$window.location.href = '/baldai/' + item_id;
    }

    goToConfiguratorOLD(item_id: string) {
        this.fs.selectFurniture(item_id, false);
        // this.fs.newItem = true;
        this.$window.location.href = '/konfiguratorius/';
    }

    goToConfigurator(item_id: string) {
        this.$window.location.href = '/konfiguratorius/' + item_id;
    }

    addToCart(item_id: string) {

        this.data = [];

        this.$http.get('https://www.fitit.lt/db/item.php?id=' + item_id).then((data) => {
            this.data.push(data);
            this.fs.setFurniture(false, this.data);

            this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        
            if (this.cartStorage) {
                this.cart.items = this.cartStorage.items;
            }
            
            this.cart.items.push({
                item: this.fs.furniture,
                count: 1,
                assemblyPrice: this.ps.getAssemblyPrice(),
                carryPrice: this.ps.getCarryPrice()
            });

            this.cart.count = this.cart.items.length;

            localStorage.setItem('cart', JSON.stringify(this.cart));

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
            window['dataLayer'].push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {                                // 'add' actionFieldObject measures.
                'products': [{                        //  adding a product to a shopping cart.
                    'name': this.fs.furniture.title,
                    'id': this.fs.furniture.item_id,
                    'price': this.fs.furniture.price,
                    'quantity': 1
                }]
                }
            }
            });

            this.$window.location.href = '/krepselis';
        });


        

    }

}