import './FiExpansionPanel.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiExpansionPanelService } from './FiExpansionPanelService';
import { FiFurnitureService, IColumnItem, IRowItem } from '../../components/fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiExpansionPanel.html'),
    bindings: {
        id: '@',
        panelTitle: '@',
        info: '@'
    },
    transclude: true
})

export class FiExpansionPanel {
    static $inject = [
        'fiExpansionPanelService',
        '$mdDialog',
        'fiFurnitureService',
    ];

    id: string;
    info: string;
    panelTitle: string;

    constructor(
        private feps: FiExpansionPanelService,
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
    }

    $onInit() {
        //
    }

    showInfo() {
        this.$mdDialog.show({
            contentElement: '#infoDialog_' + this.id,
            parent: angular.element(document.body),
            clickOutsideToClose: true
        });
    }

    hideInfo() {
        this.$mdDialog.cancel();
    }

    setView() {
        if (this.id == '8') {
            this.fs.setView(3);
        }
    }

    unActivateRow() {
        this.fs.furniture.columns.forEach((c: IColumnItem) => {
            c.active = false;
            c.rows.forEach((r: IRowItem) => {
                    r.active = false;
                
            });
        });
        this.fs.furniture.activeRow = null;
        this.fs.furniture.activeColumn = null;
    }
}
