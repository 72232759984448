import './FiImageDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiImageDialog.html'),
    bindings: {
        // 
    },
})
export class FiImageDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
    ) {
        //
    }

    $onInit() {
        //
    }

    close() {
        this.$mdDialog.cancel();
    }
}