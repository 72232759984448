import './Home.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService } from '../../components/fiFurniture/FiFurnitureService';
import { FiCart } from '../../services/Cart';
import { FiPrice } from '../../services/Price';
import * as angular from 'angular';

import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";

@Component({
    controllerAs: 'vm',
    template: require('./Home.html'),
    bindings: {
    }
})
export class Home {
    static $inject = [
        '$scope',
        '$locale',
        '$timeout',
        'fiSubHeaderService',
        '$window',
        'fiFurnitureService',
        '$http',
        'fiCart',
        'fiPrice',
        '$mdDialog',
    ];

    activeDukItem: number;
    galleryPic: number = 1;
    image_1: File;
    galleryFade: boolean;
    
    cName: string;
    cLastname: string;
    cEmail: string;
    cPhone: string;
    cDescription: string;
    cConsultation: boolean;

    thanks: boolean = false;

    constructor(
        private $scope: angular.IScope,
        private $locale: angular.ILocaleService,
        private $timeout: angular.ITimeoutService,
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
        private cart: FiCart,
        private ps: FiPrice,
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    data = [];
    gallery = [];
    cartStorage: FiCart;
    dialogTemplate: string;

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        // Items
        this.$http.get('https://www.fitit.lt/db/items.php').then((data) => {
            this.data.push(data);
        });

        // Gallery
        this.$http.get('https://www.fitit.lt/db/admin-gallery.php').then((data) => {
            this.gallery.push(data);
        });

        this.swapImage();

        this.$timeout(() => {
            this.constructGallery();
        }, 1000);

    }

    constructGallery() {
        const $galleryContainer = document.getElementById("gallery-container");

        const lg = lightGallery($galleryContainer, {
            speed: 500,
            plugins: [lgThumbnail],
            enableDrag: false,
            selector: '.item-details-gallery-item',
            enableSwipe: true,
            download : false,
            controls: true,
            closeOnTap  : false,
            mobileSettings: {
                showCloseIcon: true,
                controls: true,
            }
        });

        lg.outer.on("click", (e) => {
        const $item = lg.outer.find(".lg-current .lg-image");
        if (
            e.target.classList.contains("lg-image") ||
            $item.get().contains(e.target)
        ) {
            lg.goToNextSlide();
        }
        });
    }

    getImageUrl(url: string) {
        return 'https://www.fitit.lt/gallery/' + url;
    }

    swapImage() { 
        this.galleryFade = !this.galleryFade;

        this.$timeout(() => {
            this.swapImage();
        }, 5000);

    } 

    viewImage(img: string) {
        this.$window.open('https://www.fitit.lt/gallery/' + img, '_blank');
    }

    showChoosePlates() {

        this.dialogTemplate = '<fi-modal-top-five></fi-modal-top-five>';
        this.$mdDialog.show({
            template: this.dialogTemplate,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controllerAs: 'vm'
        });
    }

    toggleDuk(listItem: number) {
        this.activeDukItem = listItem;
    }

    goToConfiguratorNewItem() {
        this.$window.location.href = '/konfiguratorius/';
    }

    changeGalleryPic(picNo: number) {

        if (picNo < 1 || picNo > 9) {
            return
        }
        
        this.galleryPic = picNo;
    }

    viewDetails(item_id: string) {
        this.$window.location.href = '/baldai/' + item_id;
    }

    goToConfigurator(item_id: string) {
        this.$window.location.href = '/konfiguratorius/' + item_id;
    }

    addToCart(item_id: string) {

        this.data = [];

        this.$http.get('https://www.fitit.lt/db/item.php?id=' + item_id).then((data) => {
            this.data.push(data);
            this.fs.setFurniture(false, this.data);

            this.cartStorage = JSON.parse(localStorage.getItem('cart'));
        
            if (this.cartStorage) {
                this.cart.items = this.cartStorage.items;
            }
            
            this.cart.items.push({
                item: this.fs.furniture,
                count: 1,
                assemblyPrice: this.ps.getAssemblyPrice(),
                carryPrice: this.ps.getCarryPrice()
            });

            this.cart.count = this.cart.items.length;
            this.cart.environment = this.fs.environment;

            localStorage.setItem('cart', JSON.stringify(this.cart));

            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({ ecommerce: null });  // Clear the previous ecommerce object.
            window['dataLayer'].push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {                                // 'add' actionFieldObject measures.
                'products': [{                        //  adding a product to a shopping cart.
                    'name': this.fs.furniture.title,
                    'id': this.fs.furniture.item_id,
                    'price': this.fs.furniture.price,
                    'quantity': 1
                }]
                }
            }
            });

            this.$window.location.href = '/krepselis';
        });


        

    }

    sendOLD() {
        this.$http.post("https://www.fitit.lt/db/request.php", { 

            name: this.cName,
            email: this.cEmail,
            phone: this.cPhone,
            description: this.cDescription,
            consulation: this.cConsultation

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {

                // console.log(response);

                if(response.data == 'return') {
                    // alert('return');
                }
                else {
                    
                }
                return;

        });

        // this.thanks = true;
    }

    send() {
        const current = new Date();
        const timestamp = current.getTime();

        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/25831285/59f26b76-649d-4a48-8228-6ad096bfca13'
        var data = {
        "submittedAt": timestamp,
        "fields": [
            {
                "objectTypeId": "0-1",
                "name": "email",
                "value": this.cEmail
            },
            {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": this.cName
            },
            {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": this.cLastname
            },
            {
                "objectTypeId": "0-1",
                "name": "message",
                "value": this.cDescription + '\n' + (this.cConsultation === true ? 'Konsultacija reikalinga' : 'Konsultacijos nereikia')
            },
            {
                "objectTypeId": "0-1",
                "name": "phone",
                "value": this.cPhone
            },
            {
                "objectTypeId": "0-1",
                "name": "salutation",
                "value": 'Užklausos tipas: Forma'
            }
        ],
        "context": {
            // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            "pageUri": "www.fitit.lt",
            "pageName": "Užklausos tipas: Forma"
        },
        "legalConsentOptions":{ // Include this object when GDPR options are enabled
            "consent":{
            "consentToProcess":true,
            "text":"I agree to allow Example Company to store and process my personal data.",
            "communications":[
                {
                "value":true,
                "subscriptionTypeId":999,
                "text":"I agree to receive marketing communications from Example Company."
                }
            ]
            }
        }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                // alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                // alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 
        
        xhr.send(final_data);

        this.thanks = true;

        this.sendOLD();
    }

    getType(type: number) {
        if (type == 1) {
            return 'Spinta su varstomomis durimis';
        }
        else if (type == 2) {
            return 'Spinta su stumdomomis durimis';
        }
        else if (type == 3) {
            return 'Komoda';
        }
        else if (type == 4) {
            return 'Lentyna';
        }
        else if (type == 5) {
            return 'TV spintelė';
        }
        else if (type == 6) {
            return 'Spintelė';
        }
    }
}
