import './Success.scss';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import { Component } from '../../components/Component';
import { FiCart } from '../../services/Cart';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Success.html'),
    bindings: {
    }
})
export class Success {
    static $inject = [
        'fiSubHeaderService',
        'fiCart',
        '$http',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private cart: FiCart,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    order = [];

    $onInit() {

        this.fshs.subHeaderIsHidden = true;
        this.order = JSON.parse(localStorage.getItem('orderInfo'));

        this.order = [];
        localStorage.removeItem('orderInfo');
        this.cart.count = 0;
        this.cart.items = [];
        localStorage.removeItem('cart');
        
    }


    OldNoNeed() {
        console.log(this.order);

      
        this.$http.post("https://www.fitit.lt/db/order.php", { 

            order_id: this.order['order_id']

        }, { headers: {'Content-Type': 'application/json'} })
            .then((response: any) => {

                this.$http.post("https://www.fitit.lt/db/success.php", { 
            
                    name:this.order['name'],
                    email: this.order['email'],
                    order_id: this.order['order_id']

                }, { headers: {'Content-Type': 'application/json'} })
                    .then(function (response) { 
                        this.order = [];
                        localStorage.removeItem('orderInfo');
                        this.cart.count = 0;
                        this.cart.items = [];
                        localStorage.removeItem('cart');
                });

        });
    }
}
