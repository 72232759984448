import './FiMilledKnobDialog.scss';

import * as angular from 'angular';

import { Component } from '../Component';
import { FiFurnitureService } from '../fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./FiMilledKnobDialog.html'),
    bindings: {
        hideButton: '<'
    },
})
export class FiMilledKnobDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$window',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $window: angular.IWindowService,
    ) {
        //
    }

    hideButton: boolean;
    mainImage: number = 1;

    $onInit() {
        //
        this.hideButton = true;
    }

    close() {
        this.$mdDialog.cancel();
    }

    switchImage(imageNumber) {

        if (imageNumber < 1 || imageNumber > 5) {
            return
        }

        this.mainImage = imageNumber;
    }

    viewImage(img) {
        this.$window.open('/img/milled_knobs/' + img, '_blank');
    }
}