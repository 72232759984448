import './Request.scss';

import { Component } from '../../components/Component';
import { FiSubHeaderService } from './../../components/FiSubHeader/FiSubHeaderService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./Request.html'),
    bindings: {
    }
})
export class Request {
    static $inject = [
        'fiSubHeaderService',
        '$http',
    ];

    cName: string;
    cLastname: string;
    cEmail: string;
    cPhone: string;
    cDescription: string;
    cConsultation: boolean;

    thanks: boolean = false;
    
    constructor(
        private fshs: FiSubHeaderService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    $onInit() {
        this.fshs.subHeaderIsHidden = false;
    }

    sendOLD() {
        this.$http.post("https://www.fitit.lt/db/request.php", { 

            name: this.cName,
            email: this.cEmail,
            phone: this.cPhone,
            description: this.cDescription,
            consulation: this.cConsultation

        }, { headers: {'Content-Type': 'application/json'} })
            .then(function (response) {

                console.log(response);

                if(response.data == 'return') {
                    // alert('return');
                }
                else {
                    
                }
                return;

        });

        // this.thanks = true;
    }

    send() {
        const current = new Date();
        const timestamp = current.getTime();

        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/25831285/59f26b76-649d-4a48-8228-6ad096bfca13'
        var data = {
        "submittedAt": timestamp,
        "fields": [
            {
                "objectTypeId": "0-1",
                "name": "email",
                "value": this.cEmail
            },
            {
                "objectTypeId": "0-1",
                "name": "firstname",
                "value": this.cName
            },
            {
                "objectTypeId": "0-1",
                "name": "lastname",
                "value": this.cLastname
            },
            {
                "objectTypeId": "0-1",
                "name": "message",
                "value": this.cDescription + '\n' + (this.cConsultation === true ? 'Konsultacija reikalinga' : 'Konsultacijos nereikia')
            },
            {
                "objectTypeId": "0-1",
                "name": "phone",
                "value": this.cPhone
            },
            {
                "objectTypeId": "0-1",
                "name": "salutation",
                "value": 'Užklausos tipas: Forma'
            }
        ],
        "context": {
            // "hutk": ':hutk', // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            "pageUri": "www.fitit.lt",
            "pageName": "Užklausos tipas: Forma"
        },
        "legalConsentOptions":{ // Include this object when GDPR options are enabled
            "consent":{
            "consentToProcess":true,
            "text":"I agree to allow Example Company to store and process my personal data.",
            "communications":[
                {
                "value":true,
                "subscriptionTypeId":999,
                "text":"I agree to receive marketing communications from Example Company."
                }
            ]
            }
        }
        }

        var final_data = JSON.stringify(data)

        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function() {
            if(xhr.readyState == 4 && xhr.status == 200) { 
                // alert(xhr.responseText); // Returns a 200 response if the submission is successful.
            } else if (xhr.readyState == 4 && xhr.status == 400){ 
                // alert(xhr.responseText); // Returns a 400 error the submission is rejected.          
            } else if (xhr.readyState == 4 && xhr.status == 403){ 
                // alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState == 4 && xhr.status == 404){ 
                // alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 
        
        xhr.send(final_data);

        this.thanks = true;

        this.sendOLD();
    }
}
