import './FiSelectPanelHandleDialog.scss';
import * as angular from 'angular';
import { Component } from '../Component';
import { FiFurnitureService } from './../fiFurniture/FiFurnitureService';


@Component({
    controllerAs: 'vm',
    template: require('./FiSelectPanelHandleDialog.html'),
    bindings: {
        //
    },
})
export class FiSelectPanelHandleDialog {
    static $inject = [
        '$mdDialog',
        'fiFurnitureService',
        '$http',
    ];

    constructor(
        private $mdDialog: angular.material.IDialogService,
        private fs: FiFurnitureService,
        private $http: angular.IHttpService,
    ) {
        //
    }

    handles_items = [];
    handles_results = [];

    handles_internal_items = [];
    handles_internal_results = [];


    milled_knobs_items = [];
    milled_knobs_results = [];

    filter = {
        handle: {
            color: 0,
            length_category: 0,
            texture: 0,
            material: 0
        },
        handle_internal: {
            color: 0,
            length_category: 0,
            texture: 0,
            material: 0
        },
        knob_profiles: {
            color: 0,
            price_indicative: 0
        }
    };

    selectHandleType(selection) {

        if (this.fs.sameHandles) {
            this.fs.furniture.activeRow.handleType = selection;
            for(const column of this.fs.furniture.columns) {
                for(const row of column.rows) {
                    row.handleType = selection;

                    if (selection == 0 || selection == 3) {
                        row.handle = null;
                        row.handle_id = 0;
                    }
                }
            }
        }
        else {
            this.fs.furniture.activeRow.handleType = selection;
            if (selection == 0 || selection == 3) {
                this.fs.furniture.activeRow.handle = null;
                this.fs.furniture.activeRow.handle_id = 0;
            }
        }



        this.checkFirstHandleFromTheList(selection);
        


    }

    applyFilter(filterCategory: number) {

        if (filterCategory == 3) {
            this.handles_results = this.handles_items[0];

            if (this.filter.handle.color) {
                this.handles_results = this.handles_results.filter(item => item.color == this.filter.handle.color );
            }

            if (this.filter.handle.length_category) {
                this.handles_results = this.handles_results.filter(item => item.length_category == this.filter.handle.length_category );
            }

            if (this.filter.handle.texture) {
                this.handles_results = this.handles_results.filter(item => item.texture == this.filter.handle.texture );
            }

            if (this.filter.handle.material) {
                this.handles_results = this.handles_results.filter(item => item.material == this.filter.handle.material );
            }
        }
        else if (filterCategory == 4) {
            
            this.handles_internal_results = this.handles_internal_items[0];

            if (this.filter.handle_internal.color) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.color == this.filter.handle_internal.color );
            }

            if (this.filter.handle_internal.length_category) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.length_category == this.filter.handle_internal.length_category );
            }

            if (this.filter.handle_internal.texture) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.texture == this.filter.handle_internal.texture );
            }

            if (this.filter.handle_internal.material) {
                this.handles_internal_results = this.handles_internal_results.filter(item => item.material == this.filter.handle_internal.material );
            }
        }
        else if (filterCategory == 5) {
            this.knob_profiles_results = this.knob_profiles_items[0];

            if (this.filter.knob_profiles.color) {
                this.knob_profiles_results = this.knob_profiles_results.filter(item => item.color == this.filter.knob_profiles.color );
            }

            if (this.filter.knob_profiles.price_indicative) {
                this.knob_profiles_results = this.knob_profiles_results.filter(item => item.price_indicative == this.filter.knob_profiles.price_indicative );
            }
        }
        
    }

    checkFirstHandleFromTheList(type) {
        if (type == 1) {

            // Rankenėlės
            this.fs.handleItem = this.handles_results[0];

            if (this.fs.furniture.type == 2) {
                this.fs.furniture.activeDoorRow.handle = this.fs.handleItem;
                this.fs.furniture.activeDoorRow.handle_id = this.fs.handleItem.id;
            }
            else {
                if (this.fs.sameHandles) {
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle = this.fs.handleItem;
                            row.handle_id = this.fs.handleItem.id;
                        }
                    }
    
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle_internal = null;
                            row.handle_internal_id = 0;
                        }
                    }
    
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.milled_knob = null;
                            row.milled_knob_id = 0;
                        }
                    }
    
                    this.fs.furniture.activeRow.milled_knob = null;
                    this.fs.furniture.activeRow.milled_knob_id = 0;
    
                    this.fs.furniture.activeRow.handle_internal = null;
                    this.fs.furniture.activeRow.handle_internal_id = 0;
                }
                else {
                    this.fs.furniture.activeRow.handle = this.fs.handleItem;
                    this.fs.furniture.activeRow.handle_id = this.fs.handleItem.id;
                }
                
            }

        }
        else if (type == 2) {

            // Įleidžiamos
            this.fs.handleInternalItem = this.handles_internal_results[0];

            if (this.fs.furniture.type == 2) {
                this.fs.furniture.activeDoorRow.handle_internal = this.fs.handleInternalItem;
                this.fs.furniture.activeDoorRow.handle_internal_id = this.fs.handleInternalItem.id;
            }
            else {
                if (this.fs.sameHandles) {
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle_internal = this.fs.handleInternalItem;
                            row.handle_internal_id = this.fs.handleInternalItem.id;
                        }
                    }
    
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle = null;
                            row.handle_id = 0;
                        }
                    }
    
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.milled_knob = null;
                            row.milled_knob_id = 0;
                        }
                    }
    
                    this.fs.furniture.activeRow.milled_knob = null;
                    this.fs.furniture.activeRow.milled_knob_id = 0;
    
                    this.fs.furniture.activeRow.handle = null;
                    this.fs.furniture.activeRow.handle_id = 0;
                }
                else {
                    this.fs.furniture.activeRow.handle_internal = this.fs.handleInternalItem;
                    this.fs.furniture.activeRow.handle_internal_id = this.fs.handleInternalItem.id;
                }
            }

        }
        else if (type == 6) {

            // Frezuotos
            this.fs.milledKnobItem = this.milled_knobs_results[0];

            if (this.fs.furniture.type == 2) {
                this.fs.furniture.activeDoorRow.milled_knob = this.fs.milledKnobItem;
                this.fs.furniture.activeDoorRow.milled_knob_id = this.fs.milledKnobItem.id;    
            }
            else {
                if (this.fs.sameHandles) {
                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.milled_knob = this.fs.milledKnobItem;
                            row.milled_knob_id = this.fs.milledKnobItem.id;
                        }
                    }

                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle = null;
                            row.handle_id = 0;
                        }
                    }

                    for(const column of this.fs.furniture.columns) {
                        for(const row of column.rows) {
                            row.handle_internal = null;
                            row.handle_internal_id = 0;
                        }
                    }

                    this.fs.furniture.activeRow.handle = null;
                    this.fs.furniture.activeRow.handle_id = 0;

                    this.fs.furniture.activeRow.handle_internal = null;
                    this.fs.furniture.activeRow.handle_internal_id = 0;
                }
                else {
                    this.fs.furniture.activeRow.milled_knob = this.fs.milledKnobItem;
                    this.fs.furniture.activeRow.milled_knob_id = this.fs.milledKnobItem.id;
                }
            }

        }
    }

    $onInit() {
        // handles
        this.$http.get('https://www.fitit.lt/db/handles.php').then((data) => {
            this.handles_items.push(data.data);
            this.handles_results = this.handles_items[0];
        });

        // handles internal
        this.$http.get('https://www.fitit.lt/db/handles_internal.php').then((data) => {
            this.handles_internal_items.push(data.data);
            this.handles_internal_results = this.handles_internal_items[0];
        });

        // milled_knobs
        this.$http.get('https://www.fitit.lt/db/milled_knobs.php').then((data) => {
            this.milled_knobs_items.push(data.data);
            this.milled_knobs_results = this.milled_knobs_items[0];
        });
    }

    close() {
        this.$mdDialog.cancel();
    }

}