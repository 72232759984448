import './AdminOrders.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from '../../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService, IOffer, IOrder, IOrderItems } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';

@Component({
    controllerAs: 'vm',
    template: require('./AdminOrders.html'),
    bindings: {
    }
})
export class AdminOrders {
    static $inject = [
        'fiFurnitureService',
        'fiSubHeaderService',
        '$window',
        '$http',
    ];


    constructor(
        private fs: FiFurnitureService,
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
    ) {
        /* */
    }

    user: any;
    view: number;
    orders = [];
    offers = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));


        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.get('https://www.fitit.lt/db/orders.php').then((data) => {
                this.orders.push(data);
            });

            this.$http.get('https://www.fitit.lt/db/offer/offers.php').then((data) => {
                this.offers.push(data);
            });
        }

        this.view = 1;
    }
    
    newOffer() {
        this.$window.location.href = '/offer';
    }

    editOffer(item: IOffer) {
        this.$window.location.href = '/offer/' + item.order_no;
    }

    viewOrder(item: IOrder) {

        this.$window.location.href = '/order/' + item.order_id;
    }

    getOrderStatus(status: number) {
        if (status == 0) {
            return 'Laukiama apmokėjimo'; 
        }
        else if (status == 1) {
            return 'Apmokėtas';
        }
    }
}
