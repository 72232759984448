import './GiftcardsEdit.scss';

import { Component } from '../../../components/Component';
import { FiSubHeaderService } from '../../../components/FiSubHeader/FiSubHeaderService';
import { FiFurnitureService } from '../../../components/fiFurniture/FiFurnitureService';
import * as angular from 'angular';
import { IUser } from '../../../components/fiFurniture/FiFurnitureService';

@Component({
    controllerAs: 'vm',
    template: require('./GiftcardsEdit.html'),
    bindings: {
    }
})
export class GiftcardsEdit {
    static $inject = [
        'fiSubHeaderService',
        '$window',
        '$http',
        'fiFurnitureService',
        '$mdDialog',
    ];


    constructor(
        private fshs: FiSubHeaderService,
        private $window: angular.IWindowService,
        private $http: angular.IHttpService,
        private fs: FiFurnitureService,
        private $mdDialog: angular.material.IDialogService,
    ) {
        /* */
    }

    private user: any;
    giftcardsOrders = [];

    $onInit() {
        this.fshs.subHeaderIsHidden = false;

        this.user = JSON.parse(localStorage.getItem('currentUser'));

        if (!this.user || !this.user.admin) {
            this.$window.location.href = '/';
        } else {
            this.$http.get('https://www.fitit.lt/db/giftcards-orders.php').then((data) => {
                this.giftcardsOrders.push(data);
            });
        }
    }
}
